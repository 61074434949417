import { useCallback, useState } from "react";
import { Filter, FilterData } from "../../../../types";
import Icon1 from "../../../assets/icons/iconuser.png";
import Icon2 from "../../../assets/icons/iconuser2.png";
import {
  FilterDataList,
  useDashboardAdmin,
} from "../../../utils/api/dashboard";

import { formatAmout } from "../../../utils/formatter";
import "./DashboardAdmin.css";
import PriseEnChargeGraph from "./Graphs/PriseEnChargeGraph";

type DashboardCardProps = {
  title: string;
  icon: string;
  value: number | string;
  bg?: string;
};

export function DashboardCard({
  title,
  icon,
  value,
  bg = "#FFF",
}: DashboardCardProps) {
  return (
    <div className="col-md-4 col-stat-dashboard-visiteur mb-3">
      <div
        className="content-col-stat-dashboard-admin"
        style={{ minHeight: "100%", backgroundColor: bg }}
      >
        <div className="info-user-admin-stat-dashboard">
          <p className="text-stat-dashboard-visiteur mb-1">{title}</p>
          <p
            className="text-chiffre-dashboard-admin mb-0"
            style={{ color: "#000000" }}
          >
            {value}
          </p>
        </div>
        <div className="content-img-folder-dashboard-visiteur">
          <img
            src={icon}
            alt="dashboard visiteur"
            className="img-folder-dashboard-visiteur"
          />
        </div>
      </div>
    </div>
  );
}

const DashboardAdmin = () => {
  const { filter, renderFilter } = useFilter();
  const {
    drivers,
    owners,
    pendingAssignations,
    concessionnaires,
    pendingRequests,
    requests,
    requestsData,
    sumAmounts,
  } = useDashboardAdmin(filter);
  return (
    <div className="dashbord-visiteur-component">
      <div className="row row-card-stat-visiteur mb-4 align-items-stretch">
        <DashboardCard title="Propriétaires" value={owners} icon={Icon1} />
        <DashboardCard
          title="Concessionnaires"
          value={concessionnaires}
          icon={Icon1}
        />
        <DashboardCard title="Chauffeurs" value={drivers} icon={Icon1} />
        <div className="col-md-12">{renderFilter()}</div>
        <DashboardCard title="Prise en charge" value={requests} icon={Icon2} />
        <DashboardCard
          title="Demande de prise en charge"
          value={pendingRequests}
          icon={Icon2}
        />
        <DashboardCard
          title="Assignations en attente"
          value={pendingAssignations}
          icon={Icon2}
        />
        <DashboardCard
          title="Montant"
          value={formatAmout(sumAmounts)}
          icon={Icon2}
        />
      </div>
      <div className="row row-dashboard-visiteur mb-4">
        <div className="col-md-12 col-left-dashboard-visiteur mb-3">
          <div className="content-col-left-dashboard-visiteur">
            <div className="content-col-graph-admin">
              <PriseEnChargeGraph filter={filter} data={requestsData} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardAdmin;

export function useFilter() {
  const [val, setVal] = useState<Filter>("month");
  const [data] = useState<FilterData[]>(FilterDataList);
  const renderFilter = useCallback(() => {
    return <ChoiceFilter value={val} data={data} onChange={(v) => setVal(v)} />;
  }, [data, val]);

  return { renderFilter, filter: val };
}

type ChoiceFilterProps = {
  data: FilterData[];
  value: Filter;
  onChange: (v: Filter) => void;
};

export function ChoiceFilter({ data, onChange, value }: ChoiceFilterProps) {
  return (
    <ul className="list-filters d-flex p-0">
      {data.map((item) => (
        <li
          key={item.value}
          role="button"
          className={`filter-item ${value === item.value ? "active" : ""}`}
          onClick={() => onChange(item.value)}
        >
          {item.label}
        </li>
      ))}
    </ul>
  );
}
