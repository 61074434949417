import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { LandingPage } from "../../../types";

const initialState: LandingPage = {
  appstore: "#",
  facebook: "#",
  instagram: "#",
  linkedin: "#",
  playstore: "#",
  twitter: "#",
};

export const LandingPageSlice = createSlice({
  name: "landing_page",
  initialState,
  reducers: {
    onGetLandingPage: (state, action: PayloadAction<LandingPage>) => {
      state = action.payload;
      return state;
    },
  },
});

export const { onGetLandingPage } = LandingPageSlice.actions;

export default LandingPageSlice.reducer;
