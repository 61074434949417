import { useUser } from "../../../redux/root";
import { InvoiceComponent } from "../../admin/Invoices/InvoicesAdmin";

const InvoiceConcessionnaires = () => {
  const user = useUser();
  const userId = user.concessionnaireId || user.uid;
  return (
    <div className="dashbord-visiteur-component">
      <div className="terranga-auto-dash-admin-titre-btn-container p-4 bg-white">
        <div className="workcare-dash-admin-titre-container">
          <h1 className="terranga-auto-dash-admin-titre">Factures</h1>
        </div>
      </div>
      <InvoiceComponent userId={userId} />
    </div>
  );
};

export default InvoiceConcessionnaires;
