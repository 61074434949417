import { useState } from "react";
import { Vehicule, VehiculeFormData } from "../../../../../types";
import { useAddVehicule } from "../../../../utils/api/hooks";
import { useModalActions } from "../../../../utils/data";
import { useFormData } from "../../../../utils/form";
import { FormError } from "../../../auth/Login";
import {
  BtnAnnulerModal,
  BtnCloseModal,
  BtnSubmit,
} from "../../../common/Button";
import { Input, Select } from "../../../common/Input";
type AjouterVehiculeModalProps = {
  modalId?: string;
  userId?: string;
  item?: Vehicule;
};
function AjouterVehiculeModal({
  modalId,
  userId,
  item,
}: AjouterVehiculeModalProps) {
  const ma = useModalActions(modalId ?? "");

  const { errors, onChange, data, onSubmitForm, register } =
    useFormData<VehiculeFormData>(
      {
        annee: item?.annee || "",
        dateVisite: item?.dateVisite || "",
        matricule: item?.matricule || "",
        mode: item?.mode || "",
        model: item?.model || "",
        ...(userId && !item ? { concessionnaireId: userId } : {}),
      },
      (Yup) => ({
        annee: Yup.string().required().label("Année"),
        dateVisite: Yup.string()
          .required()
          .label("Date de la prochaine visite"),
        mode: Yup.string().required().label("Type de vitesse").required(),
        model: Yup.string().required().label("Modèle").required(),
        matricule: Yup.string().required().label("Matricule"),
      })
    );

  const { loading, onAdd } = useAddVehicule(() => {
    ma.hide();
    if (!item) {
      setTimeout(() => {
        onChange({
          annee: "",
          dateVisite: "",
          matricule: "",
          mode: "",
          model: "",
        });
      }, 1500);
    }
  });

  const [imgId] = useState(`${new Date().getTime()}_${Math.random()}`);

  return (
    <div className="modal-dialog modal-lg">
      <div className="modal-content custom-modal-content">
        <div className="modal-header custom-modal-header px-2 py-1">
          <h5 className="modal-title" id="AddTagModalLabel">
            {item ? "Modifier un véhicule" : "Ajouter un véhicule"}
          </h5>
          <div className="workcares-btn-close-modal-container">
            <BtnCloseModal label="" />
          </div>
        </div>
        <div className="modal-body">
          <div className="container-form">
            <form
              onSubmit={(e) => {
                e.preventDefault();
                onSubmitForm((data) => {
                  onAdd(item?.uid, data);
                });
              }}
            >
              <div className="row">
                <div className="col-md-6">
                  <Input
                    type="text"
                    label="Matricule"
                    placeholder="Matricule"
                    {...register("matricule")}
                  />
                  <FormError error={errors.matricule} />
                </div>

                <div className="col-md-6">
                  <Input
                    type="text"
                    label="Modèle"
                    placeholder="Modèle"
                    {...register("model")}
                  />
                  <FormError error={errors.model} />
                </div>
                <div className="col-md-6">
                  <Input
                    type="date"
                    label="Date de la prochaine visite"
                    placeholder="Date de la prochaine visite"
                    {...register("dateVisite")}
                  />
                  <FormError error={errors.dateVisite} />
                </div>
                <div className="col-md-6">
                  <Input
                    type="number"
                    label="Année"
                    placeholder="Année"
                    {...register("annee")}
                  />
                  <FormError error={errors.annee as any} />
                </div>

                <div className="col-md-6">
                  <Select
                    label="Type de vitesse"
                    data={[
                      { label: "Manuel", value: "Manuel" },
                      { label: "Automatique", value: "Automatique" },
                    ]}
                    placeholder="Type de vitesse"
                    {...register("mode")}
                  />
                  <FormError error={errors.mode} />
                </div>

                <div className="col-md-6">
                  <div className="mb-3">
                    <Input
                      type="file"
                      label="Image"
                      id={imgId}
                      name={imgId}
                      accept="image/png, image/gif, image/jpeg"
                      onChange={(e) => {
                        if (e.target.files) {
                          // @ts-ignore
                          onChange("image", e.target.files[0]);
                        }
                      }}
                      data-testid="image"
                      placeholder="image"
                    />
                    <div className="img-preview-container">
                      {data.image instanceof File && (
                        <div className="img-position-container">
                          <img
                            src={URL.createObjectURL(data.image)}
                            className="type-img-preview"
                            alt="img preview"
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-md-6 offset-md-6">
                  <div className="mb-3 mt-5 flex-r gap-3">
                    <BtnAnnulerModal label={"Fermer"} isLoading={false} />
                    <BtnSubmit
                      label={item ? "Modifier" : "Ajouter"}
                      isLoading={loading}
                      style={{ padding: "5px 10px", fontSize: 14 }}
                    />
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AjouterVehiculeModal;
