import React, { useEffect, useState } from "react";
import { RiArrowDropDownFill } from "react-icons/ri";
import { NavLink, useNavigate } from "react-router-dom";
import SidebarMobile from "../SidebarAdmin/SidebarMobile";
import "./HeaderAdmin.css";
// import Logo from "../../../../assets/appImages/logo.png";
import moment from "moment";
import { IoNotificationsCircle } from "react-icons/io5";
import {
  useAppDispatch,
  useAppSelector,
  useUser,
} from "../../../../redux/root";
import { onLogout } from "../../../../redux/slices/user.slice";
import { logoutAsync } from "../../../../utils/api/auth.api";
import { isConcessionnaires, isOwner } from "../../../../utils/api/hooks";
import { deleteNotif, readNotif } from "../../../../utils/api/user.api";
import { getAvatar } from "../../../../utils/data";
import "../SidebarAdmin/SidebarAdmin.css";

const HeaderAdmin: React.FC = () => {
  const notifications = useAppSelector((s) => s.notifs);
  const [count, setCount] = useState(0);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const user = useUser();

  let profileUrl = isOwner(user) ? "/proprietaire/profile" : "/admin/profile";
  if (isConcessionnaires(user)) profileUrl = "/concessionnaire/profile";
  useEffect(() => {
    setCount(notifications.filter((n) => !n.read).length);
    for (let n of notifications) {
      if (n.read) {
        const diff = moment().diff(moment(n.readDate), "days");
        if (diff > 10) {
          deleteNotif(n.uid);
        }
      }
    }
  }, [notifications]);
  const { length } = notifications;
  return (
    <div className="admin-header-component">
      <nav className="empty-fluidy alling-header navbar navbar-expand-md navbar-dark fixed-top">
        <div className="headering-admin">
          <div className="content-logo-header-admin">
            {/* <img src={Logo} alt="Logo" className="img-logo-header-admin" /> */}
          </div>
          <div className="header-others-items">
            <div className="search-form-container">
              <div className="dropdown dis-flex">
                <button
                  className="user-acount-dashboard-header dropdown-toggle btn"
                  type="button"
                  id="dropdownNotificationMenuButton"
                  data-bs-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <div className="user-acount-dashboard-header-display g-2">
                    <div style={{ position: "relative", overflow: "visible" }}>
                      <IoNotificationsCircle fontSize={32} />
                      {count > 0 && (
                        <div
                          style={{
                            position: "absolute",
                            top: 0,
                            right: 0,
                            width: 16,
                            height: 16,
                            borderRadius: "50%",
                            color: "#FFF",
                            backgroundColor: "red",
                            textAlign: "center",
                            verticalAlign: "middle",
                            fontSize: 12,
                          }}
                        >
                          {count}
                        </div>
                      )}
                    </div>
                  </div>
                </button>
                <div
                  className="dropdown-menu dropdown-menu-custom dropdown-menu-notifications"
                  aria-labelledby="dropdownNotificationMenuButton"
                >
                  {notifications.length === 0 && (
                    <div className="dropdown-item">
                      <div
                        style={{
                          fontSize: 13,
                          fontWeight: 500,
                          color: "inherit",
                          whiteSpace: "normal",
                        }}
                      >
                        Pas de notification
                      </div>
                    </div>
                  )}
                  {notifications.map((n, index) => {
                    let to = "#";
                    if (n.type === "assingation") {
                      to = `/admin/prise-en-charge?index=1`;
                    } else if (n.type === "assigned") {
                      to = `/concessionnaire/prise-en-charges`;
                    } else if (n.type === "invoice") {
                      to = `/concessionnaire/factures`;
                    }
                    return (
                      <React.Fragment key={n.uid}>
                        <div
                          onClick={() => {
                            if (!n.read) {
                              readNotif(n.uid);
                            }
                            navigate(to);
                            return true;
                          }}
                          className="dropdown-item"
                        >
                          <div
                            style={{
                              fontSize: 13,
                              fontWeight: !n.read ? 700 : 500,
                              color: "inherit",
                              whiteSpace: "normal",
                            }}
                          >
                            {n.content}
                          </div>
                          <div>
                            <small style={{ fontSize: 10 }}>
                              {moment(n.created_at).format("DD/MM/YYYY HH:mm")}
                            </small>
                          </div>
                        </div>
                        {index !== length - 1 && (
                          <div className="dropdown-divider"></div>
                        )}
                      </React.Fragment>
                    );
                  })}
                </div>
              </div>
            </div>

            <div className="connected-user-container">
              <div className="connected-user-content ml-auto">
                <div className="dropdown dis-flex">
                  <button
                    className="user-acount-dashboard-header dropdown-toggle btn"
                    type="button"
                    id="dropdownMenuButton"
                    data-bs-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <div className="user-acount-dashboard-header-display g-2">
                      <img
                        src={getAvatar(user)}
                        className="user-avatar"
                        alt="User Avatar"
                      />
                      <RiArrowDropDownFill />
                    </div>
                  </button>
                  <div
                    className="dropdown-menu dropdown-menu-custom"
                    style={{ left: "-6rem" }}
                    aria-labelledby="dropdownMenuButton"
                  >
                    <NavLink
                      to={profileUrl}
                      className="dropdown-item"
                      style={{ fontSize: 16 }}
                    >
                      {user?.prenom + " " + user?.nom}
                    </NavLink>
                    <div className="dropdown-divider"></div>
                    <button
                      type="button"
                      className="dropdown-item"
                      style={{ fontSize: 16 }}
                      onClick={() => {
                        logoutAsync();
                        window.location.reload();
                        dispatch(onLogout());
                      }}
                    >
                      Déconnexion
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="hiddenable-nav-mobile-container no-view-desktop">
              <div className="hiddenable-nav-mobile-content">
                <div className="container-nav-mobile">
                  <SidebarMobile />
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default HeaderAdmin;
