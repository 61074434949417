import { useState } from "react";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import { AiFillEye } from "react-icons/ai";
import { FaTrash } from "react-icons/fa";
import { NavLink } from "react-router-dom";
import { Invoice, UserInfos } from "../../../../../types";
import { useDeleteItem } from "../../../../utils/api/hooks";
import {
  deleteUserFunction,
  toggleUserStatusFunction,
  updateInvoice,
} from "../../../../utils/api/user.api";
import { getAvatar } from "../../../../utils/data";
import { CommonTable } from "../../../common/Input";
type ConcessionnaireAdminTableProps = {
  concessionnaires: UserInfos[];
};
function ConcessionnaireAdminTable({
  concessionnaires,
}: ConcessionnaireAdminTableProps) {
  const onDelete = useDeleteItem(
    deleteUserFunction,
    `Êtes-vous sûr de vouloir supprimer ce concessionnaire ?`,
    `Concessionnaire supprimé avec succés`,
    `Concessionnaire non supprimé`
  );
  const actionFormatter = (cell: any, row: UserInfos) => {
    return (
      <div className="table-actions-btn-container-action gap-2 d-flex">
        <NavLink
          to={`/admin/concessionnaires/${row.uid}`}
          className="btn btn-action-admin-voir with-tooltip no-link"
          state={row}
          data-tooltip-content="Voir"
        >
          <AiFillEye />
        </NavLink>
        <button
          className="btn btn-action-admin-supprimer with-tooltip"
          data-tooltip-content="Supprimer"
          onClick={() => onDelete(row.uid)}
        >
          <FaTrash />
        </button>
      </div>
    );
  };
  const nameAvatarFormatter = (cell: any, row: UserInfos) => {
    return (
      <div>
        <img
          src={getAvatar(row)}
          alt="user avatar"
          className="workcares-table-user-avatar"
        />{" "}
        {row.prenom} {row.nom}
      </div>
    );
  };

  const columns = [
    {
      dataField: "nom",
      text: "Nom",
      headerStyle: () => {
        return { width: "250px", whiteSpace: "normal" };
      },
      formatter: (cell: any, row: UserInfos) => nameAvatarFormatter(cell, row),
    },
    {
      dataField: "telephone",
      text: "Telephone",
    },
    {
      dataField: "nomSociete",
      text: "Raison sociale",
    },
    {
      dataField: "address",
      text: "Adresse",
      formatter: (cell: string) => cell ?? "Non renseigné",
    },
    {
      dataField: "disabled",
      text: "Activer ?",
      formatter: (cell: string, row: UserInfos) => (
        <ToggleUserActive item={row} />
      ),
    },
    {
      dataField: "action",
      text: "Actions",
      style: { textAlign: "center" },
      formatter: (cell: any, row: UserInfos) => actionFormatter(cell, row),
    },
  ];

  return (
    <CommonTable keyField="uid" data={concessionnaires} columns={columns} />
  );
}

export default ConcessionnaireAdminTable;

export function Switch({
  onPress,
  active,
}: {
  onPress: () => void;
  active: boolean;
}) {
  const [ID] = useState(`val_${Math.random()}`);
  const onClick = (e) => {
    e.stopPropagation();
    e.preventDefault();
    onPress();
  };
  return (
    <div className="switch2">
      <label htmlFor={ID} className="switch">
        <input
          name={ID}
          id={ID}
          onChange={(e) => {
            onClick(e);
          }}
          type="checkbox"
          checked={!active}
        />
        <span className="slider"></span>
      </label>
    </div>
  );
}

export function ToggleUserActive({ item }: { item: UserInfos }) {
  const onUpdateItem = useDeleteItem(
    toggleUserStatusFunction,
    item.disabled
      ? `Êtes-vous sûr de vouloir activer les accèss de ${item.prenom} ?`
      : `Êtes-vous sûr de vouloir désactiver les accèss de ${item.prenom} ?`,
    `${
      item.disabled ? "Utilisateur activé" : "Utilisateur désactivé"
    } avec succés`,
    `Une erreur est survenue`
  );
  return (
    <Switch active={!item.disabled} onPress={() => onUpdateItem(item.uid)} />
  );
}

export function TogglePaidInvoice({
  item,
  readonly,
}: {
  item: Invoice;
  readonly: boolean;
}) {
  const onUpdateItem = useDeleteItem(
    () => updateInvoice(item.uid, { paid: !item.paid }),
    !item.paid
      ? `Êtes-vous sûr de vouloir confirmer le paiement ?`
      : `Êtes-vous sûr de vouloir annulé le paiement ?`,
    `${!item.paid ? "Paiement confirmé avec" : "Paiement annulé"} avec succés`,
    `Une erreur est survenue`
  );
  return (
    <Switch
      active={item.paid}
      onPress={() => {
        if (readonly) return;
        onUpdateItem(item.uid);
      }}
    />
  );
}
