import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import { AiFillEye } from "react-icons/ai";
import { FaTrash } from "react-icons/fa";
import { NavLink } from "react-router-dom";
import { Driver } from "../../../../../types";
import { useDeleteItem } from "../../../../utils/api/hooks";
import { deleteUserFunction } from "../../../../utils/api/user.api";
import { getAvatar } from "../../../../utils/data";
import { CommonTable } from "../../../common/Input";
type ChauffeursAdminTableProps = {
  drivers: Driver[];
  admin?: boolean;
};
function ChauffeursAdminTable({
  drivers,
  admin = false,
}: ChauffeursAdminTableProps) {
  const onDelete = useDeleteItem(
    deleteUserFunction,
    `Êtes-vous sûr de vouloir supprimer ce chauffeur ?`,
    `Chauffeur supprimé avec succés`,
    `Chauffeur non supprimé`
  );
  const actionFormatter = (cell: string, row: Driver) => {
    return (
      <div className="table-actions-btn-container-action gap-2 d-flex">
        <NavLink
          to={`${
            admin
              ? `/admin/chauffeur/${row.uid}`
              : `/proprietaire/chauffeur/${row.uid}`
          }`}
          state={row}
          className="btn btn-action-admin-voir with-tooltip no-link"
          data-tooltip-content="Voir"
        >
          <AiFillEye />
        </NavLink>
        <button
          className="btn btn-action-admin-supprimer with-tooltip"
          data-tooltip-content="Supprimer"
          onClick={() => {
            onDelete(row.uid);
          }}
        >
          <FaTrash />
        </button>
      </div>
    );
  };
  const nameAvatarFormatter = (cell: string, row: Driver) => {
    return (
      <div>
        <img
          src={getAvatar(row)}
          alt="user avatar"
          className="workcares-table-user-avatar"
        />{" "}
        {row.prenom} {row.nom}
      </div>
    );
  };

  const columns = [
    {
      dataField: "nom",
      text: "Nom",
      headerStyle: () => {
        return { width: "250px", whiteSpace: "normal" };
      },
      formatter: (cell: string, row: Driver) => nameAvatarFormatter(cell, row),
    },
    {
      dataField: "telephone",
      text: "Telephone",
    },
    {
      dataField: "vehicule",
      text: "Véhicule",
      formatter: (cell: string, row: Driver) => {
        if (!row.vehicule) return "Pas de véhicule";
        return `${row.vehicule.matricule}`;
      },
    },
    {
      dataField: "password",
      text: "Mot de passe",
    },
    {
      dataField: "address",
      text: "Adresse",
    },
    {
      dataField: "action",
      text: "Actions",
      style: { textAlign: "center" },
      formatter: (cell: string, row: Driver) => actionFormatter(cell, row),
    },
  ];

  return <CommonTable keyField="uid" data={drivers} columns={columns} />;
}

export default ChauffeursAdminTable;
