import { useEffect, useState } from "react";
import { Content } from "../../../../types";
import { useAppSelector } from "../../../redux/root";
import { useAddContent } from "../../../utils/api/hooks";
import { BtnSubmit } from "../../common/Button";
import { HtmlInput } from "../../common/HtmlInput";

const GestionPtc = () => {
  return <ContentForm type="cgu" />;
};

export default GestionPtc;

export function ContentForm({ type }: { type: keyof Content }) {
  const data = useAppSelector((s) => s.content[type]);
  const [content, setContent] = useState("");
  const { loading, onAdd } = useAddContent(() => null);
  useEffect(() => {
    if (data) {
      setContent(data);
    }
  }, [data]);

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        onAdd({ [type]: content });
      }}
    >
      <div className="form-group">
        <HtmlInput name="content" data={content} onChange={setContent} />
      </div>
      <div className="btn-container flex-r mt-5">
        <BtnSubmit
          label={"Modifier"}
          isLoading={loading}
          style={{ padding: "5px 10px", fontSize: 14 }}
        />
      </div>
    </form>
  );
}
