import { useEffect, useState } from "react";
import { AiOutlinePlusSquare } from "react-icons/ai";
import { Invoice } from "../../../../types";
import { useInvoicesAdmin, useInvoicesByUser } from "../../../utils/api/hooks";
import AjouterInvoiceModal from "../Chauffeurs/Modales/AjouterInvoiceModal";
import InvoiceTable from "../Chauffeurs/Tables/InvoiceTable";

function InvoicesAdmin() {
  return (
    <div className="dashbord-visiteur-component">
      <div className="terranga-auto-dash-admin-titre-btn-container p-4 bg-white">
        <div className="workcare-dash-admin-titre-container">
          <h1 className="terranga-auto-dash-admin-titre">Factures</h1>
        </div>
      </div>
      <InvoiceComponent admin />
    </div>
  );
}

export default InvoicesAdmin;

export function InvoiceComponent({
  userId,
  admin,
}: {
  userId?: string;
  admin?: boolean;
}) {
  const invoices = userId ? useInvoicesByUser(userId) : useInvoicesAdmin();
  const [filtred, setFiltred] = useState<Invoice[]>([]);
  const [search, setSearch] = useState("");
  const modalId = "AjouterInvoiceModal";

  useEffect(() => {
    const s = search.trim().toLowerCase();
    if (s) {
      setFiltred(
        invoices.filter((u) => `${u.title}`.toLowerCase().includes(s))
      );
    } else {
      setFiltred(invoices);
    }
  }, [search, invoices]);
  return (
    <>
      {admin && (
        <>
          <div
            style={{
              flexDirection: "row",
              alignItems: "center",
              display: "flex",
              justifyContent: "flex-end",
              gap: 10,
              flexWrap: "wrap",
              backgroundColor: "#FFF",
              padding: "0 20px",
            }}
          >
            <button
              className="terranga-auto-dash-admin-btn-ajout-deux"
              data-bs-toggle="modal"
              data-bs-target={`#${modalId}`}
            >
              <AiOutlinePlusSquare className="terranga-auto-dash-admin-btn-ajout-icone" />{" "}
              Ajouter une facture
            </button>
          </div>
          <div
            className="modal fade"
            id={modalId}
            aria-labelledby="AjouterInvoiceModalLabel"
            aria-hidden="true"
          >
            <AjouterInvoiceModal userId={userId} modalId={modalId} />
          </div>
        </>
      )}

      <div className="workcare-dash-admin-page-content-container">
        <div className="locataire-requete-filtre-container mb-4">
          <form className="row g-3 locataire-requete-filtre-form">
            <div className="col-auto">
              <label
                htmlFor="filtreLogement"
                className="locataire-requete-filtre-label"
              >
                Filtrer
              </label>
            </div>
            <div className="col-auto">
              <input
                type="text"
                className="form-control locataire-requete-filtre-input"
                id="filtreLogement"
                placeholder="Chercher"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
            </div>
          </form>
        </div>
        <InvoiceTable userId={userId} admin={admin} invoices={filtred} />
      </div>
    </>
  );
}
