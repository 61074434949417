import React, { useState } from "react"
import Swal from "sweetalert2"
import { updatePasswordAsync } from "../../../utils/api/auth.api"
import { SWAL_DEFAULT_PROPS, useModalActions } from "../../../utils/data"
import { useFormData } from "../../../utils/form"
import { FormError } from "../../auth/Login"
import { BtnCloseModal, BtnSubmit } from "../../common/Button"
import { InputPassword } from "../../common/Input"

export type UpdatePasswordFormData = {
  new_password: string
  password_confirm: string
}

const ChangePasswordModal = () => {
  const [loading, setLoading] = useState(false)
  const { errors, onSubmitForm, onChange, register } =
    useFormData<UpdatePasswordFormData>(
      {
        new_password: "",
        password_confirm: "",
      },
      (Yup) => ({
        new_password: Yup.string().required().label("Nouveau mot de passe"),
        password_confirm: Yup.string().oneOf(
          [Yup.ref("new_password"), null],
          "Les mots de passe ne correspondent pas"
        ),
      })
    )

  const onSubmit = React.useCallback(async (data: UpdatePasswordFormData) => {
    setLoading(true)
    try {
      await updatePasswordAsync(data.new_password)
      setTimeout(() => {
        setTimeout(() => {
          Swal.fire({
            title: "Succès",
            text: "Mot de passe modifié avec succès",
            icon: "success",
            ...SWAL_DEFAULT_PROPS,
          })
          setLoading(false)
          onChange({ new_password: "", password_confirm: "" })
        }, 600)
      }, 400)
    } catch (ex) {
      const err = ex as { code: string }
      if (err.code === "auth/requires-recent-login") {
        Swal.fire({
          title: "Erreur",
          text: "Veuillez vous déconnecter et vous reconnecter pour modifier votre mot de passe",
          icon: "error",
          ...SWAL_DEFAULT_PROPS,
        })
      } else {
        Swal.fire({
          title: "Erreur",
          text: "Une erreur est survenue lors de la modification du mot de passe",
          icon: "error",
          ...SWAL_DEFAULT_PROPS,
        })
      }
      setLoading(false)
    }
  }, [])

  return (
    <div
      className="modal fade"
      id="ChangePasswordModal"
      aria-labelledby="ChangePasswordModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content custom-modal-content">
          <div className="modal-header custom-modal-header px-2 py-1">
            <h5 className="modal-title" id="ChangePasswordModalLabel">
              Changer mot de passe
            </h5>
            <div className="workcares-btn-close-modal-container">
              <BtnCloseModal label="" />
            </div>
          </div>
          <form
            onSubmit={(e) => {
              e.preventDefault()
              onSubmitForm(onSubmit)
            }}
          >
            <div className="row py-3 px-2">
              <div className="col-12 mb-3">
                <InputPassword
                  label="Nouveau mot de passe"
                  placeholder="Nouveau mot de passe"
                  {...register("new_password")}
                />
                <FormError error={errors.new_password} />
              </div>
              <div className="col-12 mb-4">
                <InputPassword
                  label="Confirmer mot de passe"
                  placeholder="Confirmer mot de passe"
                  {...register("password_confirm")}
                />
                <FormError error={errors.password_confirm} />
              </div>
              <div className="flex-r">
                <BtnSubmit label="Changer" isLoading={loading} />
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default ChangePasswordModal
