import "animate.css";
import React from "react";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-calendar/dist/Calendar.css";
import ReactDOM from "react-dom/client";
import "react-dropzone-uploader/dist/styles.css";
import "react-loading-skeleton/dist/skeleton.css";
import "react-quill/dist/quill.snow.css";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import "sweetalert2/dist/sweetalert2.min.css";
import App from "./App";
import "./index.css";
import root from "./redux/root";
import reportWebVitals from "./reportWebVitals";

import "moment/locale/fr";
ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <BrowserRouter>
    <React.StrictMode>
      <Provider store={root}>
        <App />
      </Provider>
    </React.StrictMode>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
