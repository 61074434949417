import { RiMenuAddFill } from "react-icons/ri";
import { NavLink } from "react-router-dom";
import Logo from "../../../assets/landingpage/logo.png";
import "./FrontHeader.css";
const FrontHeader = () => {
  return (
    <div className="frontheader-component">
      <nav className="navbar navbar-expand-lg navbar-light headering nav-header-custom fixed-top">
        <div className="container-fluid header-container-fluid pb-0">
          <div className="logo-and-version-container">
            <div className="logo-container">
              <a className="navbar-brand content-logo-app" href="#banniere">
                <img src={Logo} alt="Pitch palabre" className="app-logo" />
              </a>
            </div>
          </div>
          <button
            className="navbar-toggler content-icon-menu-mobile"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon">
              <RiMenuAddFill />
            </span>
          </button>
          <div
            className="collapse navbar-collapse header-navbar-collapse"
            id="navbarNav"
          >
            <div className="w-100 header-items-container">
              <div className="w-100 header-navbar-contents-container align-items-center">
                <ul className="navbar-nav navbar-nav-header me-auto ms-auto">
                  <li className="nav-item">
                    <a
                      className="nav-link menu-link"
                      aria-current="page"
                      href="/#banniere"
                    >
                      ACCUEIL
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link menu-link" href="/#a-propos">
                      A propos
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link menu-link" href="/#actualite">
                      Actualites
                    </a>
                  </li>
                </ul>
                <div className="bloc-flex-info-user-header-content">
                  <div className="login-btn-container">
                    <NavLink
                      to="/connexion"
                      className="btn btn-default btn-login-connect"
                    >
                      Connexion
                    </NavLink>
                  </div>
                  {/* <div className="connected-user-container">
                    <div className="connected-user-content ml-auto">
                      <div className="dropdown">
                        <button
                          className="btn user-acount-dashboard-header  dropdown-toggle"
                          type="button"
                          id="dropdownProfilUser"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <div className="user-acount-dashboard-header-display">
                            <img
                              src="zz"
                              width="45"
                              className="user-avatar"
                              alt="User Avatar"
                            />

                            <span className="mx-1 user-name">
                              Adama Diakhaté
                            </span>
                            <FiChevronDown />
                          </div>
                        </button>
                        <ul
                          className="dropdown-menu dropdown-menu-custom"
                          aria-labelledby="dropdownProfilUser"
                        >
                          <li>
                            <NavLink
                              className="dropdown-item item-dropdown-custom"
                              to="/"
                            >
                              Tableau de bord
                            </NavLink>
                            <NavLink
                              className="dropdown-item item-dropdown-custom"
                              to="/"
                            >
                              Tableau de bord
                            </NavLink>
                          </li>
                          <li>
                            <button
                              type="button"
                              className="dropdown-item item-dropdown-custom logout-header-btn"
                            >
                              Se déconnecter
                            </button>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default FrontHeader;
