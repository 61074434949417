import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import { Driver } from "../../../../../types";
import { getAvatar } from "../../../../utils/data";
import { CommonTable } from "../../../common/Input";
type UtilisateursTableProps = {
  drivers: Driver[];
  onChoice: (item: Driver) => void;
};
function UtilisateursTable({ drivers, onChoice }: UtilisateursTableProps) {
  const actionFormatter = (cell: string, row: Driver) => {
    return (
      <div className="table-actions-btn-container-action gap-2 d-flex">
        <button
          className="terranga-auto-dash-admin-btn-ajout-deux"
          onClick={() => {
            onChoice(row);
          }}
        >
          Assigner
        </button>
      </div>
    );
  };
  const nameAvatarFormatter = (cell: string, row: Driver) => {
    return (
      <div>
        <img
          src={getAvatar(row)}
          alt="user avatar"
          className="workcares-table-user-avatar"
        />{" "}
        {row.prenom} {row.nom}
      </div>
    );
  };

  const columns = [
    {
      dataField: "nom",
      text: "Nom",
      headerStyle: () => {
        return { width: "200px", whiteSpace: "normal" };
      },
      formatter: (cell: string, row: Driver) => nameAvatarFormatter(cell, row),
    },
    {
      dataField: "telephone",
      text: "Telephone",
    },
    {
      dataField: "nomSociete",
      text: "Nom Société",
    },
    {
      dataField: "action",
      text: "Actions",
      style: { textAlign: "center" },
      formatter: (cell: string, row: Driver) => actionFormatter(cell, row),
    },
  ];

  return <CommonTable keyField="uid" data={drivers} columns={columns} />;
}

export default UtilisateursTable;
