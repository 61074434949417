import { useEffect, useState } from "react";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import { Driver } from "../../../../../types";
import { useCLients } from "../../../../utils/api/hooks";
import { getAvatar } from "../../../../utils/data";
import { CommonTable } from "../../../common/Input";
type ClientsAdminTableProps = {
  identifier: string;
};
function ClientsAdminTable({ identifier }: ClientsAdminTableProps) {
  const clients = useCLients();
  const [data, setData] = useState(clients);
  useEffect(() => {
    const s = identifier.trim().toLowerCase();
    if (s) {
      setData(
        clients.filter((f) =>
          `${f.prenom} ${f.nom} ${f.telephone ?? ""}`.toLowerCase().includes(s)
        )
      );
    } else {
      setData(clients);
    }
  }, [identifier, clients]);

  const nameAvatarFormatter = (cell: string, row: Driver) => {
    return (
      <div>
        <img
          src={getAvatar(row)}
          alt="user avatar"
          className="workcares-table-user-avatar"
        />{" "}
        {row.prenom} {row.nom}
      </div>
    );
  };

  const columns = [
    {
      dataField: "nom",
      text: "Nom",
      headerStyle: () => {
        return { width: "200px", whiteSpace: "normal" };
      },
      formatter: (cell: string, row: Driver) => nameAvatarFormatter(cell, row),
    },
    {
      dataField: "telephone",
      text: "Telephone",
    },
    {
      dataField: "email",
      text: "Email",
    },
    {
      dataField: "nomSociete",
      text: "Nom Société",
    },
  ];

  return <CommonTable keyField="uid" data={data} columns={columns} />;
}

export default ClientsAdminTable;
