import { useCallback, useState } from "react";
import { AiOutlinePlusSquare } from "react-icons/ai";
import { useUser } from "../../../redux/root";
import AjouterPriseEnChargeModal from "../../admin/Chauffeurs/Modales/AjouterPriseEnChargeModal";
import PendingPriseEnChargeConcessionnairesTable from "../../admin/PriseEnChargeAdmin/Tables/PendingPriseEnChargeConcessionnairesTable";
import PriseEnChargeConcessionnairesTable from "../../admin/PriseEnChargeAdmin/Tables/PriseEnChargeConcessionnairesTable";

const tabs = ["Prise en charges", "Demande de prise en charges"];

function PriseEnChargeConcessionnaires() {
  const [index, setIndex] = useState(0);
  const go = useCallback((index: number) => {
    setIndex(index);
  }, []);

  const [search, setSearch] = useState("");
  const modalId = "AjouterPriseEnChageModal";

  const user = useUser();
  const userId = user.concessionnaireId || user.uid;

  return (
    <div className="dashbord-visiteur-component">
      <div className="workcare-dash-admin-page-content-container">
        <div
          className="modal fade"
          id={modalId}
          aria-labelledby="AjouterVehiculeModalLabel"
          aria-hidden="true"
        >
          <AjouterPriseEnChargeModal modalId={modalId} />
        </div>
        <ul className="tabs-container mt-4">
          {tabs.map((tab, key) => (
            <li
              className={`tab-item  ${index === key && "tab-active-item"}`}
              key={key}
              style={{ fontSize: 13 }}
              onClick={() => go(key)}
            >
              {tab}
            </li>
          ))}
        </ul>

        <div className="locataire-requete-filtre-container mb-4">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            {index === 1 && (
              <>
                <button
                  className="terranga-auto-dash-admin-btn-ajout-deux"
                  data-bs-toggle="modal"
                  data-bs-target={`#${modalId}`}
                >
                  <AiOutlinePlusSquare className="terranga-auto-dash-admin-btn-ajout-icone" />{" "}
                  Ajouter une demande
                </button>
              </>
            )}
          </div>
          <form className="row g-3 locataire-requete-filtre-form">
            <div className="col-auto">
              <label
                htmlFor="filtreLogement"
                className="locataire-requete-filtre-label"
              >
                Filtrer
              </label>
            </div>
            <div className="col-auto">
              <input
                type="text"
                className="form-control locataire-requete-filtre-input"
                id="filtreLogement"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                placeholder="Rechercher..."
              />
            </div>
          </form>
        </div>
        {index === 0 && (
          <PriseEnChargeConcessionnairesTable userId={userId} search={search} />
        )}
        {index === 1 && (
          <PendingPriseEnChargeConcessionnairesTable
            userId={userId}
            search={search}
          />
        )}
      </div>
    </div>
  );
}

export default PriseEnChargeConcessionnaires;
