import { DriverFormData } from "../../../../../types";
import { useCreateDriver } from "../../../../utils/api/hooks";
import {
  getFormattedPhoneNumber,
  isPhoneValidPhoneNumber,
  useModalActions,
} from "../../../../utils/data";
import { useFormData } from "../../../../utils/form";
import { FormError } from "../../../auth/Login";
import {
  BtnAnnulerModal,
  BtnCloseModal,
  BtnSubmit,
} from "../../../common/Button";
import { Input } from "../../../common/Input";
import GooglePlaceInput from "../../../shared/GooglePlace";
type AjouterChauffeurModalProps = {
  modalId?: string;
  userId?: string;
};
function AjouterChauffeurModal({
  modalId,
  userId,
}: AjouterChauffeurModalProps) {
  const ma = useModalActions(modalId ?? "");

  const { errors, onChange, data, setErrors, onSubmitForm, register } =
    useFormData<DriverFormData>(
      {
        email: "",
        prenom: "",
        nom: "",
        telephone: "",
        address: "",
        nbNotes: 0,
        avgNote: 0,
        nbTrajets: 0,
        ...(userId ? { userId } : {}),
      },
      (Yup) => ({
        email: Yup.string().email().notRequired().label("Email"),
        prenom: Yup.string().required().label("Prénom"),
        nom: Yup.string().required().label("Nom"),
        telephone: Yup.string().required().label("Téléphone"),
        address: Yup.string().required().label("Adresse"),
      })
    );

  const { loading, onAddDriver } = useCreateDriver(() => {
    ma.hide();
    setTimeout(() => {
      onChange({
        email: "",
        prenom: "",
        nom: "",
        telephone: "",
        address: "",
      });
    }, 1500);
  });

  return (
    <div className="modal-dialog modal-lg">
      <div className="modal-content custom-modal-content">
        <div className="modal-header custom-modal-header px-2 py-1">
          <h5 className="modal-title" id="AddTagModalLabel">
            Ajouter un chauffeur
          </h5>
          <div className="workcares-btn-close-modal-container">
            <BtnCloseModal label="" />
          </div>
        </div>
        <div className="modal-body">
          <div className="container-form">
            <form
              onSubmit={(e) => {
                e.preventDefault();
                onSubmitForm((data) => {
                  if (isPhoneValidPhoneNumber(data.telephone)) {
                    data.telephone = getFormattedPhoneNumber(
                      data.telephone ?? ""
                    )?.international;
                    onAddDriver(data);
                  } else {
                    setErrors({ telephone: "Numéro de téléphone invalid" });
                  }
                });
              }}
            >
              <div className="row">
                <div className="col-md-6">
                  <Input
                    type="text"
                    label="Prénom"
                    placeholder="Prénom"
                    {...register("prenom")}
                  />
                  <FormError error={errors.prenom} />
                </div>
                <div className="col-md-6">
                  <Input
                    type="text"
                    label="Nom"
                    placeholder="Nom"
                    {...register("nom")}
                  />
                  <FormError error={errors.nom} />
                </div>
                <div className="col-md-6">
                  <Input
                    type="text"
                    label="Téléphone"
                    placeholder="Téléphone"
                    {...register("telephone")}
                  />
                  <FormError error={errors.telephone} />
                </div>
                <div className="col-md-6">
                  <Input
                    type="email"
                    label="Email"
                    placeholder="Email"
                    {...register("email")}
                  />
                  <FormError error={errors.email} />
                </div>
                <div className="col-md-6">
                  <GooglePlaceInput
                    onChange={(data) => {
                      onChange(data);
                    }}
                    value={data.address ?? ""}
                    className=""
                    placeholder="Adresse"
                  />
                  <FormError error={errors.address} />
                </div>

                <div className="col-md-6 offset-md-6">
                  <div className="mb-3 mt-5 flex-r gap-3">
                    <BtnAnnulerModal label={"Fermer"} isLoading={false} />
                    <BtnSubmit
                      label="Ajouter"
                      isLoading={loading}
                      style={{ padding: "5px 10px", fontSize: 14 }}
                    />
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AjouterChauffeurModal;
