import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
// import { FaTrash } from "react-icons/fa";
import { useEffect, useState } from "react";
import { useConcessionnaireRequests } from "../../../../utils/api/hooks";
import CommonPriseEnChargeAdminTable from "./CommonPriseEnChargeTable";

type PriseEnChargeConcessionnairesTableProps = {
  search: string;
  userId: string;
};

function PriseEnChargeConcessionnairesTable({
  search,
  userId,
}: PriseEnChargeConcessionnairesTableProps) {
  const requests = useConcessionnaireRequests(userId);
  const [data, setData] = useState(requests);

  useEffect(() => {
    const s = search.trim().toLowerCase();
    if (s) {
      setData(
        requests.filter(
          (f) =>
            `${f.identifier} ${f.fullname ?? ""} ${f.telephone ?? ""}`
              .toLowerCase()
              .includes(s) && f.user
        )
      );
    } else {
      setData(requests.filter((f) => f.user));
    }
  }, [search, requests]);

  return <CommonPriseEnChargeAdminTable data={data} />;
}

export default PriseEnChargeConcessionnairesTable;
