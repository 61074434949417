/* eslint-disable @typescript-eslint/no-use-before-define */
import React from "react";
import PlacesAutocomplete, {
  geocodeByAddress,
} from "react-places-autocomplete";
import { GooglePlaceData } from "../../../types";

type GooglePlaceInputProps = {
  value: string;
  className?: string;
  onChange: (data: GooglePlaceData) => void;
  label?: string;
  placeholder?: string;
};
function GooglePlaceInput({
  value,
  onChange,
  className = "",
  label,
  placeholder,
}: GooglePlaceInputProps) {
  const [search, setSearch] = React.useState(value || "");
  React.useEffect(() => {
    setSearch(value);
  }, [value]);
  const onError = React.useCallback(
    (status: string, clearSuggestions: () => void) => {
      console.log("Google Maps API returned error with status: ", status);
      clearSuggestions();
    },
    []
  );
  const searchOptions = {
    componentRestrictions: { country: "SN" },
  };
  return (
    <PlacesAutocomplete
      value={search || ""}
      onError={onError}
      onChange={setSearch}
      searchOptions={searchOptions}
      onSelect={async (address) => {
        setSearch(address);
        try {
          const res = await geocodeByAddress(address);
          const data = getAddressFromReults(res);
          if (data) {
            const tem: GooglePlaceData = {
              ...data,
              address: address,
            };
            console.log(tem);
            onChange(tem);
            return;
          }
        } catch (ex) {}
      }}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <div className="mb-3" style={{ textAlign: "left" }}>
          <label
            htmlFor={"address"}
            className="form-label form-label-modal-custom"
            aria-labelledby={"address"}
          >
            {label || "Adresse"}
          </label>
          <input
            {...getInputProps({
              placeholder: placeholder ? placeholder : "Adresse",
              className: "form-control form-control-modal-custom",
            })}
            data-testid="address"
          />
          <div className="autocomplete-dropdown-container fixe-autocomplete">
            {loading && <div>Loading...</div>}
            {suggestions.map((suggestion) => {
              const style = suggestion.active
                ? {
                    backgroundColor: "#fafafa",
                    cursor: "pointer",
                  }
                : {
                    backgroundColor: "#EEE",
                    cursor: "pointer",
                  };
              return (
                <div
                  {...getSuggestionItemProps(suggestion, {
                    style: {
                      ...style,
                      ...{
                        padding: 10,
                        marginBottom: 2,
                        fontWeight: "bold",
                        color: "#2c3e50",
                      },
                    },
                  })}
                >
                  <span>{suggestion.description}</span>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </PlacesAutocomplete>
  );
}

export default GooglePlaceInput;

type Geo = {
  location: {
    lat: () => number;
    lng: () => number;
  };
};
type AddressComponents = { types: string[]; long_name: string };
type Results = {
  address_components: AddressComponents[];
  formatted_address: string;
  geometry: Geo;
};

export function getAddressFromReults(
  results: Results[]
): GooglePlaceData | null {
  console.log("results", results);
  let res = getAd(results, ["street_address"]);
  if (res === null) {
    res = getAd(results, ["route"]);
  }
  if (res === null) {
    res = getAd(results, ["neighborhood", "political"]);
  }
  if (res === null) {
    res = getAd(results, ["sublocality_level_1"]);
  }
  if (res === null) {
    res = getAd(results, ["country"]);
  }
  return res;
}
function getAd(results: Results[], criters: string[]) {
  for (const result of results) {
    const { address_components } = result;

    let exists = false;
    for (let ad of address_components) {
      for (const type of criters) {
        if (ad.types.includes(type)) exists = true;
      }
    }
    if (exists) {
      return {
        address: String(result?.formatted_address),
        latitude: String(result?.geometry?.location?.lat()),
        longitude: String(result?.geometry?.location?.lng()),
        base: getCurrentBaseFromAdress(results) || "",
        ville: getCurrentCityFromAdress(results) || "",
      } as GooglePlaceData;
    }
  }
  return null;
}
export function getCurrentBaseFromAdress(results: Results[]) {
  let res = getAddress(results, ["administrative_area_level_1"]);
  if (res === false) {
    res = getAddress(results, ["administrative_area_level_2"]);
  }
  if (res === false) {
    res = getAddress(results, ["locality"]);
  }
  if (res === false) {
    res = getAddress(results, ["sublocality"]);
  }
  if (res === false) {
    res = getAddress(results, ["sublocality_level_1"]);
  }
  return res;
}

export function getCurrentCityFromAdress(results: Results[]) {
  let res = getAddress(results, ["sublocality_level_1"]);
  if (res === false) {
    res = getAddress(results, ["sublocality"]);
  }
  if (res === false) {
    res = getAddress(results, ["locality"]);
  }
  if (res === false) {
    res = getAddress(results, ["administrative_area_level_2"]);
  }
  if (res === false) {
    res = getAddress(results, ["administrative_area_level_1"]);
  }
  return res;
}
function getAddress(results: Results[], criters: string[]) {
  for (let { address_components } of results) {
    for (let ad of address_components) {
      let exists = true;
      for (let type of criters) {
        if (!ad.types.includes(type)) exists = false;
      }
      if (exists === true) {
        return ad.long_name;
      }
    }
  }
  return false;
}
