import { getApps, initializeApp } from "firebase/app";
// Optionally import the services that you want to use

const firebaseConfig = {
  apiKey: "AIzaSyAPP9zeGYiB3hM6nrWmcAMIJl5o5oQ4-zY",
  authDomain: "terangaautomobile-e4eeb.firebaseapp.com",
  projectId: "terangaautomobile-e4eeb",
  storageBucket: "terangaautomobile-e4eeb.appspot.com",
  messagingSenderId: "132404887407",
  appId: "1:132404887407:web:4d31a7a0d70baf4ef5729a",
  databaseURL:
    "https://terangaautomobile-e4eeb-default-rtdb.europe-west1.firebasedatabase.app",
  measurementId: "G-XV92C9VZBW",
};

export const PATHS = {
  users: "users",
  tarif: "tarif",
  landing_page: "landing_page",
  jours: "jours",
  faq: "faq",
  newsletter: "newsletter",
  vehicules: "vehicules",
  invoices: "invoices",
  content: "content",
  requests: "requests",
  contacts: "contacts",
  contents: "contents",
  notifications: "notifications",
};

export const STORAGE_PATHS = {
  avatars: "avatars",
  images: "images",
  invoices: "images",
};

if (getApps().length === 0) {
  initializeApp(firebaseConfig);
}
