import moment from "moment";
import {
  GooglePlaceData,
  RawRequestFormData,
  RequestFormData,
} from "../../../../../types";
import { useUser } from "../../../../redux/root";
import {
  useSendRequests,
  useVehiculesConcessionnaire,
} from "../../../../utils/api/hooks";
import { getImage, useModalActions } from "../../../../utils/data";
import { useFormData } from "../../../../utils/form";
import { FormError } from "../../../auth/Login";
import {
  BtnAnnulerModal,
  BtnCloseModal,
  BtnSubmit,
} from "../../../common/Button";
import { Select } from "../../../common/Input";
import GooglePlaceInput from "../../../shared/GooglePlace";
type AjouterPriseEnChargeModalProps = {
  modalId?: string;
};
function AjouterPriseEnChargeModal({
  modalId,
}: AjouterPriseEnChargeModalProps) {
  const user = useUser();
  const userId = user.concessionnaireId || user.uid;
  const vehicules = useVehiculesConcessionnaire(userId);
  const ma = useModalActions(modalId ?? "");

  const { errors, onChange, data, onSubmitForm, register } =
    useFormData<RawRequestFormData>({ vehiculeId: "" }, (Yup) => ({
      from: Yup.mixed().required().label("De"),
      to: Yup.mixed().required().label("A"),
      vehiculeId: Yup.string().required().label("Véhicule"),
    }));

  const { loading, onAdd } = useSendRequests((data) => {
    if (data) {
      ma.hide();
      setTimeout(() => {
        onChange({});
      }, 1500);
    }
  });

  return (
    <div className="modal-dialog modal-lg">
      <div className="modal-content custom-modal-content">
        <div className="modal-header custom-modal-header px-2 py-1">
          <h5 className="modal-title" id="AddTagModalLabel">
            Ajouter une demande de prise en chrage
          </h5>
          <div className="workcares-btn-close-modal-container">
            <BtnCloseModal label="" />
          </div>
        </div>
        <div className="modal-body">
          <div className="container-form">
            <form
              onSubmit={(e) => {
                e.preventDefault();
                onSubmitForm((data) => {
                  const vehicule = vehicules.find(
                    (v) => v.uid === data.vehiculeId
                  );
                  if (vehicule) {
                    const client = vehicule.client || user;
                    const newData: RequestFormData = {
                      date: moment().toDate().getTime(),
                      location: {
                        from: data.from as GooglePlaceData,
                        to: data.to as GooglePlaceData,
                      },
                      user: null,
                      token: client.token || null,
                      fullname: `${client.prenom} ${client.nom}`,
                      identifier: vehicule.matricule,
                      image: getImage(vehicule),
                      mode: vehicule.mode,
                      telephone: client.telephone,
                      vehiculeId: vehicule.uid,
                      concessionnaireId: userId,
                    };
                    if (vehicule.clientId) {
                      newData.anonymousId = vehicule.clientId;
                    }
                    onAdd(newData);
                  }
                });
              }}
            >
              <div className="row">
                <div className="col-md-12">
                  <GooglePlaceInput
                    onChange={(data) => {
                      // @ts-ignore
                      onChange("from", data);
                    }}
                    value={data.from?.address ?? ""}
                    className=""
                    label="De"
                    placeholder="Ajouter votre position"
                  />
                  <FormError error={errors.from as any} />
                </div>
                <div className="col-md-12">
                  <GooglePlaceInput
                    onChange={(data) => {
                      // @ts-ignore
                      onChange("to", data);
                    }}
                    value={data.to?.address ?? ""}
                    className=""
                    label="A"
                    placeholder="Destionation du véhicule"
                  />
                  <FormError error={errors.to as any} />
                </div>

                <div className="col-md-12">
                  <Select
                    label="Véhicule"
                    data={vehicules.map((v) => ({
                      value: v.uid,
                      label: `${v.matricule} - ${v.model}`,
                    }))}
                    placeholder="Véhicule"
                    {...register("vehiculeId")}
                  />
                  <FormError error={errors.vehiculeId} />
                </div>

                <div className="col-md-6 offset-md-6">
                  <div className="mb-3 mt-5 flex-r gap-3">
                    <BtnAnnulerModal label={"Fermer"} isLoading={false} />
                    <BtnSubmit
                      label={"Ajouter"}
                      isLoading={loading}
                      style={{ padding: "5px 10px", fontSize: 14 }}
                    />
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AjouterPriseEnChargeModal;
