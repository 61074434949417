import { useState } from "react"
import "./ClientsAdmin.css"
import ClientsAdminTable from "./Tables/ClientsAdminTable"

function ClientsAdmin() {
  const [search,setSearch] = useState('')
  return (
    <div className="dashbord-visiteur-component">
      <div className="terranga-auto-dash-admin-titre-btn-container mb-4">
        <div className="workcare-dash-admin-titre-container">
          <h1 className="terranga-auto-dash-admin-titre">Clients</h1>
        </div>
      </div>
      <div className="workcare-dash-admin-page-content-container">
        <div className="locataire-requete-filtre-container mb-4">
          <form className="row g-3 locataire-requete-filtre-form">
            <div className="col-auto">
              <label
                htmlFor="filtreLogement"
                className="locataire-requete-filtre-label"
              >
                Filtrer
              </label>
            </div>
            <div className="col-auto">
              <input
                type="text"
                className="form-control locataire-requete-filtre-input"
                id="filtreLogement"
                value={search}
                onChange={e => setSearch(e.target.value)}
                placeholder="Rechercher..."
              />
            </div>
          </form>
        </div>
        <ClientsAdminTable identifier={search} />
      </div>
    </div>
  )
}

export default ClientsAdmin
