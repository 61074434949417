import { getDatabase, onValue, ref } from "firebase/database";
import { useEffect, useRef, useState } from "react";
import { GooglePlaceData } from "../../../types";

const db = getDatabase();

export function useLocationTraking(userId: string) {
  const [data, setData] = useState<GooglePlaceData>();
  const [loading, setLoading] = useState(true);
  const userRef = useRef(ref(db, `locations/${userId}`)).current;
  useEffect(() => {
    const subscribe = onValue(userRef, (snapshot) => {
      setData(snapshot.val());
      setLoading(false);
    });
    return () => subscribe();
  }, []);

  console.log({ loading, data });

  return { loading, data };
}
