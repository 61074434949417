import { useState } from "react"
import ReactQuill from "react-quill"
import { MessageContactType } from "../../../../../types"
import { useResponseContact } from "../../../../utils/api/hooks"
import { SubmitButton } from "../../../auth/Login"
import CloseModalBtn from "../../../shared/CloseModalBtn"
import "./MessageContactModale.css"

const DetailsMessageModal = ({
  item,
  onHide,
}: {
  item: MessageContactType
  onHide: () => void
}) => {
  const [value, setValue] = useState("")
  const { loading, onResponse } = useResponseContact(() => {
    onHide()
    setTimeout(() => {
      setValue("")
    }, 2000)
  })

  return (
    <div className="modal-dialog modal-dialog-centered modal-lg">
      <div className="modal-content">
        <div className="modal-header">
          <h5
            className="modal-title admin-modal-title text-start"
            id="DetailsMessageModal"
          >
            Réponse à ({item.name})
          </h5>
          <CloseModalBtn />
        </div>
        <div className="form-container px-3">
          <div className="row border-bottom pb-2">
            <div className="col-md-12 form-group">
              <div className="admin-content-message">{item.message}</div>
            </div>
          </div>
          <form
            onSubmit={(e) => {
              e.preventDefault()
              if (value.trim().length > 0) {
                onResponse(item.id, value)
              }
            }}
          >
            <div className="row mb-4 mt-4">
              {item.responses?.map((r, index) => {
                return (
                  <div key={index} className="col-md-12 my-2 form-group">
                    <div
                      className="admin-content-message"
                      dangerouslySetInnerHTML={{ __html: r }}
                    />
                  </div>
                )
              })}
              <div className="col-md-12 mb-3">
                <div className="mb-4 form-group">
                  <label className="form-label label-form-admin">Réponse</label>
                  <ReactQuill value={value} onChange={setValue} />
                </div>
              </div>
            </div>
            <div className="gap-3 flex-r align-center row my-4">
              <div className="col-md-3">
                <button
                  type="button"
                  className="btn btn-cancelled"
                  onClick={() => onHide()}
                >
                  Fermer
                </button>
              </div>
              <div className="col-md-3">
                <SubmitButton title="Envoyer" loading={loading} />
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default DetailsMessageModal
