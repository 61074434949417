import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
// import { FaTrash } from "react-icons/fa";
import { useEffect, useState } from "react";
import { useOwnersRequests } from "../../../../utils/api/hooks";
import CommonPriseEnChargeAdminTable from "./CommonPriseEnChargeTable";

type PriseEnChargeProprietaireTableProps = {
  search: string;
  userId: string;
};

function PriseEnChargeProprietaireTable({
  search,
  userId,
}: PriseEnChargeProprietaireTableProps) {
  const requests = useOwnersRequests(userId);
  const [data, setData] = useState(requests);
  useEffect(() => {
    const s = search.trim().toLowerCase();
    if (s) {
      setData(
        requests.filter((f) =>
          `${f.identifier} ${f.fullname ?? ""} ${f.telephone ?? ""}`
            .toLowerCase()
            .includes(s)
        )
      );
    } else {
      setData(requests);
    }
  }, [search, requests]);

  return <CommonPriseEnChargeAdminTable isPro data={data} />;
}

export default PriseEnChargeProprietaireTable;
