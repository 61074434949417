import { Navigate } from "react-router-dom";

import ParametresConcessionnaire from "../components/Concessionnaires/Parametres/ParametresConcessionnaire";
import DetailVehicule from "../components/Concessionnaires/Vehicules/DetailVehicule";
import VehiculesConcessionnaires from "../components/Concessionnaires/Vehicules/VehiculesConcessionnaires";
import PriseEnChargeConcessionnaires from "../components/Concessionnaires/prisepnchargeconcessionnaires/PriseEnChargeConcessionnaires";
import ProfileConcessionnaire from "../components/Concessionnaires/profil/ProfileConcessionnaire";
import LandingPage from "../components/LandingPage/LandingPage";
import Privacy from "../components/LandingPage/Privacy";

import InvoiceConcessionnaires from "../components/Concessionnaires/Invoices/InvoiceConcessionnaires";
import AdminLayout from "../components/admin/AdminLayout/AdminLayout";
import ChauffeursAdmin from "../components/admin/Chauffeurs/ChauffeursAdmin";
import DetailsChauffeurAdmin from "../components/admin/Chauffeurs/DetailsChauffeurAdmin";
import ClientsAdmin from "../components/admin/Clients/ClientsAdmin";
import DashboardAdmin from "../components/admin/DashboardAdmin/DashboardAdmin";
import DashboardProprietaire from "../components/admin/DashboardAdmin/DashboardProprietaire";
import InvoicesAdmin from "../components/admin/Invoices/InvoicesAdmin";
import LandigPageSettings from "../components/admin/Parametres/LandigPageSettings";
import NewslatterAdmin from "../components/admin/Parametres/NewslatterAdmin";
import ParametresAdmin from "../components/admin/Parametres/ParametresAdmin";
import Tarification from "../components/admin/Parametres/Tarification";
import PriseEnChargeAdmin from "../components/admin/PriseEnChargeAdmin/PriseEnChargeAdmin";
import PriseEnChargeAdminDetail from "../components/admin/PriseEnChargeAdmin/PriseEnChargeAdminDetail";
import ProfileAdmin from "../components/admin/ProfileAdmin/ProfileAdmin";
import DetailsProprietaireAdmin from "../components/admin/Proprietaires/DetailsProprietaireAdmin";
import ProprietairesAdmin from "../components/admin/Proprietaires/ProprietairesAdmin";
import VehiculesAdmin from "../components/admin/Vehicules/VehiculesAdmin";
import ConcessionnairesAdmin from "../components/admin/concessionnaires/ConcessionnairesAdmin";
import DetailsConcessionnaireAdmin from "../components/admin/concessionnaires/DetailsConcessionnaireAdmin";
import MessageContact from "../components/admin/messageContact/MessageContact";
import ForgetPassword from "../components/auth/ForgetPassword";
import Login from "../components/auth/Login";

import DashboardConcessionnaires from "../components/admin/DashboardAdmin/DashboardConcessionnaires";
import Priseenchargeproprietaires from "../components/proprietaires/priseenchargeproprietaires/RequestOwner";
import ProfileProprietaire from "../components/proprietaires/profil/ProfileOwner";
import SettingsOwner from "../components/proprietaires/settings/SettingsOwner";
import DetailVehiculeProprietaire from "../components/proprietaires/vehicule/DetailVehiculeProprietaire";
import VehiculesProprietaires from "../components/proprietaires/vehicule/VehiculesProprietaires";
import { useUser } from "../redux/root";
import {
  getRedirectRoute,
  isAdmin,
  isConcessionnaires,
  isOwner,
} from "../utils/api/hooks";

// import Priseenchargeproprietaires from "../components/Proprietaires/priseenchargeproprietaires/RequestOwner";
// import ProfileProprietaire from "../components/Proprietaires/profil/ProfileOwner";
// import SettingsOwner from "../components/Proprietaires/settings/SettingsOwner";
// import DetailVehiculeProprietaire from "../components/Proprietaires/vehicule/DetailVehiculeProprietaire";
// import VehiculesProprietaires from "../components/Proprietaires/vehicule/VehiculesProprietaires";

function GuestComponent({
  component: Component,
}: {
  component: () => JSX.Element | null;
}) {
  const user = useUser();
  if (user) return <Navigate to={getRedirectRoute(user)} replace />;

  return <Component />;
}

function AdminComponent({
  component: Component,
}: {
  component: () => JSX.Element | null;
}) {
  const user = useUser();
  if (!isAdmin(user)) return <Navigate to={getRedirectRoute(user)} replace />;

  return <Component />;
}

function ProComponent({
  component: Component,
}: {
  component: () => JSX.Element | null;
}) {
  const user = useUser();
  if (!isOwner(user)) return <Navigate to={getRedirectRoute(user)} replace />;
  return <Component />;
}

function ConcessionnaireComponent({
  component: Component,
}: {
  component: () => JSX.Element | null;
}) {
  const user = useUser();
  if (!isConcessionnaires(user))
    return <Navigate to={getRedirectRoute(user)} replace />;
  return <Component />;
}

export const AppRoutes = [
  {
    path: "/",
    element: <LandingPage />,
  },
  {
    path: "/privacy",
    element: <Privacy />,
  },
  {
    path: "/connexion",
    element: <GuestComponent component={Login} />,
  },
  {
    path: "/mot-de-passe-oublie",
    element: <GuestComponent component={ForgetPassword} />,
  },
  {
    path: "admin/",
    element: <AdminComponent component={AdminLayout} />,
    children: [
      { element: <Navigate to="/admin/dashboard" replace />, index: true },
      {
        path: "dashboard",
        element: <DashboardAdmin />,
      },
      {
        path: "clients",
        element: <ClientsAdmin />,
      },
      {
        path: "factures",
        element: <InvoicesAdmin />,
      },
      {
        path: "chauffeurs",
        element: <ChauffeursAdmin />,
      },
      {
        path: "chauffeur/:slug",
        element: <DetailsChauffeurAdmin />,
      },
      {
        path: "proprietaires",
        element: <ProprietairesAdmin />,
      },
      {
        path: "proprietaire/:slug",
        element: <DetailsProprietaireAdmin />,
      },
      {
        path: "concessionnaires",
        element: <ConcessionnairesAdmin />,
      },
      {
        path: "concessionnaires/:slug",
        element: <DetailsConcessionnaireAdmin />,
      },
      {
        path: "vehicules",
        element: <VehiculesAdmin />,
      },
      {
        path: "vehicules/:id",
        element: <DetailVehiculeProprietaire />,
      },
      {
        path: "message-contact",
        element: <MessageContact />,
      },
      {
        path: "prise-en-charge",
        element: <PriseEnChargeAdmin />,
      },
      {
        path: "prise-en-charge/:slug",
        element: <PriseEnChargeAdminDetail />,
      },
      {
        path: "tarification",
        element: <Tarification />,
      },
      {
        path: "landing-page",
        element: <LandigPageSettings />,
      },
      {
        path: "newsletter",
        element: <NewslatterAdmin />,
      },
      {
        path: "parametres",
        element: <ParametresAdmin />,
      },
      {
        path: "profile",
        element: <ProfileAdmin />,
      },
    ],
  },
  {
    path: "proprietaire/",
    element: <ProComponent component={AdminLayout} />,
    children: [
      {
        element: <Navigate to="/proprietaire/dashboard" replace />,
        index: true,
      },
      {
        path: "dashboard",
        element: <DashboardProprietaire />,
      },
      {
        path: "vehicules",
        element: <VehiculesProprietaires />,
      },
      {
        path: "vehicules/:id",
        element: <DetailVehiculeProprietaire />,
      },
      {
        path: "prise-en-charges",
        element: <Priseenchargeproprietaires />,
      },
      {
        path: "prise-en-charge/:slug",
        element: <PriseEnChargeAdminDetail />,
      },
      {
        path: "parametres",
        element: <SettingsOwner />,
      },
      {
        path: "profile",
        element: <ProfileProprietaire />,
      },
    ],
  },
  {
    path: "concessionnaire/",
    element: <ConcessionnaireComponent component={AdminLayout} />,
    children: [
      {
        element: <Navigate to="/proprietaire/dashboard" replace />,
        index: true,
      },
      {
        path: "dashboard",
        element: <DashboardConcessionnaires />,
      },
      {
        path: "vehicules",
        element: <VehiculesConcessionnaires />,
      },
      {
        path: "vehicules/:id",
        element: <DetailVehicule />,
      },
      {
        path: "prise-en-charges",
        element: <PriseEnChargeConcessionnaires />,
      },
      {
        path: "prise-en-charge/:slug",
        element: <PriseEnChargeAdminDetail />,
      },
      {
        path: "factures",
        element: <InvoiceConcessionnaires />,
      },
      {
        path: "parametres",
        element: <ParametresConcessionnaire />,
      },
      {
        path: "profile",
        element: <ProfileConcessionnaire />,
      },
    ],
  },
];
