import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Tarif } from "../../../types";

const initialState: Tarif = {
  dk_tarif: 0,
  interurbain_tarif: 0,
  majoration_ferier: 0,
  majoration_nuit: 0,
};

export const TarifSlice = createSlice({
  name: "tarif",
  initialState,
  reducers: {
    onGetTarif: (state, action: PayloadAction<Tarif>) => {
      state = action.payload;
      return state;
    },
  },
});

export const { onGetTarif } = TarifSlice.actions;

export default TarifSlice.reducer;
