import moment from "moment";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import { AiFillEye } from "react-icons/ai";
// import { FaTrash } from "react-icons/fa";
import { useEffect, useState } from "react";
import { FaTrash } from "react-icons/fa";
import { RequestOwner } from "../../../../../types";
import {
  useAdminPendingRequests,
  useDeleteItem,
} from "../../../../utils/api/hooks";
import { deleteRequest } from "../../../../utils/api/user.api";
import { CommonTable } from "../../../common/Input";
import AssignRequestModal from "../../Parametres/Modales/AssignRequestModal";

function PendingAssignationAdminTable({
  search,
  userId,
}: {
  search: string;
  userId?: string;
}) {
  const requests = useAdminPendingRequests(userId);

  const onDelete = useDeleteItem(
    deleteRequest,
    `Êtes-vous sûr de vouloir supprimer cette demande de prise en charge ?`,
    `Demande de prise en charge supprimée avec succés`,
    `Demande de prise en chargenon supprimée`
  );

  const [data, setData] = useState(requests);

  useEffect(() => {
    const s = search.trim().toLowerCase();
    if (s) {
      setData(
        requests.filter((f) =>
          `${f.identifier} ${f.fullname ?? ""} ${f.telephone ?? ""}`
            .toLowerCase()
            .includes(s)
        )
      );
    } else {
      setData(requests);
    }
  }, [search, requests]);
  const actionFormatter = (cell: string, row: RequestOwner) => {
    const modalId = `AjouterAdministrateurModal${row.uid}`;
    return (
      <div className="table-actions-btn-container-action gap-2 d-flex">
        <button
          className="terranga-auto-dash-admin-btn-ajout-deux"
          data-bs-toggle="modal"
          data-bs-target={`#${modalId}`}
        >
          <AiFillEye />
        </button>
        <button
          className="terranga-auto-dash-admin-btn-ajout-deux"
          data-tooltip-content="Supprimer"
          onClick={() => {
            onDelete(row.uid);
          }}
        >
          <FaTrash />
        </button>
        <div
          className="modal fade"
          id={modalId}
          aria-labelledby="AjouterAdministrateurModalLabel"
          aria-hidden="true"
        >
          <AssignRequestModal item={row} modalId={modalId} />
        </div>
      </div>
    );
  };
  const chargeFormatter = (cell: string, row: RequestOwner) => {
    return (
      <div>
        <img
          src={row.image}
          alt="car img"
          className="terranga-auto-table-img-car"
        />{" "}
        {row.identifier}
      </div>
    );
  };

  const AddressFormatter = (cell: string, row: RequestOwner) => {
    return (
      <div>
        {row.location.from.ville} {"=>"} {row.location.to.ville}
      </div>
    );
  };

  const columns = [
    {
      dataField: "uid",
      text: "Prise en charge",
      formatter: chargeFormatter,
      headerStyle: () => {
        return { width: "250px", whiteSpace: "normal" };
      },
    },
    {
      dataField: "location",
      text: "Localisation",
      formatter: AddressFormatter,
      headerStyle: () => {
        return { width: "250px", whiteSpace: "normal" };
      },
    },
    {
      dataField: "date",
      text: "Date",
      formatter: (cell: string) => moment(cell).format("DD/MM/YYYY"),
    },
    {
      dataField: "action",
      text: "Actions",
      style: { textAlign: "center" },
      formatter: actionFormatter,
    },
  ];

  return <CommonTable keyField="uid" data={data} columns={columns} />;
}

export default PendingAssignationAdminTable;
