import { Filter, FilterData, Request } from "../../../types";
import { getRequestAmount } from "../data";
import {
  useAdminDahsboardPendingRequests,
  useAdminDashboardPendingOtherRequests,
  useConcessionnaires,
  useDashbaordAdminRequests,
  useDashboardConcessionnaireRequests,
  useDashboardOwnersRequests,
  useDrivers,
  useOwners,
  useVehiculesConcessionnaire,
  useVehiculesProprietaires,
} from "./hooks";

export function useDashboardAdmin(filter: Filter) {
  const drivers = useDrivers();
  const concessionnaires = useConcessionnaires();
  const owners = useOwners();
  const pendingAssignations = useAdminDahsboardPendingRequests(
    undefined,
    filter
  );
  const requests = useDashbaordAdminRequests(filter);
  const pendingRequests = useAdminDashboardPendingOtherRequests(filter);
  return {
    drivers: drivers.length,
    owners: owners.length,
    concessionnaires: concessionnaires.length,
    pendingAssignations: pendingAssignations.count,
    requests: requests.count,
    requestsData: requests.data,
    sumAmounts: sumRequests(requests.data),
    pendingRequests: pendingRequests.count,
  };
}

export function useDashboardConcessionnaires(filter: Filter, userId: string) {
  const vehicules = useVehiculesConcessionnaire(userId);
  const requests = useDashboardConcessionnaireRequests(userId, filter);

  return {
    vehicules: vehicules.length,
    requests: requests.requests,
    requestsData: requests.data,
    sumAmounts: sumRequests(requests.data),
    pendingRequests: requests.pending,
  };
}

export function useDashboardProprietaire(filter: Filter, userId: string) {
  const vehicules = useVehiculesProprietaires(userId);
  const requests = useDashboardOwnersRequests(userId, filter);

  return {
    vehicules: vehicules.length,
    requests: requests.requests,
    requestsData: requests.data,
    sumAmounts: sumRequests(requests.data, true),
    pendingRequests: requests.pending,
  };
}

function sumRequests(requests: Request[], isPro = false) {
  return requests.reduce((acc, cur) => {
    return acc + getRequestAmount(cur, isPro);
  }, 0);
}

export const FilterDataList: FilterData[] = [
  { label: "Ce mois-ci", value: "month" },
  { label: "Le mois passé", value: "last month" },
  { label: "Cette semaine", value: "week" },
  { label: "Cette année-ci", value: "year" },
  { label: "Tous", value: "all" },
];
