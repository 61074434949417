import "bootstrap/dist/css/bootstrap.min.css";
import { useEffect } from "react";
import { useLocation, useRoutes } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "./redux/root";
import { onGetContent } from "./redux/slices/content.slice";
import { onDeleteFaq, onFaq } from "./redux/slices/faq.slice";
import { onDeleteInvoices, onSetInvoices } from "./redux/slices/invoice.slice";
import { onGetLandingPage } from "./redux/slices/landingPage.slice";
import { onMessageContact } from "./redux/slices/message-contact.slice";
import { setNotifs } from "./redux/slices/notification.slice";
import { onDeleteRequests, onGetRequests } from "./redux/slices/request.slice";
import { onLogout, onSetUserInfos } from "./redux/slices/user.slice";
import { onDeleteUsers, onUsers } from "./redux/slices/users.slice";
import {
  onDeleteVehicules,
  onSetVehicules,
} from "./redux/slices/vehicule.slice";
import { AppRoutes } from "./routes/routes";
import { authListener } from "./utils/api/auth.api";
import { isAdmin, isConcessionnaires, isOwner } from "./utils/api/hooks";
import {
  VehiculeListener,
  contentListener,
  driverListener,
  faqListener,
  invoicesListener,
  landingPageListener,
  messageContactListener,
  notificationListener,
  requestsListener,
  userListener,
} from "./utils/api/user.api";

function App() {
  const routes = useRoutes(AppRoutes);
  const { user, userInfos } = useAppSelector((s) => s.auth);
  const proprietaire = isOwner(userInfos);
  const admin = isAdmin(userInfos);
  const concessionnaires = isConcessionnaires(userInfos);

  const dispatch = useAppDispatch();
  useEffect(() => {
    authListener((user) => {
      if (!user) {
        dispatch(onLogout());
      }
    });
  }, []);
  useEffect(() => {
    if (user?.uid) {
      const listener = userListener(user, (userInfos) => {
        dispatch(onSetUserInfos(userInfos));
      });
      return () => {
        listener();
      };
    }
  }, [user?.uid]);

  useEffect(() => {
    if (concessionnaires || admin || proprietaire) {
      const listener = VehiculeListener((data, deleteds) => {
        if (data.length > 0) {
          dispatch(onSetVehicules(data));
        }
        if (deleteds.length > 0) {
          setTimeout(() => {
            dispatch(onDeleteVehicules(deleteds));
          }, 400);
        }
      });

      return () => {
        listener();
      };
    }
  }, [concessionnaires, admin, userInfos?.uid, proprietaire]);

  useEffect(() => {
    if (user?.uid) {
      const listener = driverListener((data, deleteds) => {
        if (data.length > 0) {
          dispatch(onUsers(data));
        }
        if (deleteds.length > 0) {
          setTimeout(() => {
            dispatch(onDeleteUsers(deleteds));
          }, 400);
        }
      });
      const listener2 = requestsListener((data, deleteds) => {
        if (data.length > 0) {
          dispatch(onGetRequests(data));
        }
        if (deleteds.length > 0) {
          setTimeout(() => {
            dispatch(onDeleteRequests(deleteds));
          }, 400);
        }
      });
      const listener3 = invoicesListener((data, deleteds) => {
        if (data.length > 0) {
          dispatch(onSetInvoices(data));
        }
        if (deleteds.length > 0) {
          setTimeout(() => {
            dispatch(onDeleteInvoices(deleteds));
          }, 400);
        }
      });
      const listener4 = notificationListener(user.uid, (data) => {
        dispatch(setNotifs(data));
      });
      return () => {
        listener();
        listener2();
        listener3();
        listener4();
        dispatch(setNotifs([]));
      };
    }
  }, [user?.uid]);

  useEffect(() => {
    if (admin) {
      const listener = messageContactListener((data) => {
        dispatch(onMessageContact(data));
      });
      return () => {
        listener();
      };
    }
  }, [admin]);

  useEffect(() => {
    const li = landingPageListener((data) => {
      dispatch(onGetLandingPage(data));
    });
    return () => li();
  }, []);

  useEffect(() => {
    const li = contentListener((data) => {
      dispatch(onGetContent(data));
    });
    return () => li();
  }, []);

  useEffect(() => {
    const li = faqListener((data, dels) => {
      if (data.length > 0) {
        dispatch(onFaq(data));
      }
      if (dels.length > 0) {
        dispatch(onDeleteFaq(dels));
      }
    });
    return () => li();
  }, []);

  return (
    <div className="app-routing-container">
      <ScrollToTop />
      {routes}
    </div>
  );
}

function ScrollToTop() {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname, location.state]);
  return null;
}
export default App;
