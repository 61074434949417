import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";
import { STORAGE_PATHS } from "./api";

const storage = getStorage();

async function upload(file: File, directory: string, path: string) {
  if (directory === path) {
    path = new Date().getTime().toString();
  }
  const parts = file.name.split(".");
  const ext = parts[parts.length - 1];
  const reference = ref(storage, `${directory}/${file.name}_${path}.${ext}`);
  await uploadBytes(reference, file);
  return getDownloadURL(reference);
}

export async function uploadUserAvatarAsync(file: File, uid: string) {
  return upload(file, STORAGE_PATHS.avatars, uid);
}

export async function uploadDriverImagesAsync(files?: File[]) {
  if (!files) return [];
  const promises = files.map((file) =>
    upload(file, STORAGE_PATHS.images, new Date().getTime().toString())
  );
  return Promise.all(promises);
}

export async function uploadImagesAsync(file: File) {
  return upload(file, STORAGE_PATHS.images, new Date().getTime().toString());
}

export async function uploadInvoiceAsync(file: File) {
  return upload(file, STORAGE_PATHS.invoices, new Date().getTime().toString());
}
