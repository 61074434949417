import { BiArrowBack } from "react-icons/bi";
import { useNavigate } from "react-router-dom";

import moment from "moment";
import { useCallback, useState } from "react";
import { Driver } from "../../../../types";
import { useLocationOrRedirect } from "../../../utils/api/hooks";
import { getImage } from "../../../utils/data";
import {
  InfosRow,
  MessageUrgence,
} from "../../Concessionnaires/Vehicules/DetailVehicule";
import Map from "../../Concessionnaires/Vehicules/Map";

const tabs = [
  "Informations générales",
  "Véhicule",
  "Message d'urgence",
  "Localisation",
];

function DetailChauffeur() {
  const navigate = useNavigate();
  const [index, setIndex] = useState(0);
  const item = useLocationOrRedirect<Driver>();
  const go = useCallback((index: number) => {
    setIndex(index);
  }, []);

  if (!item) return null;

  return (
    <div className="dashbord-visiteur-component">
      <div className="terranga-auto-dash-admin-titre-btn-container mb-4">
        <div className="workcare-dash-admin-titre-container">
          <div
            onClick={() => navigate(-1)}
            className="terranga-auto-dash-admin-titre"
          >
            <BiArrowBack />
            <span className="ps-2">Retour</span>
          </div>
        </div>
      </div>
      <div className="workcare-dash-admin-page-content-container mb-3">
        <ul className="tabs-container mt-4">
          {tabs.map((tab, key) => (
            <li
              className={`tab-item  ${index === key && "tab-active-item"}`}
              key={key}
              style={{ fontSize: 13 }}
              onClick={() => go(key)}
            >
              {tab}
            </li>
          ))}
        </ul>
        {index === 1 && (
          <>
            {!item.vehicule && (
              <div className="alert alert-danger">Pas de véhicule</div>
            )}
            {item.vehicule && (
              <>
                {!!item.vehicule.image && (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <img
                      src={getImage(item.vehicule)}
                      style={{
                        height: 250,
                        width: "auto",
                        marginBottom: 10,
                        borderRadius: 10,
                      }}
                    />
                  </div>
                )}
                <section className="mb-3">
                  <div className="row">
                    <InfosRow
                      title={"Matricule"}
                      value={item.vehicule.matricule}
                    />
                    <InfosRow title={"Année"} value={item.vehicule.annee} />
                    <InfosRow title={"Modèle"} value={item.vehicule.model} />
                    <InfosRow
                      title={"Type de vitesse"}
                      value={item.vehicule.mode}
                    />
                    <InfosRow
                      title={"Prochaine visite"}
                      value={moment(item.vehicule.dateVisite).format(
                        "DD/MM/YYYY"
                      )}
                    />
                  </div>
                </section>
              </>
            )}
          </>
        )}
        {index === 0 && (
          <>
            <div className="row">
              <InfosRow title={"Prénom"} value={item.prenom} />
              <InfosRow title={"Nom"} value={item.nom} />
              <InfosRow title={"Téléphone"} value={item.telephone} />
              <InfosRow title={"Email"} value={item.email} />
              <InfosRow title={"Adresse"} value={item.address} />
            </div>
          </>
        )}
        {index === 2 && (
          <>
            <MessageUrgence client={item} />
          </>
        )}
        {index === 3 && (
          <>
            <Map driver={item} />
          </>
        )}
      </div>
    </div>
  );
}

export default DetailChauffeur;
