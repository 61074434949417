import { useCallback, useState } from "react";
import { BiArrowBack } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import { UserInfos } from "../../../../types";
import {
  useLocationOrRedirect,
  useVehiculesProprietaires,
} from "../../../utils/api/hooks";
import { getAvatar } from "../../../utils/data";
import { InfosRow } from "../../Concessionnaires/Vehicules/DetailVehicule";
import VehiculeProprietaireTable from "../Chauffeurs/Tables/VehiculeProprietaireTable";
import { DashboardProprietairesComponent } from "../DashboardAdmin/DashboardProprietaire";
import PriseEnChargeProprietaireTable from "../PriseEnChargeAdmin/Tables/PriseEnChargeProprietaireTable";
import "./ProprietairesAdmin.css";

const tabs = ["KPI", "Infos personnelles", "Prise en charges", "Véhicules"];

function DetailsProprietairesAdmin() {
  const navigate = useNavigate();

  const item = useLocationOrRedirect<UserInfos>();

  const [index, setIndex] = useState(0);

  const userId = item?.ownerId || item?.uid;

  const vehicules = useVehiculesProprietaires(userId);

  const go = useCallback((index: number) => {
    setIndex(index);
  }, []);
  if (!item) return null;
  return (
    <div className="dashbord-visiteur-component">
      <div className="terranga-auto-dash-admin-titre-btn-container mb-4">
        <div className="workcare-dash-admin-titre-container">
          <div
            onClick={() => navigate(-1)}
            className="terranga-auto-dash-admin-titre"
          >
            <BiArrowBack />
            <span className="ps-2">Retour</span>
          </div>
        </div>
      </div>
      <div className="workcare-dash-admin-page-content-container mb-3">
        <ul className="tabs-container mt-4">
          {tabs.map((tab, key) => (
            <li
              className={`tab-item  ${index === key && "tab-active-item"}`}
              key={key}
              style={{ fontSize: 13 }}
              onClick={() => go(key)}
            >
              {tab}
            </li>
          ))}
        </ul>
        {index === 0 && (
          <DashboardProprietairesComponent bg="#EEE" userId={userId} />
        )}
        {index === 1 && (
          <>
            <div className="content-pp-profil-visiteur text-center mb-3">
              <div className="d-flex justify-content-center align-items-center mb-4 leader-auto-user-avatar-container">
                <label htmlFor="input-test" className="input-testt">
                  <img
                    src={getAvatar(item)}
                    alt="profil utilisateur"
                    className="pp-profil-user-visiteur"
                  />
                </label>
              </div>
              <div className="content-text-name-user">
                <h2 className="custom-name-user-admin">
                  {item.prenom} {item.nom}
                </h2>
              </div>
            </div>

            <div className="kanimmo-dash-locataire-param-main-page-container py-4 mb-3">
              <section className="mb-3">
                <div className="terranga-dash-admin-param-title mb-5 mt-4">
                  Informations générales
                </div>
                <form>
                  <div className="row">
                    <InfosRow title="Prénom" value={item.prenom} />
                    <InfosRow title="Nom" value={item.nom} />
                    <InfosRow title="Adresse" value={item.address} />
                    <InfosRow title="Email" value={item.email} />
                    <InfosRow title="Téléphone" value={item.telephone} />
                    <InfosRow title="Raison sociale" value={item.nomSociete} />
                  </div>
                </form>
              </section>
            </div>
          </>
        )}

        {index === 2 && (
          <PriseEnChargeProprietaireTable userId={userId} search="" />
        )}

        {index === 3 && (
          <VehiculeProprietaireTable admin vehicules={vehicules} />
        )}
      </div>
    </div>
  );
}

export default DetailsProprietairesAdmin;
