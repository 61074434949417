/* eslint-disable @typescript-eslint/no-use-before-define */
import { Modal } from "bootstrap";
import $ from "jquery";
import { useEffect, useState } from "react";
import {
  CreateRequestFormData,
  Driver,
  ImportPriseEnChargeFormData,
  Request,
  RequestAdmin,
  RequestOwner,
  Transmission,
  UserInfos,
  Vehicule,
  VehiculeFormData,
} from "../../types";

import { PhoneNumberFormat, PhoneNumberUtil } from "google-libphonenumber";
import moment from "moment";
import Swal from "sweetalert2";
import * as xlsx from "xlsx";
import { useAppSelector } from "../redux/root";
import { isDriver, useCamions, useVehiculesClients } from "./api/hooks";
import {
  addImportedVehicule,
  createRequests,
  vehiculeByMatricule,
} from "./api/user.api";
import { formatAmout } from "./formatter";
import { getLocationFromAddress } from "./location";
const phoneUtil = PhoneNumberUtil.getInstance();

export const getFormattedPhoneNumber = (phone: string) => {
  const number = phoneUtil.parse(phone, "SN");
  if (phoneUtil.isPossibleNumber(number) && phoneUtil.isValidNumber(number)) {
    return {
      national: number.getNationalNumber().toString(),
      international: phoneUtil.format(number, PhoneNumberFormat.INTERNATIONAL),
    };
  }
  return null;
};

export const isPhoneValidPhoneNumber = (phone?: string) => {
  if (!phone) return true;
  try {
    const number = phoneUtil.parse(phone, "SN");
    if (phoneUtil.isPossibleNumber(number) && phoneUtil.isValidNumber(number)) {
      return true;
    }
  } catch (ex) {
    console.log("error phone", ex);
  }
  return false;
};

export function closeModal(modalId: string) {
  // @ts-ignore
  $(`#${modalId}`).modal("hide");
}

export function useModalActions(modalId: string) {
  const [modal, setModal] = useState<{ show?: () => void; hide?: () => void }>(
    {}
  );
  useEffect(() => {
    const modalRef = document.getElementById(modalId);
    if (modalRef) {
      var myModal = new Modal(modalRef, {});
      if (document.readyState === "complete") {
        setModal(myModal);
      }
      document.onreadystatechange = function () {
        setModal(myModal);
      };
      $(`#${modalId}`).on("shown.bs.modal", function () {
        $(".modal-backdrop").css({ display: "block" });
      });
    }
  }, []);

  const show = () => {
    $(".modal-backdrop").css({ display: "block" });
    if (modal?.show) {
      modal.show();
    }
  };
  const hide = () => {
    if (modal?.hide) modal.hide();
    setTimeout(() => {
      $(".modal-backdrop").css({ display: "none" });
      $("body").css({ overflow: "scroll" });
    }, 500);
  };

  return { show, hide };
}

export function getAvatar(user?: UserInfos | Driver) {
  if (user?.avatar) return user.avatar;
  return `https://ui-avatars.com/api/?name=${user?.prenom} ${user?.nom}`;
}

export function getImage(item: Vehicule) {
  if (item.image) return item.image;
  return `https://ui-avatars.com/api/?name=${item.matricule} ${item.model}`;
}

export const SWAL_DEFAULT_PROPS = {
  confirmButtonColor: "#F2994A",
  iconColor: "#F2994A",
  cancelButtonColor: "#DB3250",
};

export function getRequestAmount(item: Request, isPro = false) {
  if (item.user) {
    const finded = item.users.find((u) => u.user === item.user);
    if (finded) {
      if (isPro) {
        return finded.pro_price;
      } else {
        return parseFloat(finded.amount);
      }
    }
  }

  return 0;
}

export function getAmountFormRequest(
  item: Request | RequestAdmin | RequestOwner,
  isPro = false
) {
  if ("userData" in item && item.userData) {
    if (isPro) {
      return formatAmout(item.userData.pro_price);
    }
    return formatAmout(item.userData.amount);
  }
  if (item.user) {
    const uid = typeof item.user === "string" ? item.user : item.user.uid;
    // @ts-ignore
    const finded = item.users.find((u) => u.user === uid);
    if (finded) {
      if (isPro) {
        return formatAmout(finded.pro_price);
      }
      return formatAmout(finded.amount);
    }
  }

  return "-";
}

type ImportedVehiculeData = {
  Matricule: string;
  Année: number | string;
  Modèle: string;
  "Type de vitesse": Transmission;
  "Date de la prochaine visite": number;
};

export function xlsxToJson(file: File): Promise<VehiculeFormData[] | null> {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.onload = (e: any) => {
      const data = e.target.result;
      const workbook = xlsx.read(data, { type: "array" });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const json: ImportedVehiculeData[] = xlsx.utils.sheet_to_json(worksheet, {
        raw: false,
      });
      const newData: VehiculeFormData[] = [];
      for (let row of json) {
        const newItem: VehiculeFormData = {};
        for (let key in row) {
          if (
            typeof row[key] === "number" &&
            !isNaN(row[key]) &&
            key === "Date de la prochaine visite"
          ) {
            newItem["dateVisite"] = moment(
              new Date((row[key] - (25567 + 2)) * 86400 * 1000)
            ).format("YYYY-MM-DD");
          } else {
            if (key === "Matricule") newItem["matricule"] = row[key];
            if (key === "Année") newItem["annee"] = row[key] as any;
            if (key === "Type de vitesse") newItem["mode"] = row[key];
            if (key === "Modèle") newItem["model"] = row[key];
            if (key === "Date de la prochaine visite")
              newItem["dateVisite"] = row[key] as any;
          }
        }
        const inputDate = newItem.dateVisite;

        const parsedDate = moment(inputDate, ["DD/MM/YYYY", "YYYY-MM-DD"]);

        if (parsedDate.isValid()) {
          newItem.dateVisite = parsedDate.format("YYYY-MM-DD");
        }
        newData.push(newItem);
      }
      resolve(newData);
    };
    reader.onerror = () => {
      resolve(null);
    };
    reader.readAsArrayBuffer(file);
  });
}

const showError = (
  index: number,
  error: string,
  resolve: (v: boolean) => void
) => {
  Swal.fire({
    icon: "error",
    title: `Ligne ${index}: ${error}`,
    showConfirmButton: false,
    timer: 5000,
    ...SWAL_DEFAULT_PROPS,
  });
  resolve(false);
};

export function validateExcelFile(data: VehiculeFormData[]) {
  return new Promise(async (resolve) => {
    let index = 1;
    for (let item of data) {
      if (!item.matricule) {
        return showError(index, "Le matricule est obligatoire", resolve);
      }
      if (!item.annee) {
        return showError(index, "L'année est obligatoire", resolve);
      }
      if (!item.mode) {
        return showError(index, "Le type de vitesse  est obligatoire", resolve);
      }
      if (!["Manuel", "Automatique"].includes(item.mode)) {
        return showError(
          index,
          `Les types de vitesses possibles sont (Manuel, Automatique)`,
          resolve
        );
      }
      if (!item.dateVisite) {
        return showError(
          index,
          "La date de la prochaine visite  est obligatoire",
          resolve
        );
      }
      const parsedDate = moment(item.dateVisite, ["DD/MM/YYYY", "YYYY-MM-DD"]);

      if (!parsedDate.isValid()) {
        return showError(
          index,
          "La date de la prochaine visite  n'est pas valide format accepté:(DD/MM/YYYY)",
          resolve
        );
      }
      if (!item.model) {
        return showError(index, "La modèle  est obligatoire", resolve);
      }
      index++;
    }
    resolve(true);
  });
}

const wait = () => new Promise((r) => setTimeout(r, 2500));

export function useExecuteImportVehicules() {
  let [message, setMessage] = useState("");
  let [count, setCount] = useState(0);

  const exec = async (data: VehiculeFormData[], userId?: string) => {
    for (let item of data) {
      setMessage(`Validation de ${item.matricule}...`);
      const doc = await vehiculeByMatricule(
        item.matricule?.toLowerCase() ?? ""
      );
      await wait();
      if (doc) {
        setMessage(`${item.matricule}: Existe déjà et sera ignoré.`);
      } else {
        setMessage(`Ajout de ${item.matricule}...`);
        await wait();
        if (userId) {
          item.concessionnaireId = userId;
        }
        await addImportedVehicule(item);
        setCount((old) => old + 1);
        setMessage(`${item.matricule} Ajouté avec succès`);
      }
      await wait();
    }
    setCount((old) => {
      setMessage(`${old} véhicules ajoutés avec succès`);
      return old;
    });
    await wait();
    setCount(0);
    setMessage("");
  };

  return {
    exec,
    message,
  };
}

export function useExecuteImportPriseEnCharge() {
  let [message, setMessage] = useState("");
  const camions = useCamions();
  const vehicules = useVehiculesClients();
  const users = useAppSelector((s) => s.users);

  const exec = async (data: ImportPriseEnChargeFormData[]) => {
    setMessage(`Validation en cours...`);
    let index = 1;
    const finalData: CreateRequestFormData[] = [];
    for (let item of data) {
      const user = users.find(
        (d) =>
          d.telephone ===
            getFormattedPhoneNumber(String(item.user))?.international &&
          isDriver(d)
      );
      if (!user) {
        return showError(index, `Chauffeur non trouvé "${item.user}"`, () => {
          setMessage(``);
        });
      }
      const camion = camions.find(
        (c) => c.matricule_lower === item.camion?.toLowerCase()
      );
      if (!camion) {
        return showError(index, `Camion non trouvé "${item.camion}"`, () => {
          setMessage(``);
        });
      }
      const from = await getLocationFromAddress(String(item.from));
      if (!from) {
        return showError(index, `Address non touvé "${item.from}"`, () => {
          setMessage(``);
        });
      }

      const to = await getLocationFromAddress(String(item.to));
      if (!to) {
        return showError(index, `Address non touvé "${item.to}"`, () => {
          setMessage(``);
        });
      }

      const vehicule = vehicules.find(
        (c) => c.matricule_lower === item.identifier?.toLowerCase()
      );
      const sendData: CreateRequestFormData = {
        date: item.date,
        image: `https://ui-avatars.com/api/?name=${item.identifier}`,
        user: user.uid,
        camionId: camion.uid,
        location: {
          from: from,
          to: to,
        },
        mode: item.mode,
        collected: false,
        ended: false,
        fullname: item.fullname,
        telephone: item.telephone,
        identifier: item.identifier,
      };
      if (vehicule) {
        let client: Driver | null | undefined = vehicule.client;
        sendData.image = getImage(vehicule);
        sendData.vehiculeId = vehicule.uid;
        sendData.concessionnaireId = vehicule.concessionnaireId;
        if (client) {
          sendData.token = client?.token;
          sendData.anonymousId = client?.uid;
        }
      }
      finalData.push(sendData);

      index++;
    }
    setMessage(`Importation en cours...`);
    try {
      console.log(finalData);
      await createRequests(finalData);
    } catch (ex) {
      console.log("error import", ex);
    }
    setMessage(`Prise en charges importées avec succès`);
    await wait();
    setMessage("");
  };

  return {
    exec,
    message,
  };
}

type ImportedPriseEnChargeData = {
  Date: number;
  Chauffeur: string;
  De: string;
  A: string;
  Matricule: string;
  "Prénom & Nom": string;
  Téléphone: string;
  "Type de vitesse": Transmission;
  "Matricule Camion": string;
};

export function xlsxToPriseEnChargeJson(
  file: File
): Promise<ImportPriseEnChargeFormData[] | null> {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.onload = (e: any) => {
      const data = e.target.result;
      const workbook = xlsx.read(data, { type: "array" });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const json: ImportedPriseEnChargeData[] = xlsx.utils.sheet_to_json(
        worksheet,
        {
          raw: false,
        }
      );
      const newData: ImportPriseEnChargeFormData[] = [];
      for (let row of json) {
        const newItem: ImportPriseEnChargeFormData = {};
        let date: any = undefined;
        for (let key in row) {
          if (
            typeof row[key] === "number" &&
            !isNaN(row[key]) &&
            key === "Date"
          ) {
            date = moment(
              new Date((row[key] - (25567 + 2)) * 86400 * 1000)
            ).format("YYYY-MM-DD");
          } else {
            if (key === "Date") date = row[key];
            if (key === "Chauffeur") newItem["user"] = row[key];
            if (key === "De") newItem["from"] = row[key];
            if (key === "A") newItem["to"] = row[key];
            if (key === "Matricule") newItem["identifier"] = row[key];
            if (key === "Matricule Camion") newItem["camion"] = row[key];
            if (key === "Prénom & Nom") newItem["fullname"] = row[key];
            if (key === "Téléphone") newItem["telephone"] = row[key];
            if (key === "Type de vitesse") newItem["mode"] = row[key];
          }
        }
        const parsedDate = moment(date, ["DD/MM/YYYY", "YYYY-MM-DD"]);
        if (parsedDate.isValid()) {
          newItem.date = parsedDate.toDate().getTime();
        }
        newData.push(newItem);
      }
      resolve(newData);
    };
    reader.onerror = () => {
      resolve(null);
    };
    reader.readAsArrayBuffer(file);
  });
}

export function validatePriseEnChargeFile(data: ImportPriseEnChargeFormData[]) {
  return new Promise(async (resolve) => {
    let index = 1;
    for (let item of data) {
      if (!item.date) {
        return showError(index, "La date est obligatoire", resolve);
      }
      if (!item.user) {
        return showError(index, "Le chauffeur est obligatoire", resolve);
      }
      if (
        !isPhoneValidPhoneNumber(item.user) ||
        !isPhoneValidPhoneNumber(item.telephone)
      ) {
        return showError(index, "Numéro téléphone invalid", resolve);
      }
      if (!item.mode) {
        return showError(index, "Le type de vitesse  est obligatoire", resolve);
      }
      if (!["Manuel", "Automatique"].includes(item.mode)) {
        return showError(
          index,
          `Les types de vitesses possibles sont (Manuel, Automatique)`,
          resolve
        );
      }
      if (!item.identifier) {
        return showError(index, "La matricule est obligatoire", resolve);
      }

      if (!item.camion) {
        return showError(
          index,
          "La matricule du camion est obligatoire",
          resolve
        );
      }

      if (!item.from) {
        return showError(index, "Le lieu de départ est obligatoire", resolve);
      }

      if (!item.to) {
        return showError(
          index,
          "Le lieu de destination est obligatoire",
          resolve
        );
      }

      if (!item.fullname) {
        return showError(index, "Le prénom et le nom est obligatoire", resolve);
      }

      if (!item.telephone) {
        return showError(index, "Le N° téléphone est obligatoire", resolve);
      }
      index++;
    }
    resolve(true);
  });
}
