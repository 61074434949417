import { BiArrowBack } from "react-icons/bi";
import { useNavigate } from "react-router-dom";

import moment from "moment";
import { ReactNode, useCallback, useState } from "react";
import { Driver, Vehicule } from "../../../../types";
import { useUser } from "../../../redux/root";
import {
  useLocationOrRedirect,
  useSendMessageUrgence,
} from "../../../utils/api/hooks";
import { getImage } from "../../../utils/data";
import { SubmitButton } from "../../auth/Login";
import Map from "./Map";
export function InfosRow({
  title,
  value,
}: {
  title: string;
  value?: string | number | ReactNode;
}) {
  return (
    <div className="col-md-6">
      <div className="mb-3">
        <div className="terranga-auto-custom-form-label">{title}</div>
        <div className="form-control-modal-custom custom-form-control-modal-custom">
          {value}
        </div>
      </div>
    </div>
  );
}

const tabs = [
  "Informations générales",
  "Client",
  "Message d'urgence",
  "Localisation",
];

function DetailVehicule() {
  const navigate = useNavigate();
  const [index, setIndex] = useState(0);
  const item = useLocationOrRedirect<Vehicule>();
  const go = useCallback((index: number) => {
    setIndex(index);
  }, []);

  if (!item) return null;

  return (
    <div className="dashbord-visiteur-component">
      <div className="terranga-auto-dash-admin-titre-btn-container mb-4">
        <div className="workcare-dash-admin-titre-container">
          <div
            onClick={() => navigate(-1)}
            className="terranga-auto-dash-admin-titre"
          >
            <BiArrowBack />
            <span className="ps-2">Retour</span>
          </div>
        </div>
      </div>
      <div className="workcare-dash-admin-page-content-container mb-3">
        <ul className="tabs-container mt-4">
          {tabs.map((tab, key) => (
            <li
              className={`tab-item  ${index === key && "tab-active-item"}`}
              key={key}
              style={{ fontSize: 13 }}
              onClick={() => go(key)}
            >
              {tab}
            </li>
          ))}
        </ul>
        {index === 0 && (
          <>
            {!!item.image && (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img
                  src={getImage(item)}
                  style={{
                    height: 250,
                    width: "auto",
                    marginBottom: 10,
                    borderRadius: 10,
                  }}
                />
              </div>
            )}
            <section className="mb-3">
              <div className="row">
                <InfosRow title={"Matricule"} value={item.matricule} />
                <InfosRow title={"Année"} value={item.annee} />
                <InfosRow title={"Modèle"} value={item.model} />
                <InfosRow title={"Type de vitesse"} value={item.mode} />
                <InfosRow
                  title={"Prochaine visite"}
                  value={moment(item.dateVisite).format("DD/MM/YYYY")}
                />
              </div>
            </section>
          </>
        )}
        {index === 1 && (
          <>
            {!item.clientId && (
              <div className="alert alert-danger">Pas de client</div>
            )}
            {!!item.clientId && (
              <>
                <div className="row">
                  <InfosRow title={"Prénom"} value={item.client?.prenom} />
                  <InfosRow title={"Nom"} value={item.client?.nom} />
                  <InfosRow
                    title={"Téléphone"}
                    value={item.client?.telephone}
                  />
                  <InfosRow title={"Email"} value={item.client?.email} />
                  <InfosRow
                    title={"Lieu utilisation"}
                    value={item.client?.address}
                  />
                </div>
              </>
            )}
          </>
        )}
        {index === 2 && (
          <>
            {!!item.client && <MessageUrgence client={item.client} />}
            {!item.clientId && (
              <div className="alert alert-danger">Pas de client</div>
            )}
          </>
        )}
        {index === 3 && (
          <>
            {!!item.client && <Map driver={item.client} />}
            {!item.clientId && (
              <div className="alert alert-danger">Pas de client</div>
            )}
          </>
        )}
      </div>
    </div>
  );
}

export default DetailVehicule;

export function MessageUrgence({ client }: { client: Driver }) {
  const user = useUser();
  const [message, setMessage] = useState("");
  const { loading, onAdd } = useSendMessageUrgence(() => {
    setMessage("");
  });
  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        if (message.trim().length > 0) {
          onAdd({ from: user.uid, to: client.uid, message });
        }
      }}
    >
      <div className="row">
        <div className="col-md-8 mb-3">
          <textarea
            placeholder={`Envoyer un message à ${client.prenom} ${client.nom}`}
            value={message}
            rows={6}
            className="form-control"
            onChange={(e) => setMessage(e.target.value)}
          />
        </div>
        <div className="col-md-12"></div>
        <div className="col-md-4">
          <SubmitButton title="Envoyer" loading={loading} />
        </div>
      </div>
    </form>
  );
}
