import { useAppSelector } from "../../redux/root";
import FrontFooter from "./FrontFooter/FrontFooter";
import FrontHeader from "./FrontHeader/FrontHeader";
import "./LandingPage.css";
const Privacy = () => {
  const content = useAppSelector((s) => s.content);

  return (
    <div className="landing-page-component">
      <FrontHeader />
      <div className="page-content py-5">
        <div className="section-service-app">
          <div className="container-app-landing-page">
            <h1 className="my-3">Politique de confidentialité</h1>
            <p dangerouslySetInnerHTML={{ __html: content.cgu }} />
          </div>
        </div>
      </div>

      <FrontFooter />
    </div>
  );
};

export default Privacy;
