import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { OwnerDashboardData } from "../../../types";



const initialState: OwnerDashboardData = {
  drivers: 0,
  requests: 0
}

export const OwnerDashboardSLice = createSlice({
  name: "owner_dashbaord",
  initialState,
  reducers: {
    onGetDashboardOwner: (state, action: PayloadAction<OwnerDashboardData>) => {
      return action.payload
    },
  },
});

export const { onGetDashboardOwner } = OwnerDashboardSLice.actions;

export default OwnerDashboardSLice.reducer;
