import "intl";
import "intl/locale-data/jsonp/fr";

export function formatAmout(amount: string | number, replace = "F CFA") {
  if (amount || amount === 0) {
    return new Intl.NumberFormat("fr-FR", {
      style: "currency",
      currency: "CFA",
      maximumFractionDigits: 2,
      minimumFractionDigits: 0,
    })
      .format(parseFloat(String(amount)))
      .replace("CFA", replace);
  }

  return "-";
}
