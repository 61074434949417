import { useEffect, useState } from "react";
import { AiOutlinePlusSquare } from "react-icons/ai";
import { Driver } from "../../../../types";
import { useDriversAndVehicules } from "../../../utils/api/hooks";
import "./ChauffeursAdmin.css";
import AjouterChauffeurModal from "./Modales/AjouterChauffeurModal";
import ChauffeursAdminTable from "./Tables/ChauffeursAdminTable";
function ChauffeursAdmin() {
  const users = useDriversAndVehicules();
  const [drivers, setDrivers] = useState<Driver[]>([]);
  const [search, setSearch] = useState("");
  const modalId = "AjouterChauffeurModal";
  useEffect(() => {
    const s = search.trim().toLowerCase();
    if (s) {
      setDrivers(
        users.filter((u) =>
          `${u.prenom} ${u.nom} ${u.vehicule?.matricule} ${u.vehicule?.model}`
            .toLowerCase()
            .includes(s)
        )
      );
    } else {
      setDrivers(users);
    }
  }, [search, users]);
  return (
    <div className="dashbord-visiteur-component">
      <div className="terranga-auto-dash-admin-titre-btn-container mb-4">
        <div className="workcare-dash-admin-titre-container">
          <h1 className="terranga-auto-dash-admin-titre">Chauffeurs</h1>
        </div>
        <button
          className="terranga-auto-dash-admin-btn-ajout-deux"
          data-bs-toggle="modal"
          data-bs-target={`#${modalId}`}
        >
          <AiOutlinePlusSquare className="terranga-auto-dash-admin-btn-ajout-icone" />{" "}
          Ajouter un chauffeur
        </button>
        <div
          className="modal fade"
          id={modalId}
          aria-labelledby="AjouterChauffeurModalLabel"
          aria-hidden="true"
        >
          <AjouterChauffeurModal modalId={modalId} />
        </div>
      </div>
      <div className="workcare-dash-admin-page-content-container">
        <div className="locataire-requete-filtre-container mb-4">
          <form className="row g-3 locataire-requete-filtre-form">
            <div className="col-auto">
              <label
                htmlFor="filtreLogement"
                className="locataire-requete-filtre-label"
              >
                Filtrer
              </label>
            </div>
            <div className="col-auto">
              <input
                type="text"
                className="form-control locataire-requete-filtre-input"
                id="filtreLogement"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                placeholder="Entrer un nom"
              />
            </div>
          </form>
        </div>
        <ChauffeursAdminTable admin drivers={drivers} />
      </div>
    </div>
  );
}

export default ChauffeursAdmin;
