import React from "react";
import { AiOutlineMenuUnfold } from "react-icons/ai";
import { BiCategory } from "react-icons/bi";
import { FaUser } from "react-icons/fa";
import { IoLogOutOutline } from "react-icons/io5";
import { RiSettings3Fill } from "react-icons/ri";
import { NavLink, useLocation } from "react-router-dom";
import VersionChecker from "../../../../versionChecker/VersionChecker";

const SidebarMobile = () => {
  const location = useLocation()
  return (
    <div>
      <div className="container-nav">
        <input type="checkbox" id="check" />
        <label htmlFor="check">
          <AiOutlineMenuUnfold />
          <i className="fas fa-times" id="cancel"></i>
        </label>
        <div className="sidebar" id="scrollbar-nav-mobile">
          <div className="content-nav-mobile">
          <div className="container-user-connect-info pb-4 d-flex justify-content-center">
            <div className="container-info-user-connect-navmenu ps-2">
              <p className="app-title-connect-navmenu mb-1">
                TERANGA{" "}
                <span className="app-title-connect-navmenu-bold">
                  AUTOMOBILE
                </span>
              </p>
            </div>
          </div>
            <ul className="nav nav-items-custom flex-column">
            {location?.pathname?.startsWith("/admin") && (
              <>
            <li className="nav-item nav-item-vertical-custom">
                <NavLink
                  className="nav-link nav-link-vertival-custom"
                  to="/admin/dashboard"
                >
                  <BiCategory />
                  <span className="item-nav-menu drop-header">Dashboard</span>
                </NavLink>
              </li>
            <li className="nav-item nav-item-vertical-custom">
                <NavLink
                  className="nav-link nav-link-vertival-custom"
                  to="/admin/clients"
                >
                  <FaUser />
                  <span className="item-nav-menu drop-header">Clients</span>
                </NavLink>
              </li>
            <li className="nav-item nav-item-vertical-custom">
                <NavLink
                  className="nav-link nav-link-vertival-custom"
                  to="/admin/chauffeurs"
                >
                  <FaUser />
                  <span className="item-nav-menu drop-header">Chauffeurs</span>
                </NavLink>
              </li>
            <li className="nav-item nav-item-vertical-custom">
                <NavLink
                  className="nav-link nav-link-vertival-custom"
                  to="/admin/proprietaires"
                >
                  <FaUser />
                  <span className="item-nav-menu drop-header">Proprietaires</span>
                </NavLink>
              </li>
            <li className="nav-item nav-item-vertical-custom">
                <NavLink
                  className="nav-link nav-link-vertival-custom"
                  to="/admin/prise-en-charge"
                >
                  <RiSettings3Fill />
                  <span className="item-nav-menu drop-header">Prise en charge</span>
                </NavLink>
              </li>
            <li className="nav-item nav-item-vertical-custom">
                <NavLink
                  className="nav-link nav-link-vertival-custom"
                  to="/admin/parametres"
                >
                  <RiSettings3Fill />
                  <span className="item-nav-menu drop-header">Paramètres</span>
                </NavLink>
              </li>
            <li className="nav-item nav-item-vertical-custom">
                <NavLink
                  className="nav-link nav-link-vertival-custom"
                  to="/admin/profile"
                >
                  <RiSettings3Fill />
                  <span className="item-nav-menu drop-header">Profil</span>
                </NavLink>
              </li>
              </>
            )}
            {location?.pathname?.startsWith("/proprietaire") && (
              <>
                <li className="nav-item nav-item-vertical-custom">
                <NavLink
                  className="nav-link nav-link-vertival-custom"
                  to="/proprietaire/dashboard"
                >
                  <BiCategory />
                  <span className="item-nav-menu drop-header">Dashboard</span>
                </NavLink>
              </li>
                <li className="nav-item nav-item-vertical-custom">
                <NavLink
                  className="nav-link nav-link-vertival-custom"
                  to="/proprietaire/chauffeurs"
                >
                  <FaUser />
                  <span className="item-nav-menu drop-header">Chauffeurs</span>
                </NavLink>
              </li>
                <li className="nav-item nav-item-vertical-custom">
                <NavLink
                  className="nav-link nav-link-vertival-custom"
                  to="/proprietaire/prise-en-charges"
                >
                  <RiSettings3Fill />
                  <span className="item-nav-menu drop-header">Prise en charge</span>
                </NavLink>
              </li>
                <li className="nav-item nav-item-vertical-custom">
                <NavLink
                  className="nav-link nav-link-vertival-custom"
                  to="/proprietaire/parametres"
                >
                  <RiSettings3Fill />
                  <span className="item-nav-menu drop-header">Paramètres</span>
                </NavLink>
              </li>
                <li className="nav-item nav-item-vertical-custom">
                <NavLink
                  className="nav-link nav-link-vertival-custom"
                  to="/proprietaire/profile"
                >
                  <RiSettings3Fill />
                  <span className="item-nav-menu drop-header">Profile</span>
                </NavLink>
              </li>
              </>
            )}
            </ul>
            <div className="container-btn-logout">
            <button className="btn btn-logout" >
              <IoLogOutOutline className="btn-logout-icone" />
              <span className="ps-2">Déconnexion</span>
            </button>
            <div className="version-view-container ps-3">
              {"v" + VersionChecker()}
            </div>
          </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SidebarMobile;
