import { useCallback, useState } from "react";
import { useUser } from "../../../redux/root";
import PendingPriseEnChargeProprietaireTable from "../../admin/PriseEnChargeAdmin/Tables/PendingPriseEnChargeProprietaireTable";
import PriseEnChargeProprietaireTable from "../../admin/PriseEnChargeAdmin/Tables/PriseEnChargeProprietaireTable";

const tabs = ["Prise en charges", "Demande de prise en charges"];

function Priseenchargeproprietaires() {
  const [index, setIndex] = useState(0);
  const go = useCallback((index: number) => {
    setIndex(index);
  }, []);

  const [search, setSearch] = useState("");

  const user = useUser();
  const userId = user.ownerId || user.uid;

  return (
    <div className="dashbord-visiteur-component">
      <div className="workcare-dash-admin-page-content-container">
        <ul className="tabs-container mt-4">
          {tabs.map((tab, key) => (
            <li
              className={`tab-item  ${index === key && "tab-active-item"}`}
              key={key}
              style={{ fontSize: 13 }}
              onClick={() => go(key)}
            >
              {tab}
            </li>
          ))}
        </ul>

        {index === 0 && (
          <>
            <div className="locataire-requete-filtre-container mb-4">
              <form className="row g-3 locataire-requete-filtre-form">
                <div className="col-auto">
                  <label
                    htmlFor="filtreLogement"
                    className="locataire-requete-filtre-label"
                  >
                    Filtrer
                  </label>
                </div>
                <div className="col-auto">
                  <input
                    type="text"
                    className="form-control locataire-requete-filtre-input"
                    id="filtreLogement"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    placeholder="Rechercher..."
                  />
                </div>
              </form>
            </div>
            <PriseEnChargeProprietaireTable userId={userId} search={search} />
          </>
        )}
        {index === 1 && (
          <>
            <div className="locataire-requete-filtre-container mb-4"></div>
            <PendingPriseEnChargeProprietaireTable userId={userId} />
          </>
        )}
      </div>
    </div>
  );
}

export default Priseenchargeproprietaires;
