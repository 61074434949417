import { UserFormData, UserInfos } from "../../../../../types";
import { useCreateAdmin } from "../../../../utils/api/hooks";
import { useModalActions } from "../../../../utils/data";
import { useFormData } from "../../../../utils/form";
import {
  BtnAnnulerModal,
  BtnCloseModal,
  BtnSubmit,
} from "../../../common/Button";
import { FormError, Input } from "../../../common/Input";

type AjouterAdministrateurModalProps = {
  modalId?: string;
  item?: UserInfos;
};
function AjouterAdministrateurModal({
  modalId,
  item,
}: AjouterAdministrateurModalProps) {
  const ma = useModalActions(modalId ?? "");

  const { errors, onChange, onSubmitForm, register } =
    useFormData<UserFormData>(
      {
        email: item?.email ?? "",
        prenom: item?.prenom ?? "",
        nom: item?.nom ?? "",
        telephone: item?.telephone ?? "",
      },
      (Yup) => ({
        email: Yup.string().email().required().label("Email"),
        prenom: Yup.string().required().label("Prénom"),
        nom: Yup.string().required().label("Nom"),
        telephone: Yup.string().required().label("Téléphone"),
      })
    );

  const { loading, onAddAdmin } = useCreateAdmin(() => {
    ma.hide();
    if (!item) {
      setTimeout(() => {
        onChange({
          email: "",
          prenom: "",
          nom: "",
          telephone: "",
        });
      }, 1500);
    }
  }, item);
  return (
    <div className="modal-dialog modal-lg">
      <div className="modal-content custom-modal-content">
        <div className="modal-header custom-modal-header px-2 py-1">
          <h5 className="modal-title" id="AddTagModalLabel">
            {item
              ? "Modifier un administrateur"
              : "Ajouter un un administrateur"}
          </h5>
          <div className="workcares-btn-close-modal-container">
            <BtnCloseModal label="" />
          </div>
        </div>
        <div className="modal-body">
          <div className="container-form">
            <form
              onSubmit={(e) => {
                e.preventDefault();
                onSubmitForm(onAddAdmin);
              }}
            >
              <div className="row">
                <div className="col-md-6">
                  <Input
                    type="text"
                    label="Prénom"
                    placeholder="Prénom"
                    {...register("prenom")}
                  />
                  <FormError error={errors.prenom} />
                </div>
                <div className="col-md-6">
                  <Input
                    type="text"
                    label="Nom"
                    placeholder="Nom"
                    {...register("nom")}
                  />
                  <FormError error={errors.nom} />
                </div>
                <div className={"col-md-6"}>
                  <Input
                    type="text"
                    label="Téléphone"
                    placeholder="Téléphone"
                    {...register("telephone")}
                  />
                  <FormError error={errors.telephone} />
                </div>

                <div className="col-md-6">
                  <Input
                    type="email"
                    label="Adresse mail"
                    placeholder="Adresse mail"
                    readOnly={!!item}
                    {...register("email")}
                  />
                  <FormError error={errors.email} />
                </div>
                <div className="col-md-6 offset-md-6">
                  <div className="mb-3 mt-5 flex-r gap-3">
                    <BtnAnnulerModal label={"Fermer"} isLoading={false} />
                    <BtnSubmit
                      label={item ? "Modifier" : "Ajouter"}
                      isLoading={loading}
                      style={{ padding: "5px 10px", fontSize: 14 }}
                    />
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AjouterAdministrateurModal;
