import { deleteField } from "firebase/firestore";
import moment from "moment";
import { useRef } from "react";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import { AiFillEye } from "react-icons/ai";
import { FaEdit, FaTrash, FaUser } from "react-icons/fa";
import { NavLink } from "react-router-dom";
import { Vehicule, VehiculeFormData } from "../../../../../types";
import { useDeleteItem, useUpdateItem } from "../../../../utils/api/hooks";
import { deleteVehicule, updateVehicule } from "../../../../utils/api/user.api";
import { getImage, useModalActions } from "../../../../utils/data";
import AssignDriverModal from "../../../Concessionnaires/utilisateurs/AssignDriverModal";
import { CommonTable } from "../../../common/Input";
import AjouterVehiculeModal from "../Modales/AjouterVehiculeModal";
type VehiculeProprietaireTableProps = {
  vehicules: Vehicule[];
  admin?: boolean;
};
function VehiculeProprietaireTable({
  vehicules,
  admin,
}: VehiculeProprietaireTableProps) {
  const ref = useRef<Vehicule>();
  const onUpdateItem = useUpdateItem(
    updateVehicule,
    `Êtes-vous sûr de vouloir continuer ?`,
    `Véhicule assigné avec succés`,
    `Véhicule non assigné`,
    () => {}
  );

  const onCancelItem = useUpdateItem(
    updateVehicule,
    `Êtes-vous sûr de vouloir annulé l'assignation ?`,
    `Assignation annulée avec succés`,
    `Assignation  non annulée`,
    () => {}
  );
  const parentModalId = "UtilisateurConcessionnaireModal";
  const ma = useModalActions("UtilisateurConcessionnaireModal");
  const onDelete = useDeleteItem(
    deleteVehicule,
    `Êtes-vous sûr de vouloir supprimer ce véhicule ?`,
    `Véhicule supprimé avec succés`,
    `Véhicule non supprimé`
  );
  const actionFormatter = (cell: string, row: Vehicule) => {
    const modalId = `VehiculeUpdate${row.uid}`;
    return (
      <div className="table-actions-btn-container-action gap-2 d-flex">
        {admin && (
          <>
            {!!row.driverId && (
              <button
                className={`btn btn-action-admin-supprimer   with-tooltip`}
                data-tooltip-content="Annuler l'assignation"
                onClick={() => {
                  onCancelItem(row.uid, { driverId: deleteField() });
                }}
              >
                <FaUser />
              </button>
            )}

            {!row.driverId && (
              <button
                className={`btn btn-action-admin-voir with-tooltip`}
                data-tooltip-content="Assigner"
                data-bs-toggle="modal"
                data-bs-target={`#${parentModalId}`}
                onClick={(e) => {
                  ref.current = row;
                }}
              >
                <FaUser />
              </button>
            )}
          </>
        )}

        <button
          className="btn btn-action-admin-voir  with-tooltip"
          data-tooltip-content="Modifier"
          data-bs-toggle="modal"
          data-bs-target={`#${modalId}`}
        >
          <FaEdit />
        </button>
        <button
          className="btn btn-action-admin-supprimer with-tooltip"
          data-tooltip-content="Supprimer"
          onClick={() => {
            onDelete(row.uid);
          }}
        >
          <FaTrash />
        </button>
        <NavLink
          to={
            admin
              ? `/admin/vehicules/${row.uid}`
              : `/proprietaire/vehicules/${row.uid}`
          }
          className="btn btn-action-admin-voir with-tooltip no-link"
          state={row}
          data-tooltip-content="Voir"
        >
          <AiFillEye />
        </NavLink>
        <div
          className="modal fade"
          id={modalId}
          aria-labelledby="AjouterAdministrateurModalLabel"
          aria-hidden="true"
        >
          <AjouterVehiculeModal item={row} modalId={modalId} />
        </div>
      </div>
    );
  };
  const imageFormatter = (cell: string, row: Vehicule) => {
    return (
      <div>
        <img
          src={getImage(row)}
          alt="user avatar"
          className="workcares-table-user-avatar"
        />{" "}
      </div>
    );
  };

  const columns = [
    {
      dataField: "image",
      text: "Image",
      headerStyle: () => {
        return { width: "60px", whiteSpace: "normal" };
      },
      formatter: (cell: string, row: Vehicule) => imageFormatter(cell, row),
    },
    {
      dataField: "matricule",
      text: "Matricule",
    },
    {
      dataField: "driver",
      text: "Chauffeur",
      formatter: (cell: string, row: Vehicule) => {
        if (!row.driverId) return "Pas de chauffeur";
        if (!row.driver) return "-";
        return `${row.driver.prenom} ${row.driver.nom}`;
      },
    },
    {
      dataField: "model",
      text: "Modèle",
    },

    {
      dataField: "annee",
      text: "Année",
    },
    {
      dataField: "mode",
      text: "Mode",
    },
    {
      dataField: "dateVisite",
      text: "Prochaine visite",
      formatter: (cell: string, row: Vehicule) =>
        moment(row.dateVisite).format("DD/MM/YYYY"),
    },
    {
      dataField: "action",
      text: "Actions",
      style: { textAlign: "center" },
      formatter: (cell: string, row: Vehicule) => actionFormatter(cell, row),
    },
  ];

  return (
    <>
      {admin && (
        <AssignDriverModal
          parentModalId={parentModalId}
          onChoice={(item) => {
            ma.hide();
            setTimeout(() => {
              if (ref.current) {
                const data: VehiculeFormData = { driverId: item.uid };
                onUpdateItem(ref.current.uid, data);
              }
            }, 500);
          }}
        />
      )}
      <CommonTable keyField="uid" data={vehicules} columns={columns} />
    </>
  );
}

export default VehiculeProprietaireTable;
