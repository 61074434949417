import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
// import { FaTrash } from "react-icons/fa";
import { useEffect, useState } from "react";
import { useAdminPendingOtherRequests } from "../../../../utils/api/hooks";
import CommonPriseEnChargeAdminTable from "./CommonPriseEnChargeTable";

type PendingPriseEnChargeAdminTableProps = {
  search: string;
};

function PendingPriseEnChargeAdminTable({
  search,
}: PendingPriseEnChargeAdminTableProps) {
  const requests = useAdminPendingOtherRequests();

  const [data, setData] = useState(requests);

  useEffect(() => {
    const s = search.trim().toLowerCase();
    if (s) {
      setData(
        requests.filter(
          (f) =>
            `${f.identifier} ${f.fullname ?? ""} ${f.telephone ?? ""}`
              .toLowerCase()
              .includes(s) && !f.user
        )
      );
    } else {
      setData(requests.filter((u) => !u.user));
    }
  }, [search, requests]);

  return <CommonPriseEnChargeAdminTable enableDelete data={data} />;
}

export default PendingPriseEnChargeAdminTable;
