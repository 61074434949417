import moment from "moment";
import { useEffect } from "react";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import { FaTrash } from "react-icons/fa";
import { UserInfos } from "../../../../../types";
import { useAppDispatch, useAppSelector } from "../../../../redux/root";
import { onDeleteJours, onJours } from "../../../../redux/slices/jour.slice";
import { useDeleteItem } from "../../../../utils/api/hooks";
import { deleteJour, joursListener } from "../../../../utils/api/user.api";
import { CommonTable } from "../../../common/Input";

function JoursTable() {
  const dispatch = useAppDispatch()
  const data = useAppSelector(s => s.jours)
  useEffect(() => {
    const li = joursListener((data,dels) => {
      if(data.length >0){
        dispatch(onJours(data))
      }
      if(dels.length >0){
        dispatch(onDeleteJours(dels))
      }
    });
    return () => li();
  }, []);

  const onDelete = useDeleteItem(
    (id)=> deleteJour(id),
    `Êtes-vous sûr de vouloir supprimer?`,
    `Jour férié supprimé avec succés`,
    `Jour férié non supprimé`
  );

  const actionFormatter = (cell: string, row: UserInfos) => {
    return (
      <div className="table-actions-btn-container-action gap-2 d-flex">
       
        <button
          className="btn btn-action-admin-supprimer with-tooltip"
          data-tooltip-content="Supprimer"
          onClick={() => {
            onDelete(row.uid);
          }}
        >
          <FaTrash />
        </button>
      </div>
    );
  };
  const dateFormatter = (cell: string, row: UserInfos) => {
    return (
      <div>
        {moment(cell).format('DD/MM/YYYY HH:mm')}
      </div>
    );
  };

  const columns = [
    {
      dataField: "start",
      text: "Date début",
      headerStyle: () => {
        return { width: "250px", whiteSpace: "normal" };
      },
      formatter: dateFormatter,
    },
    {
      dataField: "end",
      text: "Date fin",
      headerStyle: () => {
        return { width: "250px", whiteSpace: "normal" };
      },
      formatter: dateFormatter,
    },
    {
      dataField: "action",
      text: "Actions",
      style: { textAlign: "center" },
      formatter: actionFormatter,
    },
  ];

  return (
    <CommonTable
      keyField="uid"
      data={data}
      columns={columns}
    />
  );
}

export default JoursTable;
