import moment from "moment";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import { AiFillEye } from "react-icons/ai";
import { FaEdit, FaTrash } from "react-icons/fa";
import { NavLink } from "react-router-dom";
import { Invoice } from "../../../../../types";
import { useDeleteItem } from "../../../../utils/api/hooks";
import { deleteInvoice } from "../../../../utils/api/user.api";
import { getAvatar } from "../../../../utils/data";
import { CommonTable } from "../../../common/Input";
import { TogglePaidInvoice } from "../../concessionnaires/Tables/ConcessionnairesAdminTable";
import AjouterInvoiceModal from "../Modales/AjouterInvoiceModal";
type InvoiceTableProps = {
  invoices: Invoice[];
  admin?: boolean;
  userId?: string;
};
function InvoiceTable({ invoices, admin, userId }: InvoiceTableProps) {
  const onDelete = useDeleteItem(
    deleteInvoice,
    `Êtes-vous sûr de vouloir supprimer cette facture ?`,
    `Facture supprimée avec succés`,
    `Facture non supprimée`
  );
  const actionFormatter = (cell: string, row: Invoice) => {
    const modalId = `InvoiceUpdate${row.uid}`;
    return (
      <div className="table-actions-btn-container-action gap-2 d-flex">
        {admin && (
          <>
            <button
              className="btn btn-action-admin-voir  with-tooltip"
              data-tooltip-content="Modifier"
              data-bs-toggle="modal"
              data-bs-target={`#${modalId}`}
            >
              <FaEdit />
            </button>
            <button
              className="btn btn-action-admin-supprimer with-tooltip"
              data-tooltip-content="Supprimer"
              onClick={() => {
                onDelete(row.uid);
              }}
            >
              <FaTrash />
            </button>

            <div
              className="modal fade"
              id={modalId}
              aria-labelledby="AjouterAdministrateurModalLabel"
              aria-hidden="true"
            >
              <AjouterInvoiceModal
                userId={userId}
                item={row}
                modalId={modalId}
              />
            </div>
          </>
        )}

        <NavLink
          to={`${row.fichier}`}
          target="_blank"
          className="btn btn-action-admin-voir with-tooltip no-link"
          data-tooltip-content="Voir"
        >
          <AiFillEye />
        </NavLink>
      </div>
    );
  };
  const imageFormatter = (cell: string, row: Invoice) => {
    if (!row.user) return "-";
    return (
      <div>
        <img
          src={getAvatar(row.user)}
          alt="user avatar"
          className="workcares-table-user-avatar"
        />{" "}
        {row.user.prenom} {row.user.nom}
      </div>
    );
  };

  const columns = [
    {
      dataField: "title",
      text: "Libelle",
    },
    ,
    {
      dataField: "amount",
      text: "Montant",
    },
    {
      dataField: "created_at",
      text: "Date",
      formatter: (cell: string, row: Invoice) =>
        moment(row.created_at).format("DD/MM/YYYY"),
    },
    {
      dataField: "paid",
      text: "Payée ?",
      formatter: (cell: string, row: Invoice) => (
        <TogglePaidInvoice readonly={!admin} item={row} />
      ),
    },
    {
      dataField: "action",
      text: "Actions",
      style: { textAlign: "center" },
      formatter: (cell: string, row: Invoice) => actionFormatter(cell, row),
    },
  ];

  if (admin && !userId) {
    columns.splice(0, 0, {
      dataField: "user",
      text: "Destinataire",
      headerStyle: () => {
        return { width: "250px", whiteSpace: "normal" };
      },
      formatter: (cell: string, row: Invoice) => imageFormatter(cell, row),
    } as any);
  }

  return (
    <>
      <CommonTable keyField="uid" data={invoices} columns={columns} />
    </>
  );
}

export default InvoiceTable;
