import { useCallback, useEffect, useState } from "react";
import { AiOutlinePlusSquare } from "react-icons/ai";
import { LandingPage } from "../../../../types";
import { useAppSelector } from "../../../redux/root";
import { useUpdateLandingPage } from "../../../utils/api/hooks";
import { useFormData } from "../../../utils/form";
import { FormError } from "../../auth/Login";
import { BtnSubmit } from "../../common/Button";
import { Input } from "../../common/Input";
import GestionPtc from "./GestionPtc";
import AjouterFaqModal from "./Modales/AjouterFaqModal";
import "./ParametresAdmin.css";
import FaqTable from "./Tables/FaqTable";

const tabs = ["Liens", "Faq", "Politique de confidentialité"];
function Tabs() {
  const [index, setIndex] = useState(0);
  const go = useCallback((index: number) => {
    setIndex(index);
  }, []);

  return (
    <div className="dashbord-visiteur-component">
      <div className="terranga-auto-dash-admin-titre-btn-container mb-4">
        <div className="workcare-dash-admin-titre-container">
          <h1 className="terranga-auto-dash-admin-titre">Landing page</h1>
        </div>
      </div>
      <div className="workcare-dash-admin-page-content-container">
        <ul className="tabs-container mt-4">
          {tabs.map((tab, key) => (
            <li
              className={`tab-item  ${index === key && "tab-active-item"}`}
              key={key}
              style={{ fontSize: 13 }}
              onClick={() => go(key)}
            >
              {tab}
            </li>
          ))}
        </ul>
        <div className="tabs-component-container mt-5">
          {index === 0 && <Links />}

          {index === 1 && <Faq />}
          {index === 2 && <GestionPtc />}
        </div>
      </div>
    </div>
  );
}

function Links() {
  const lp = useAppSelector((s) => s.landing_page);
  const { errors, onChange, onSubmitForm, register } = useFormData<LandingPage>(
    {
      appstore: "",
      facebook: "",
      instagram: "",
      linkedin: "",
      playstore: "",
      twitter: "",
    },
    (Yup) => ({})
  );

  const { loading, onUpdate } = useUpdateLandingPage(() => {});
  useEffect(() => {
    onChange(lp);
  }, [lp]);
  const inputs: { name: keyof LandingPage; placeholder: string }[] = [
    { name: "appstore", placeholder: "Lien Appstore" },
    { name: "playstore", placeholder: "Lien Playstore" },
    { name: "facebook", placeholder: "Lien Facebook" },
    { name: "instagram", placeholder: "Lien Instagram" },
    { name: "linkedin", placeholder: "Lien LinkedIn" },
    { name: "twitter", placeholder: "Lien Twitter" },
  ];

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        onSubmitForm(onUpdate);
      }}
      className="row g-3 locataire-requete-filtre-form"
    >
      <div className="row">
        {inputs.map((i) => {
          const name = i.name as keyof LandingPage;
          return (
            <div className="col-md-6">
              <Input
                type="url"
                label={i.placeholder}
                placeholder={i.placeholder}
                {...register(name)}
              />
              <FormError error={errors[name] ? String(errors[name]) : ""} />
            </div>
          );
        })}

        <div className="col-md-3">
          <div className="mb-3 mt-2 flex-r gap-3">
            <BtnSubmit
              label={"Modifier"}
              isLoading={loading}
              style={{ padding: "5px 10px", fontSize: 14 }}
            />
          </div>
        </div>
      </div>
    </form>
  );
}

function Faq() {
  return (
    <>
      <div className="terranga-auto-dash-admin-titre-btn-container mb-4">
        <div className="workcare-dash-admin-titre-container" />
        <button
          className="terranga-auto-dash-admin-btn-ajout-deux"
          data-bs-toggle="modal"
          data-bs-target={`#faq-add`}
        >
          <AiOutlinePlusSquare className="terranga-auto-dash-admin-btn-ajout-icone" />{" "}
          Ajouter un Faq
        </button>
        <div
          className="modal fade"
          id={"faq-add"}
          aria-labelledby="AjouterAdministrateurModalLabel"
          aria-hidden="true"
        >
          <AjouterFaqModal modalId={"faq-add"} />
        </div>
      </div>
      <FaqTable />
    </>
  );
}

export default Tabs;
