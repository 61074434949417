import { useEffect, useState } from "react";
import { AiOutlinePlusSquare } from "react-icons/ai";
import { Driver } from "../../../../types";
import { useUtilisateurs } from "../../../utils/api/hooks";
import AjouterUtilisateurModal from "../../admin/Chauffeurs/Modales/AjouterUtilisateurModal";
import UtilisateursTable from "../../admin/Chauffeurs/Tables/UtilisateursTable";
import { BtnCloseModal } from "../../common/Button";
import "./UtilisateurConcessionnaire.css";
function UtilisateurConcessionnaire({
  parentModalId,
  onChoice,
}: {
  parentModalId: string;
  onChoice: (item: Driver) => void;
}) {
  const users = useUtilisateurs();
  const [drivers, setDrivers] = useState<Driver[]>([]);
  const [search, setSearch] = useState("");

  const [show, setShow] = useState(false);

  useEffect(() => {
    const s = search.trim().toLowerCase();
    if (s) {
      setDrivers(
        users.filter((u) =>
          `${u.prenom} ${u.nom} ${u.telephone} ${u.nomSociete}`
            .toLowerCase()
            .includes(s)
        )
      );
    } else {
      setDrivers(users);
    }
  }, [search, users]);

  return (
    <div
      className="modal fade"
      id={parentModalId}
      aria-labelledby="AjouterUtilisateurModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lg">
        <div className="modal-content custom-modal-content">
          <div className="modal-header custom-modal-header px-2">
            <h5 className="modal-title" id="AddTagModalLabel">
              Choisir un client
            </h5>
            <div className="workcares-btn-close-modal-container">
              <BtnCloseModal label="" />
            </div>
          </div>
          <div className="modal-body">
            <div
              style={{
                flexDirection: "row",
                alignItems: "center",
                display: "flex",
                gap: 10,
                marginBottom: 10,
              }}
            >
              <div>
                <button
                  className="terranga-auto-dash-admin-btn-ajout-deux"
                  onClick={() => setShow(true)}
                >
                  <AiOutlinePlusSquare className="terranga-auto-dash-admin-btn-ajout-icone" />{" "}
                  Ajouter un client
                </button>
              </div>
              <div>
                <input
                  type="text"
                  className="form-control locataire-requete-filtre-input"
                  id="filtreLogement"
                  placeholder="Chercher..."
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
              </div>
            </div>

            {show && (
              <AjouterUtilisateurModal
                onAddItem={() => {
                  setSearch("");
                  setShow(false);
                }}
              />
            )}
            <UtilisateursTable
              onChoice={(item) => {
                onChoice(item);
              }}
              drivers={drivers}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default UtilisateurConcessionnaire;
