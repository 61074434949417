import { JourFormData } from "../../../../../types";
import { useAddJour } from "../../../../utils/api/hooks";
import { useModalActions } from "../../../../utils/data";
import { useFormData } from "../../../../utils/form";
import {
  BtnAnnulerModal,
  BtnCloseModal,
  BtnSubmit,
} from "../../../common/Button";
import { FormError, Input } from "../../../common/Input";

type AjouterJourModalProps = {
  modalId?: string;
};
function AjouterJourModal({ modalId }: AjouterJourModalProps) {
  const ma = useModalActions(modalId ?? "");

  const { errors, onChange, onSubmitForm, register } =
    useFormData<JourFormData>(
      {
        start: "",
        end: "",
      },
      (Yup) => ({
        start: Yup.string().required().label("Début"),
        end: Yup.string().required().label("Fin"),
      })
    );

  const { loading, onAdd } = useAddJour(() => {
    ma.hide();
    setTimeout(() => {
      onChange({
        start: "",
        end: "",
      });
    }, 1500);
  });
  return (
    <div className="modal-dialog modal-lg">
      <div className="modal-content custom-modal-content">
        <div className="modal-header custom-modal-header px-2 py-1">
          <h5 className="modal-title" id="AddTagModalLabel">
            Ajouter un jour férié
          </h5>
          <div className="workcares-btn-close-modal-container">
            <BtnCloseModal label="" />
          </div>
        </div>
        <div className="modal-body">
          <div className="container-form">
            <form
              onSubmit={(e) => {
                e.preventDefault();
                onSubmitForm(onAdd);
              }}
            >
              <div className="row">
                <div className="col-md-6">
                  <Input
                    type="datetime-local"
                    label="Date début"
                    placeholder="Date début"
                    {...register("start")}
                  />
                  <FormError error={errors.start} />
                </div>
                <div className="col-md-6">
                  <Input
                    type="datetime-local"
                    label="Date fin"
                    placeholder="Date fin"
                    {...register("end")}
                  />
                  <FormError error={errors.end} />
                </div>

                <div className="col-md-6 offset-md-6">
                  <div className="mb-3 mt-5 flex-r gap-3">
                    <BtnAnnulerModal label={"Fermer"} isLoading={false} />
                    <BtnSubmit
                      label={"Ajouter"}
                      isLoading={loading}
                      style={{ padding: "5px 10px", fontSize: 14 }}
                    />
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AjouterJourModal;
