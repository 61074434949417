import { BiCategory } from "react-icons/bi";
import { BsFillChatDotsFill } from "react-icons/bs";
import { FaCar, FaFile, FaUser } from "react-icons/fa";
import { IoLogOutOutline } from "react-icons/io5";
import { RiMailFill, RiSettings3Fill } from "react-icons/ri";
import { NavLink } from "react-router-dom";
import { useAppDispatch, useUser } from "../../../../redux/root";
import { onLogout } from "../../../../redux/slices/user.slice";
import { logoutAsync } from "../../../../utils/api/auth.api";
import {
  isAdmin,
  isConcessionnaires,
  isOwner,
} from "../../../../utils/api/hooks";
import VersionChecker from "../../../../versionChecker/VersionChecker";
import "./SidebarAdmin.css";

const SidebarAdmin = () => {
  const dispatch = useAppDispatch();
  return (
    <div className="navbar-vertical-customisation-v2 no-view-mobile">
      <div className="hoverable mt-4" id="scrollbar">
        <div className="content">
          <div className="move-left-side-menu"></div>
          <div className="container-user-connect-info pb-3">
            <div className="container-info-user-connect-navmenu ps-2">
              <p className="app-title-connect-navmenu mb-1">
                Jambaars
                <span className="app-title-connect-navmenu-bold">Auto</span>
              </p>
            </div>
          </div>
          <ul className="nav nav-items-custom flex-column">
            <AdminRoutes />
            <OwnerRoutes />
            <ConcessionnaireRoutes />
          </ul>
          <div className="container-btn-logout">
            <button
              onClick={() => {
                logoutAsync();
                dispatch(onLogout());
                window.location.reload();
              }}
              className="btn btn-logout"
            >
              <IoLogOutOutline className="btn-logout-icone" />
              <span className="ps-2">Déconnexion</span>
            </button>
            <div className="version-view-container ps-3">
              {"v" + VersionChecker()}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SidebarAdmin;

function OwnerRoutes() {
  const user = useUser();
  if (!isOwner(user)) return null;
  return (
    <>
      <li className="nav-item nav-item-vertical-custom">
        <NavLink
          className="nav-link nav-link-vertival-custom with-tooltip-menu"
          to="/proprietaire/dashboard"
          data-tooltip-content="Dashboard"
        >
          <span className="icon-container-nav-link-vertival">
            <BiCategory />
          </span>
          <span className="hiddenable-navlink-label">Dashboard</span>
        </NavLink>
      </li>

      <li className="nav-item nav-item-vertical-custom">
        <NavLink
          className="nav-link nav-link-vertival-custom with-tooltip-menu"
          to="/proprietaire/prise-en-charges"
          data-tooltip-content="Prise en charge"
        >
          <span className="icon-container-nav-link-vertival">
            <RiSettings3Fill />
          </span>
          <span className="hiddenable-navlink-label">Prise en charge</span>
        </NavLink>
      </li>

      <li className="nav-item nav-item-vertical-custom">
        <NavLink
          className="nav-link nav-link-vertival-custom with-tooltip-menu"
          to="/proprietaire/parametres"
          data-tooltip-content="Paramètres"
        >
          <span className="icon-container-nav-link-vertival">
            <RiSettings3Fill />
          </span>
          <span className="hiddenable-navlink-label">Paramètres</span>
        </NavLink>
      </li>
      <li className="nav-item nav-item-vertical-custom">
        <NavLink
          className="nav-link nav-link-vertival-custom with-tooltip-menu"
          to="/proprietaire/vehicules"
          data-tooltip-content="Chauffeurs"
        >
          <span className="icon-container-nav-link-vertival">
            <FaCar />
          </span>
          <span className="hiddenable-navlink-label">Vehicules</span>
        </NavLink>
      </li>
      <li className="nav-item nav-item-vertical-custom">
        <NavLink
          className="nav-link nav-link-vertival-custom with-tooltip-menu"
          to="/proprietaire/profile"
          data-tooltip-content="Profile"
        >
          <span className="icon-container-nav-link-vertival">
            <RiSettings3Fill />
          </span>
          <span className="hiddenable-navlink-label">Profile</span>
        </NavLink>
      </li>
    </>
  );
}

function ConcessionnaireRoutes() {
  const user = useUser();
  if (!isConcessionnaires(user)) return null;
  return (
    <>
      <li className="nav-item nav-item-vertical-custom">
        <NavLink
          className="nav-link nav-link-vertival-custom with-tooltip-menu"
          to="/concessionnaire/dashboard"
          data-tooltip-content="Dashboard"
        >
          <span className="icon-container-nav-link-vertival">
            <BiCategory />
          </span>
          <span className="hiddenable-navlink-label">Dashboard</span>
        </NavLink>
      </li>
      <li className="nav-item nav-item-vertical-custom">
        <NavLink
          className="nav-link nav-link-vertival-custom with-tooltip-menu"
          to="/concessionnaire/vehicules"
          data-tooltip-content="Chauffeurs"
        >
          <span className="icon-container-nav-link-vertival">
            <FaCar />
          </span>
          <span className="hiddenable-navlink-label">Vehicules</span>
        </NavLink>
      </li>
      <li className="nav-item nav-item-vertical-custom">
        <NavLink
          className="nav-link nav-link-vertival-custom with-tooltip-menu"
          to="/concessionnaire/factures"
          data-tooltip-content="Clients"
        >
          <span className="icon-container-nav-link-vertival">
            <FaFile />
          </span>
          <span className="hiddenable-navlink-label">Factures</span>
        </NavLink>
      </li>
      <li className="nav-item nav-item-vertical-custom">
        <NavLink
          className="nav-link nav-link-vertival-custom with-tooltip-menu"
          to="/concessionnaire/prise-en-charges"
          data-tooltip-content="Prise en charge"
        >
          <span className="icon-container-nav-link-vertival">
            <RiSettings3Fill />
          </span>
          <span className="hiddenable-navlink-label">Prise en charge</span>
        </NavLink>
      </li>
      {/* <li className="nav-item nav-item-vertical-custom">
        <NavLink
          className="nav-link nav-link-vertival-custom with-tooltip-menu"
          to="/concessionnaire/utilisateurs"
          data-tooltip-content="Chauffeurs"
        >
          <span className="icon-container-nav-link-vertival">
            <FaUser />
          </span>
          <span className="hiddenable-navlink-label">Utilisateurs</span>
        </NavLink>
      </li> */}
      <li className="nav-item nav-item-vertical-custom">
        <NavLink
          className="nav-link nav-link-vertival-custom with-tooltip-menu"
          to="/concessionnaire/parametres"
          data-tooltip-content="Paramètres"
        >
          <span className="icon-container-nav-link-vertival">
            <RiSettings3Fill />
          </span>
          <span className="hiddenable-navlink-label">Paramètres</span>
        </NavLink>
      </li>
      <li className="nav-item nav-item-vertical-custom">
        <NavLink
          className="nav-link nav-link-vertival-custom with-tooltip-menu"
          to="/concessionnaire/profile"
          data-tooltip-content="Profile"
        >
          <span className="icon-container-nav-link-vertival">
            <RiSettings3Fill />
          </span>
          <span className="hiddenable-navlink-label">Profile</span>
        </NavLink>
      </li>
    </>
  );
}

function AdminRoutes() {
  const user = useUser();
  if (!isAdmin(user)) return null;

  return (
    <>
      <li className="nav-item nav-item-vertical-custom">
        <NavLink
          className="nav-link nav-link-vertival-custom with-tooltip-menu"
          to="/admin/dashboard"
          data-tooltip-content="Dashboard"
        >
          <span className="icon-container-nav-link-vertival">
            <BiCategory />
          </span>
          <span className="hiddenable-navlink-label">Dashboard</span>
        </NavLink>
      </li>
      <li className="nav-item nav-item-vertical-custom">
        <NavLink
          className="nav-link nav-link-vertival-custom with-tooltip-menu"
          to="/admin/prise-en-charge"
          data-tooltip-content="Prise en charge"
        >
          <span className="icon-container-nav-link-vertival">
            <RiSettings3Fill />
          </span>
          <span className="hiddenable-navlink-label">Prise en charge</span>
        </NavLink>
      </li>
      <li className="nav-item nav-item-vertical-custom">
        <NavLink
          className="nav-link nav-link-vertival-custom with-tooltip-menu"
          to="/admin/clients"
          data-tooltip-content="Clients"
        >
          <span className="icon-container-nav-link-vertival">
            <FaUser />
          </span>
          <span className="hiddenable-navlink-label">Clients</span>
        </NavLink>
      </li>
      <li className="nav-item nav-item-vertical-custom">
        <NavLink
          className="nav-link nav-link-vertival-custom with-tooltip-menu"
          to="/admin/vehicules"
          data-tooltip-content="Chauffeurs"
        >
          <span className="icon-container-nav-link-vertival">
            <FaCar />
          </span>
          <span className="hiddenable-navlink-label">Vehicules</span>
        </NavLink>
      </li>
      <li className="nav-item nav-item-vertical-custom">
        <NavLink
          className="nav-link nav-link-vertival-custom with-tooltip-menu"
          to="/admin/factures"
          data-tooltip-content="Clients"
        >
          <span className="icon-container-nav-link-vertival">
            <FaFile />
          </span>
          <span className="hiddenable-navlink-label">Factures</span>
        </NavLink>
      </li>
      <li className="nav-item nav-item-vertical-custom">
        <NavLink
          className="nav-link nav-link-vertival-custom with-tooltip-menu"
          to="/admin/chauffeurs"
          data-tooltip-content="Chauffeurs"
        >
          <span className="icon-container-nav-link-vertival">
            <FaUser />
          </span>
          <span className="hiddenable-navlink-label">Chauffeurs</span>
        </NavLink>
      </li>
      <li className="nav-item nav-item-vertical-custom">
        <NavLink
          className="nav-link nav-link-vertival-custom with-tooltip-menu"
          to="/admin/proprietaires"
          data-tooltip-content="Propriétaires"
        >
          <span className="icon-container-nav-link-vertival">
            <FaUser />
          </span>
          <span className="hiddenable-navlink-label">Propriétaires</span>
        </NavLink>
      </li>
      <li className="nav-item nav-item-vertical-custom">
        <NavLink
          className="nav-link nav-link-vertival-custom with-tooltip-menu"
          to="/admin/concessionnaires"
          data-tooltip-content="Concessionnaires"
        >
          <span className="icon-container-nav-link-vertival">
            <FaUser />
          </span>
          <span className="hiddenable-navlink-label">Concessionnaires</span>
        </NavLink>
      </li>
      <li className="nav-item nav-item-vertical-custom">
        <NavLink
          className="nav-link nav-link-vertival-custom with-tooltip-menu"
          to="/admin/message-contact"
          data-tooltip-content="Message Contact"
        >
          <span className="icon-container-nav-link-vertival">
            <BsFillChatDotsFill />
          </span>
          <span className="hiddenable-navlink-label">Messages Contact</span>
        </NavLink>
      </li>

      <li className="nav-item nav-item-vertical-custom">
        <NavLink
          className="nav-link nav-link-vertival-custom with-tooltip-menu"
          to="/admin/tarification"
          data-tooltip-content="Paramètres"
        >
          <span className="icon-container-nav-link-vertival">
            <RiSettings3Fill />
          </span>
          <span className="hiddenable-navlink-label">Tarification</span>
        </NavLink>
      </li>
      <li className="nav-item nav-item-vertical-custom">
        <NavLink
          className="nav-link nav-link-vertival-custom with-tooltip-menu"
          to="/admin/landing-page"
          data-tooltip-content="Paramètres Landing page"
        >
          <span className="icon-container-nav-link-vertival">
            <RiSettings3Fill />
          </span>
          <span className="hiddenable-navlink-label">Landing Page</span>
        </NavLink>
      </li>
      <li className="nav-item nav-item-vertical-custom">
        <NavLink
          className="nav-link nav-link-vertival-custom with-tooltip-menu"
          to="/admin/newsletter"
          data-tooltip-content="Paramètres"
        >
          <span className="icon-container-nav-link-vertival">
            <RiMailFill />
          </span>
          <span className="hiddenable-navlink-label">Newsletter</span>
        </NavLink>
      </li>
      <li className="nav-item nav-item-vertical-custom">
        <NavLink
          className="nav-link nav-link-vertival-custom with-tooltip-menu"
          to="/admin/parametres"
          data-tooltip-content="Paramètres"
        >
          <span className="icon-container-nav-link-vertival">
            <RiSettings3Fill />
          </span>
          <span className="hiddenable-navlink-label">Paramètres</span>
        </NavLink>
      </li>
      <li className="nav-item nav-item-vertical-custom">
        <NavLink
          className="nav-link nav-link-vertival-custom with-tooltip-menu"
          to="/admin/profile"
          data-tooltip-content="Profil"
        >
          <span className="icon-container-nav-link-vertival">
            <RiSettings3Fill />
          </span>
          <span className="hiddenable-navlink-label">Profile</span>
        </NavLink>
      </li>
    </>
  );
}
