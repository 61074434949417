import Fade from "react-reveal/Fade";
import Slide from "react-reveal/Slide";
import AppStore from "../../assets/landingpage/app-store.png";
import Phone from "../../assets/landingpage/phone-banner.png";
import PlayStore from "../../assets/landingpage/play-store.png";
import { useAppSelector } from "../../redux/root";

const BannerPage = () => {
  const lp = useAppSelector((s) => s.landing_page);
  return (
    <div className="banner-page-container">
      <div className="container-app-landing-page">
        <div className="row row-banner mt-5">
          <div className="col-md-7 col-left-banner-home-page">
            <Fade left cascade>
              <div className="content-col-left-home-page">
                <h1 className="title-home-page-banner">
                  Téléchargez l’application et faites vous dépanner partout au
                  Sénégal
                </h1>
                <div className="content-text-description-home-page-banner mt-4 mb-5">
                  <p className="text-description-home-page-banner">
                    Jambars auto est l'application mobile qui vous accompagne où
                    que vous soyez. Qu'il s'agisse d'une panne sur la route,
                    d'un accident, d'un saut de batterie ou d'un changement de
                    pneu, notre équipe de professionnels expérimentés est
                    disponible 24 heures sur 24 et 7 jours sur 7 pour vous
                    fournir une assistance rapide et fiable.
                  </p>
                  <p className="text-description-home-page-banner">
                    Avec Jambars auto, vous pouvez être sûr de reprendre la
                    route en un rien de temps
                  </p>
                </div>
                <div className="content-btn-download-app pt-4">
                  <div className="download-app-item">
                    <a
                      href={lp.appstore}
                      className="btn btn-download-app"
                      target="_blank"
                    >
                      <img
                        src={AppStore}
                        alt="App Store"
                        className="img-download-app"
                      />
                    </a>
                  </div>
                  <div className="download-app-item">
                    <a
                      href={lp.playstore}
                      className="btn btn-download-app"
                      target="_blank"
                    >
                      <img
                        src={PlayStore}
                        alt="Play Store"
                        className="img-download-app"
                      />
                    </a>
                  </div>
                </div>
              </div>
            </Fade>
          </div>
          <div className="col-md-5 col-right-home-page-banner">
            <div className="content-col-right-home-page-banner position-relative">
              <Slide right cascade>
                <div className="container-img-banner">
                  <img
                    src={Phone}
                    alt="image bannière"
                    className="img-banner"
                  />
                </div>
              </Slide>
              <div className="bg-img-phone-banner"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BannerPage;
