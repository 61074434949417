import moment from "moment";
import { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { Filter, Request } from "../../../../../types";
import { FilterDataList } from "../../../../utils/api/dashboard";
import "./PriseEnChargeGraph.css";

type State = {
  count: number;
  label: string;
};
function PriseEnChargeGraph({
  filter,
  data = [],
}: {
  filter: Filter;
  data: Request[];
}) {
  useEffect(() => {
    const stateObj: any = {};
    if (filter === "all") {
      for (let item of data) {
        const date = moment(item.date).format("YYYY");
        if (stateObj[date]) {
          stateObj[date]++;
        } else {
          stateObj[date] = 1;
        }
      }
    } else if (filter === "year") {
      let date = moment();
      const start = date.startOf(filter).toDate().getTime();
      const end = date.endOf(filter);
      for (let i = 0; ; i++) {
        const date = moment(start).add(i, "months");
        const formated = date.format("MM");
        stateObj[formated] = data.filter((u) =>
          moment(u.date).isSame(date, "months")
        ).length;
        if (date.isSame(end, "months")) break;
      }
    } else {
      let format: any = filter;
      let date = moment();
      if (filter === "last month") {
        format = "month";
        date = moment().subtract(1, "months").startOf("months");
      }
      const start = date.startOf(format).toDate().getTime();
      const end = date.endOf(format);
      for (let i = 0; ; i++) {
        const date = moment(start).add(i, "days");
        const formated = date.format("DD");
        stateObj[formated] = data.filter((u) =>
          moment(u.date).isSame(date, "dates")
        ).length;
        if (date.isSame(end, "dates")) break;
      }
    }
    const state = Object.keys(stateObj).map(
      (k) => ({ count: stateObj[k], label: k } as State)
    );
    setState(state.sort((a, b) => parseInt(a.label) - parseInt(b.label)));
  }, [filter, data.length]);
  const item = FilterDataList.find((f) => f.value === filter);
  const [state, setState] = useState<State[]>([]);

  const series = [
    {
      data: state.map((i) => i.count),
      name: "Prise en charges",
    },
  ];

  const options: any = {
    chart: {
      height: 280,
      type: "area",
    },
    dataLabels: {
      enabled: false,
    },
    colors: ["#1E4777"],
    fill: {
      type: "solid",
      colors: ["rgb(233, 237, 242)"],
    },
    xaxis: {
      categories: state.map((i) => {
        if (filter === "all" || filter === "month" || filter === "last month")
          return i.label;
        if (filter === "year") return moment(i.label, "MM").format("MMM");
        return moment(i.label, "DD").format("ddd");
      }),
      labels: {
        style: {
          colors: "#1E4777",
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: "#9FA2B4",
        },
      },
    },
  };
  return (
    <div className="chart-item-container">
      <div className="row mb-2 chart-infos-row">
        <div className="col-md-12 mb-2">
          <div className="chart-item-title-container">
            <h2 className="chart-item-title">Prise en charge</h2>
            <div className="chart-item-filter-container">
              <div className="chart-item-filter">
                <div>{item?.label}</div>
                <div className="chart-item-filter-indicator"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Chart
        className="chart-dashboard-marketplace"
        options={options}
        series={series}
        data={"Prise en charge"}
        type="area"
        width="100%"
        height="350"
      />
    </div>
  );
}

export default PriseEnChargeGraph;
