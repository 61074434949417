import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
// import { FaTrash } from "react-icons/fa";
import { useEffect, useState } from "react";
import { useAdminRequests } from "../../../../utils/api/hooks";
import CommonPriseEnChargeAdminTable from "./CommonPriseEnChargeTable";

type PriseEnChargeAdminTableProps = {
  search: string;
};

function PriseEnChargeAdminTable({ search }: PriseEnChargeAdminTableProps) {
  const requests = useAdminRequests();

  const [data, setData] = useState(requests);

  useEffect(() => {
    const s = search.trim().toLowerCase();
    if (s) {
      setData(
        requests.filter((f) =>
          `${f.identifier} ${f.fullname ?? ""} ${f.telephone ?? ""}`
            .toLowerCase()
            .includes(s)
        )
      );
    } else {
      setData(requests);
    }
  }, [search, requests]);

  return <CommonPriseEnChargeAdminTable data={data} />;
}

export default PriseEnChargeAdminTable;
