import { GooglePlaceData } from "../../types";
import { getAddressFromReults } from "../components/shared/GooglePlace";

import { geocodeByAddress } from "react-places-autocomplete";

export async function getLocationFromAddress(address: string) {
  const res = await geocodeByAddress(address);
  const data = getAddressFromReults(res);
  if (data) {
    const tem: GooglePlaceData = {
      ...data,
      address: address,
    };

    return tem;
  }
  return null;
}
