import { useEffect } from "react";
import { AiOutlinePlusSquare } from "react-icons/ai";
import { useAppDispatch } from "../../../redux/root";
import {
  onDeleteNewsletter,
  onNewsletter,
} from "../../../redux/slices/newsletter.slice";
import { newsletterListener } from "../../../utils/api/user.api";
import AjouterNewsletterModal from "./Modales/AjouterNewsletterModal";
import "./ParametresAdmin.css";
import NewsletterTable from "./Tables/NewsletterTable";

function NewslatterAdmin() {
  const dispatch = useAppDispatch();
  useEffect(() => {
    const li = newsletterListener((data, dels) => {
      if (data.length > 0) {
        dispatch(onNewsletter(data));
      }
      if (dels.length > 0) {
        dispatch(onDeleteNewsletter(dels));
      }
    });
    return () => li();
  }, []);
  return (
    <div className="dashbord-visiteur-component">
      <div className="terranga-auto-dash-admin-titre-btn-container mb-4">
        <div className="workcare-dash-admin-page-content-container">
          <div className="terranga-auto-dash-admin-titre-btn-container mb-4">
            <div className="workcare-dash-admin-titre-container">
              <h1 className="terranga-auto-dash-admin-titre">Newsletter</h1>
            </div>
            <button
              className="terranga-auto-dash-admin-btn-ajout-deux"
              data-bs-toggle="modal"
              data-bs-target={`#newsletter-add`}
            >
              <AiOutlinePlusSquare className="terranga-auto-dash-admin-btn-ajout-icone" />{" "}
              Ajouter un email
            </button>
            <div
              className="modal fade"
              id={"newsletter-add"}
              aria-labelledby="AjouterAdministrateurModalLabel"
              aria-hidden="true"
            >
              <AjouterNewsletterModal modalId={"newsletter-add"} />
            </div>
          </div>
          <NewsletterTable />
        </div>
      </div>
    </div>
  );
}

export default NewslatterAdmin;
