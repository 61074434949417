import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import { FaTrash } from "react-icons/fa";
import { MdEdit } from "react-icons/md";
import { UserInfos } from "../../../../../types";
import { useDeleteItem } from "../../../../utils/api/hooks";
import { deleteUserFunction } from "../../../../utils/api/user.api";
import { getAvatar } from "../../../../utils/data";
import { CommonTable } from "../../../common/Input";
import { ToggleUserActive } from "../../concessionnaires/Tables/ConcessionnairesAdminTable";
import AjouterAdministrateurModal from "../Modales/AjouterAdministrateurModal";
type ParametresAdminTableProps = {
  admins: UserInfos[];
};
function ParametresAdminTable({ admins }: ParametresAdminTableProps) {
  const onDelete = useDeleteItem(
    deleteUserFunction,
    `Êtes-vous sûr de vouloir supprimer ce administrateur ?`,
    `Administrateur supprimé avec succés`,
    `Administrateur non supprimé`
  );

  const actionFormatter = (cell: string, row: UserInfos) => {
    if (row.is_super_admin) return null;
    const modalId = `UpdateAdminModal${row.uid}`;

    return (
      <div className="table-actions-btn-container-action gap-2 d-flex">
        <button
          className="btn btn-action-admin-modifier with-tooltip no-link"
          data-tooltip-content="Modifier"
          data-bs-toggle="modal"
          data-bs-target={`#${modalId}`}
        >
          <MdEdit />
        </button>
        <button
          className="btn btn-action-admin-supprimer with-tooltip"
          data-tooltip-content="Supprimer"
          onClick={() => {
            onDelete(row.uid);
          }}
        >
          <FaTrash />
        </button>
        <div
          className="modal fade"
          id={modalId}
          aria-labelledby="AjouterAdministrateurModalLabel"
          aria-hidden="true"
        >
          <AjouterAdministrateurModal item={row} modalId={modalId} />
        </div>
      </div>
    );
  };
  const nameAvatarFormatter = (cell: string, row: UserInfos) => {
    return (
      <div>
        <img
          src={getAvatar(row)}
          alt="user avatar"
          className="workcares-table-user-avatar"
        />{" "}
        {row.prenom} {row.nom}
      </div>
    );
  };

  const columns = [
    {
      dataField: "nom",
      text: "Nom",
      headerStyle: () => {
        return { width: "200px", whiteSpace: "normal" };
      },
      formatter: nameAvatarFormatter,
    },
    {
      dataField: "email",
      text: "Email",
      headerStyle: () => {
        return { width: "250px", whiteSpace: "normal" };
      },
    },
    {
      dataField: "telephone",
      text: "Telephone",
    },
    {
      dataField: "disabled",
      text: "Activer ?",
      formatter: (cell: string, row: UserInfos) => (
        <ToggleUserActive item={row} />
      ),
    },
    {
      dataField: "action",
      text: "Actions",
      style: { textAlign: "center" },
      formatter: actionFormatter,
    },
  ];

  return <CommonTable keyField="uid" data={admins} columns={columns} />;
}

export default ParametresAdminTable;
