import React, { useState } from "react";
import { BsFillPersonFill } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import Logo from "../../assets/appImages/logo-login.png";
import { sendPasswordResetLinkAsync } from "../../utils/api/auth.api";
import { SWAL_DEFAULT_PROPS } from "../../utils/data";
import { useFormData } from "../../utils/form";
import { FormError, SubmitButton } from "./Login";
import "./Login.css";
type ForgotPasswordData = {
  email: string;
};

const ForgetPassword = () => {
  const navigate = useNavigate();
  const { register, errors, setErrors, onSubmitForm } =
    useFormData<ForgotPasswordData>(
      {
        email: "",
      },
      (Yup) => ({
        email: Yup.string().email().required().label("Email"),
      })
    );
  const [loading, setLoading] = useState(false);

  const onSubmit = React.useCallback(async (data: ForgotPasswordData) => {
    setLoading(true);
    try {
      await sendPasswordResetLinkAsync(data.email);
      Swal.fire({
        title: "Succès",
        text: "Un lien pour réinitialiser votre mot de passe a été envoyé avec succès, veuillez vérifier votre boîte de réception",
        icon: "success",
        ...SWAL_DEFAULT_PROPS,
      });
      navigate("/");
    } catch (ex) {
      setErrors({
        email: "L'utilisateur avec cet e-mail n'a pas été trouvé",
      });
    }
    setLoading(false);
  }, []);
  return (
    <div>
      <div className="empty-header">
        <img src={Logo} alt="Logo" />
      </div>
      <div className="container-page-login-app">
        <div className="auth-row">
          <div className="auth-col auth-right-side-col">
            <div className="auth-right-side-container">
              <div className="auth-form-container pb-4">
                <h2 className="title-auth-form-text pt-4 text-center">
                  Récupération du Mot de Passe
                </h2>
                <div className="p-4">
                  <h4 className="auth-form-message">
                    Une demande de réinitialisation de mot de passe va être
                    envoyé par e-mail
                  </h4>
                </div>
                <div className="mt-5">
                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                      onSubmitForm(onSubmit);
                    }}
                    id="auth-form"
                  >
                    <div className="row auth-form-row">
                      <div className="col-md-12 auth-input-col mb-3">
                        <div className="auth-form-group position-relative">
                          <span className="icon-connect-form-login">
                            <BsFillPersonFill />
                          </span>
                          <input
                            type={`email`}
                            className="form-control auth-form-control"
                            placeholder="Identifiant"
                            aria-label="Username"
                            {...register("email")}
                          />
                        </div>
                        <FormError error={errors.email} />
                      </div>

                      <div className="col-md-8 offset-md-2 auth-submit-btn-container mt-5">
                        <SubmitButton
                          title="Reinitialiser le mot de passe"
                          loading={loading}
                        />
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgetPassword;
