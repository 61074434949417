import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import { FaEdit, FaTrash } from "react-icons/fa";
import { Newsleter } from "../../../../../types";
import { useAppSelector } from "../../../../redux/root";
import { useDeleteItem } from "../../../../utils/api/hooks";
import { deleteNewsletter } from "../../../../utils/api/user.api";
import { CommonTable } from "../../../common/Input";
import AjouterNewsletterModal from "../Modales/AjouterNewsletterModal";

function NewsletterTable() {
  const data = useAppSelector((s) => s.newsletter);

  const onDelete = useDeleteItem(
    (id) => deleteNewsletter(id),
    `Êtes-vous sûr de vouloir supprimer?`,
    `Email supprimé avec succés`,
    `Email non supprimé`
  );

  const actionFormatter = (cell: string, row: Newsleter) => {
    const modalId = `NewsletterUpdate${row.uid}`;
    return (
      <div className="table-actions-btn-container-action gap-2 d-flex">
        <button
          className="btn btn-action-admin-voir  with-tooltip"
          data-tooltip-content="Modifier"
          data-bs-toggle="modal"
          data-bs-target={`#${modalId}`}
        >
          <FaEdit />
        </button>
        <button
          className="btn btn-action-admin-supprimer with-tooltip"
          data-tooltip-content="Supprimer"
          onClick={() => {
            onDelete(row.uid);
          }}
        >
          <FaTrash />
        </button>

        <div
          className="modal fade"
          id={modalId}
          aria-labelledby="AjouterAdministrateurModalLabel"
          aria-hidden="true"
        >
          <AjouterNewsletterModal item={row} modalId={modalId} />
        </div>
      </div>
    );
  };

  const columns = [
    {
      dataField: "email",
      text: "Email",
      headerStyle: () => {
        return { width: "250px", whiteSpace: "normal" };
      },
    },
    {
      dataField: "action",
      text: "Actions",
      style: { textAlign: "center" },
      formatter: actionFormatter,
    },
  ];

  return <CommonTable keyField="uid" data={data} columns={columns} />;
}

export default NewsletterTable;
