import { useState } from "react";
import {
  useExecuteImportPriseEnCharge,
  useModalActions,
  validatePriseEnChargeFile,
  xlsxToPriseEnChargeJson,
} from "../../../../utils/data";
import {
  BtnAnnulerModal,
  BtnCloseModal,
  BtnSubmit,
} from "../../../common/Button";
import { Input } from "../../../common/Input";

type ImporterPriseEnChargeModalProps = {
  modalId?: string;
};

function ImporterPriseEnChargeModal({
  modalId,
}: ImporterPriseEnChargeModalProps) {
  const { exec, message } = useExecuteImportPriseEnCharge();
  const ma = useModalActions(modalId ?? "");
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState<File>();
  const onSubmit = async (file: File) => {
    setLoading(true);
    try {
      const jsonData = await xlsxToPriseEnChargeJson(file);
      if (jsonData && (await validatePriseEnChargeFile(jsonData))) {
        await exec(jsonData);
        const form = document.getElementById(
          "import-form"
        ) as HTMLFormElement | null;
        if (form) {
          form.reset();
        }
        ma.hide();
      }
    } catch (ex) {}
    setLoading(false);
  };

  return (
    <div className="modal-dialog modal-lg">
      <div className="modal-content custom-modal-content">
        <div className="modal-header custom-modal-header px-2 py-1">
          <h5 className="modal-title" id="AddTagModalLabel">
            Importer un fichier excel
          </h5>
          <div className="workcares-btn-close-modal-container">
            <BtnCloseModal label="" />
          </div>
        </div>
        <div className="modal-body">
          <div className="container-form">
            <form
              id="import-form"
              onSubmit={(e) => {
                e.preventDefault();
                if (file) {
                  onSubmit(file);
                }
              }}
            >
              <div className="row">
                <div className="col-md-6 offset-md-6">
                  <div className="mb-3 mt-5 flex-r gap-3">
                    <button
                      type="button"
                      className="btn auth-submit-btn"
                      onClick={() => {
                        window.open(
                          "/Jambaars auto - modèle d'imports de prise en charge.xlsx",
                          "_blank"
                        );
                      }}
                      style={{ padding: "5px 10px", fontSize: 14 }}
                    >
                      Télécharger le modèle
                    </button>
                  </div>
                </div>
                <div className="col-md-12">
                  <Input
                    type="file"
                    label="Image"
                    id={"import-file"}
                    name={"import-file"}
                    accept=".xlsx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    onChange={(e) => {
                      if (e.target.files) {
                        setFile(e.target.files[0]);
                      }
                    }}
                    data-testid="image"
                    placeholder="image"
                  />
                </div>
              </div>
              {!!message && (
                <div className="col-md-12">
                  <div className="alert alert-info">{message}</div>
                </div>
              )}
              <div className="col-md-6 offset-md-6">
                <div className="mb-3 mt-5 flex-r gap-3">
                  <BtnAnnulerModal label={"Fermer"} isLoading={false} />
                  <BtnSubmit
                    label={"Importer"}
                    isLoading={loading}
                    style={{ padding: "5px 10px", fontSize: 14 }}
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ImporterPriseEnChargeModal;
