import { UserFormData, UserInfos } from "../../../../../types";
import { useCreateOwner } from "../../../../utils/api/hooks";
import { useModalActions } from "../../../../utils/data";
import { useFormData } from "../../../../utils/form";
import { FormError } from "../../../auth/Login";
import {
  BtnAnnulerModal,
  BtnCloseModal,
  BtnSubmit,
} from "../../../common/Button";
import { Input } from "../../../common/Input";
import GooglePlaceInput from "../../../shared/GooglePlace";
type AjouterProprietaireModalProps = {
  modalId?: string;
  item?: UserInfos;
};
function AjouterProprietaireModal({
  modalId,
  item,
}: AjouterProprietaireModalProps) {
  const ma = useModalActions(modalId ?? "");

  const { errors, onChange, data, onSubmitForm, register } =
    useFormData<UserFormData>(
      {
        email: item?.email || "",
        prenom: item?.prenom || "",
        nom: item?.nom || "",
        telephone: item?.telephone || "",
        address: item?.address || "",
        part_propritaire: item?.part_propritaire || 55,
        nomSociete: "",
      },
      (Yup) => ({
        email: Yup.string().email().required().label("Email"),
        prenom: Yup.string().required().label("Prénom"),
        nom: Yup.string().required().label("Nom"),
        telephone: Yup.string().required().label("Téléphone"),
        address: Yup.string().required().label("Adresse"),
        nomSociete: Yup.string().notRequired().label("Raison sociale"),
        part_propritaire: Yup.number()
          .min(0)
          .max(100)
          .required()
          .label("Part propriétaires"),
      })
    );

  const { loading, onAdd } = useCreateOwner(() => {
    ma.hide();
    setTimeout(() => {
      if (!item) {
        onChange({
          email: "",
          prenom: "",
          nom: "",
          telephone: "",
          address: "",
          part_propritaire: 55,
          nomSociete: "",
        });
      }
    }, 1500);
  }, item);
  return (
    <div className="modal-dialog modal-lg">
      <div className="modal-content custom-modal-content">
        <div className="modal-header custom-modal-header px-2 py-1">
          <h5 className="modal-title" id="AddTagModalLabel">
            {item ? "Modifier" : "Ajouter"} un propriétaire
          </h5>
          <div className="workcares-btn-close-modal-container">
            <BtnCloseModal label="" />
          </div>
        </div>
        <div className="modal-body">
          <div className="container-form">
            <form
              onSubmit={(e) => {
                e.preventDefault();
                onSubmitForm(onAdd);
              }}
            >
              <div className="row">
                <div className="col-md-6">
                  <Input
                    type="text"
                    label="Prénom"
                    placeholder="Prénom"
                    {...register("prenom")}
                  />
                  <FormError error={errors.prenom} />
                </div>
                <div className="col-md-6">
                  <Input
                    type="text"
                    label="Nom"
                    placeholder="Nom"
                    {...register("nom")}
                  />
                  <FormError error={errors.nom} />
                </div>
                <div className="col-md-6">
                  <Input
                    type="phone"
                    label="Téléphone"
                    placeholder="Téléphone"
                    {...register("telephone")}
                  />
                  <FormError error={errors.telephone} />
                </div>
                <div className="col-md-6">
                  <Input
                    type="email"
                    label="Email"
                    placeholder="Email"
                    readOnly={!!item}
                    {...register("email")}
                  />
                  <FormError error={errors.email} />
                </div>
                <div className="col-md-6">
                  <GooglePlaceInput
                    onChange={(data) => {
                      onChange(data);
                    }}
                    value={data.address ?? ""}
                    className=""
                    placeholder="Adresse"
                  />
                  <FormError error={errors.address} />
                </div>
                <div className="col-md-6">
                  <Input
                    type="number"
                    label="Part propriétaires sur 100"
                    placeholder="Part propriétaires ex: 50"
                    {...register("part_propritaire")}
                  />
                  <FormError error={errors.part_propritaire as any} />
                </div>
                <div className="col-md-6">
                  <Input
                    type="text"
                    label="Raison sociale"
                    placeholder="Raison sociale"
                    {...register("nomSociete")}
                  />
                  <FormError error={errors.nomSociete} />
                </div>
                <div className="col-md-6 offset-md-6">
                  <div className="mb-3 mt-5 flex-r gap-3">
                    <BtnAnnulerModal label={"Fermer"} isLoading={false} />
                    <BtnSubmit
                      label={item ? "Modifier" : "Ajouter"}
                      isLoading={loading}
                      style={{ padding: "5px 10px", fontSize: 14 }}
                    />
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AjouterProprietaireModal;
