import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Content } from "../../../types";

const initialState: Content = {
  ptc: "",
  cgu: "",
};

export const ContentSlice = createSlice({
  name: "content",
  initialState,
  reducers: {
    onGetContent: (state, action: PayloadAction<Content>) => {
      state = action.payload;
      return state;
    },
  },
});

export const { onGetContent } = ContentSlice.actions;

export default ContentSlice.reducer;
