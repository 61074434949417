import { DriverFormData } from "../../../../../types";
import { useCreateClient } from "../../../../utils/api/hooks";
import {
  getFormattedPhoneNumber,
  isPhoneValidPhoneNumber,
} from "../../../../utils/data";
import { useFormData } from "../../../../utils/form";
import { FormError } from "../../../auth/Login";
import { BtnSubmit } from "../../../common/Button";
import { Input } from "../../../common/Input";
import GooglePlaceInput from "../../../shared/GooglePlace";
type AjouterUtilisateurModalProps = {
  modalId?: string;
  userId?: string;
  onAddItem: () => void;
};
function AjouterUtilisateurModal({ onAddItem }: AjouterUtilisateurModalProps) {
  const { errors, onChange, data, setErrors, onSubmitForm, register } =
    useFormData<DriverFormData>(
      {
        email: "",
        prenom: "",
        nom: "",
        telephone: "",
        address: "",
        nomSociete: "",
      },
      (Yup) => ({
        email: Yup.string().email().notRequired().label("Email"),
        prenom: Yup.string().required().label("Prénom"),
        nom: Yup.string().required().label("Nom"),
        telephone: Yup.string().required().label("Téléphone"),
        address: Yup.string().required().label("Lieu utilisation"),
        nomSociete: Yup.string().required().label("Nom Société"),
      })
    );

  const { loading, onAdd } = useCreateClient(() => {
    setTimeout(() => {
      onChange({
        email: "",
        prenom: "",
        nom: "",
        telephone: "",
        address: "",
        nomSociete: "",
      });
      onAddItem();
    }, 1500);
  });

  return (
    <div
      className="container-form px-4 py-4"
      style={{ backgroundColor: "#EEE" }}
    >
      <h4>Ajouter un client</h4>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          onSubmitForm((data) => {
            const newData = { ...data };
            if (isPhoneValidPhoneNumber(data.telephone)) {
              newData.telephone = getFormattedPhoneNumber(
                newData.telephone ?? ""
              )?.international;
              onAdd(newData);
            } else {
              setErrors({ telephone: "Numéro de téléphone invalid" });
            }
          });
        }}
      >
        <div className="row">
          <div className="col-md-6">
            <Input
              type="text"
              label="Prénom"
              placeholder="Prénom"
              {...register("prenom")}
            />
            <FormError error={errors.prenom} />
          </div>
          <div className="col-md-6">
            <Input
              type="text"
              label="Nom"
              placeholder="Nom"
              {...register("nom")}
            />
            <FormError error={errors.nom} />
          </div>
          <div className="col-md-6">
            <Input
              type="text"
              label="Téléphone"
              placeholder="Téléphone"
              {...register("telephone")}
            />
            <FormError error={errors.telephone} />
          </div>
          <div className="col-md-6">
            <Input
              type="text"
              label="Nom Société"
              placeholder="Nom Société"
              {...register("nomSociete")}
            />
            <FormError error={errors.nomSociete} />
          </div>
          <div className="col-md-6">
            <Input
              type="email"
              label="Email"
              placeholder="Email"
              {...register("email")}
            />
            <FormError error={errors.email} />
          </div>
          <div className="col-md-6">
            <GooglePlaceInput
              onChange={(data) => {
                onChange(data);
              }}
              value={data.address ?? ""}
              className=""
              placeholder="Lieu utilisation"
            />
            <FormError error={errors.address} />
          </div>
          <div className="col-md-6 offset-md-6">
            <div className="mt-2 flex-r gap-3">
              <button
                className="btn custom-btn-annuler-modale"
                type="button"
                onClick={onAddItem}
              >
                Annuler
              </button>
              <BtnSubmit
                label={"Ajouter"}
                isLoading={loading}
                style={{ padding: "5px 10px", fontSize: 14 }}
              />
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}

export default AjouterUtilisateurModal;
