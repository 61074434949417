import { useState } from "react";
import { Invoice, InvoiceFormData } from "../../../../../types";
import {
  useAddInvoice,
  useUsersForInvoices,
} from "../../../../utils/api/hooks";
import { useModalActions } from "../../../../utils/data";
import { useFormData } from "../../../../utils/form";
import { FormError } from "../../../auth/Login";
import {
  BtnAnnulerModal,
  BtnCloseModal,
  BtnSubmit,
} from "../../../common/Button";
import { Input, Select } from "../../../common/Input";
type AjouterInvoiceModalProps = {
  modalId?: string;
  userId?: string;
  item?: Invoice;
};
function AjouterInvoiceModal({
  modalId,
  userId,
  item,
}: AjouterInvoiceModalProps) {
  const ma = useModalActions(modalId ?? "");

  const users = userId ? [] : useUsersForInvoices();

  const { errors, onChange, onSubmitForm, register } =
    useFormData<InvoiceFormData>(
      {
        title: item?.title || "",
        amount: item?.amount || "",
        paid: item?.paid || false,
        userId: item?.userId || userId || "",
      },
      (Yup) => ({
        title: Yup.string().required().label("Libelle"),
        amount: Yup.string().required().label("Montant"),
        fichier: !item
          ? Yup.mixed().required().label("Facture")
          : Yup.mixed().notRequired(),
        userId: Yup.string().required().label("Destinataire"),
      })
    );

  const { loading, onAdd } = useAddInvoice(() => {
    ma.hide();
    if (!item) {
      setTimeout(() => {
        if (userId) {
          onChange({
            title: "",
            amount: "",
          });
        } else {
          onChange({
            title: "",
            userId: "",
            amount: "",
          });
        }
      }, 1500);
    }
  });

  const [imgId] = useState(`${new Date().getTime()}_${Math.random()}`);

  return (
    <div className="modal-dialog modal-lg">
      <div className="modal-content custom-modal-content">
        <div className="modal-header custom-modal-header px-2 py-1">
          <h5 className="modal-title" id="AddTagModalLabel">
            {item ? "Modifier une facture" : "Ajouter une facture"}
          </h5>
          <div className="workcares-btn-close-modal-container">
            <BtnCloseModal label="" />
          </div>
        </div>
        <div className="modal-body">
          <div className="container-form">
            <form
              onSubmit={(e) => {
                e.preventDefault();
                onSubmitForm((data) => {
                  onAdd(item?.uid, data);
                });
              }}
            >
              <div className="row">
                <div className="col-md-6">
                  <Input
                    type="text"
                    label="Libelle"
                    placeholder="Libelle"
                    {...register("title")}
                  />
                  <FormError error={errors.title} />
                </div>
                <div className="col-md-6">
                  <Input
                    type="text"
                    label="Montant"
                    placeholder="Montant"
                    {...register("amount")}
                  />
                  <FormError error={errors.amount} />
                </div>
                <div className="col-md-12">
                  <div className="mb-3">
                    <Input
                      type="file"
                      label="Facture"
                      id={imgId}
                      name={imgId}
                      accept=".pdf, .doc, .docx"
                      onChange={(e) => {
                        if (e.target.files) {
                          // @ts-ignore
                          onChange("fichier", e.target.files[0]);
                        }
                      }}
                      data-testid="image"
                      placeholder="image"
                    />
                  </div>
                </div>
                {!userId && (
                  <div className="col-md-12">
                    <div className="mb-3">
                      <Select
                        type="text"
                        label="Destinataire"
                        placeholder="Destinataire"
                        data={users.map((u) => ({
                          label: `${u.prenom} ${u.nom} - ${u.telephone}`,
                          value: u.uid,
                        }))}
                        {...register("userId")}
                      />
                      <FormError error={errors.userId} />
                    </div>
                  </div>
                )}
                <div className="col-md-6 offset-md-6">
                  <div className="mb-3 mt-5 flex-r gap-3">
                    <BtnAnnulerModal label={"Fermer"} isLoading={false} />
                    <BtnSubmit
                      label={item ? "Modifier" : "Ajouter"}
                      isLoading={loading}
                      style={{ padding: "5px 10px", fontSize: 14 }}
                    />
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AjouterInvoiceModal;
