import moment from "moment";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import { AiFillEye } from "react-icons/ai";
// import { FaTrash } from "react-icons/fa";
import { RequestOwner } from "../../../../../types";
import { useOwnerPendingRequests } from "../../../../utils/api/hooks";
import { CommonTable } from "../../../common/Input";
import AcceptRequestModal from "../../Parametres/Modales/AcceptRequestModal";

function PendingPriseEnChargeProprietaireTable({ userId }: { userId: string }) {
  const requests = useOwnerPendingRequests(userId);

  const actionFormatter = (cell: string, row: RequestOwner) => {
    const modalId = `AjouterAdministrateurModal${row.uid}`;
    return (
      <div className="table-actions-btn-container-action gap-2 d-flex">
        <button
          className="terranga-auto-dash-admin-btn-ajout-deux"
          data-bs-toggle="modal"
          data-bs-target={`#${modalId}`}
        >
          <AiFillEye />
        </button>
        <div
          className="modal fade"
          id={modalId}
          aria-labelledby="AjouterAdministrateurModalLabel"
          aria-hidden="true"
        >
          <AcceptRequestModal item={row} modalId={modalId} />
        </div>
      </div>
    );
  };
  const chargeFormatter = (cell: string, row: RequestOwner) => {
    return (
      <div>
        <img
          src={row.image}
          alt="car img"
          className="terranga-auto-table-img-car"
        />{" "}
        {row.identifier}
      </div>
    );
  };

  const AddressFormatter = (cell: string, row: RequestOwner) => {
    return (
      <div>
        {row.location.from.ville} {"=>"} {row.location.to.ville}
      </div>
    );
  };

  const columns = [
    {
      dataField: "uid",
      text: "Prise en charge",
      formatter: chargeFormatter,
      headerStyle: () => {
        return { width: "250px", whiteSpace: "normal" };
      },
    },
    {
      dataField: "location",
      text: "Localisation",
      formatter: AddressFormatter,
      headerStyle: () => {
        return { width: "250px", whiteSpace: "normal" };
      },
    },
    {
      dataField: "date",
      text: "Date",
      formatter: (cell: string) => moment(cell).format("DD/MM/YYYY"),
    },
    {
      dataField: "action",
      text: "Actions",
      style: { textAlign: "center" },
      formatter: actionFormatter,
    },
  ];

  return <CommonTable keyField="uid" data={requests} columns={columns} />;
}

export default PendingPriseEnChargeProprietaireTable;
