import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Client } from "../../../types";

export type State = Client[];

const initialState: State = [];

export const ClientsSLice = createSlice({
  name: "clients",
  initialState,
  reducers: {
    onGetClients: (state, action: PayloadAction<State>) => {
      return action.payload;
    },
  },
});

export const { onGetClients } = ClientsSLice.actions;

export default ClientsSLice.reducer;
