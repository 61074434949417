import Fade from "react-reveal/Fade";
import { useAppSelector } from "../../redux/root";

const Faq = () => {
  const faq = useAppSelector((s) => s.faq);
  return (
    <section className="section-aprops-homepage position-relative">
      <div className="container-app-landing-page">
        <div className="content-title-banner-about-us mb-5">
          <div className="content-title pb-3">
            <Fade bottom cascade>
              <div className="text-center pb-3">
                <span className="title-theme-app">FAQ</span>
              </div>
            </Fade>
            <Fade bottom cascade>
              {" "}
              <h2 className="title-section-theme-app">question fréquentes</h2>
            </Fade>
          </div>
          {/* <Fade bottom cascade>
            <div className="w-50 m-auto">
              <p className="text-about-service-item">
                SaasUp is the perfect template, there are a lot of important
                features
              </p>
            </div>
          </Fade> */}
        </div>
        <div className="container-faq w-70 ms-auto me-auto">
          <section id="section1">
            <Fade bottom>
              <>
                {faq.map((item) => (
                  <div key={item.uid} className="mb-3">
                    <a
                      data-bs-toggle="collapse"
                      href={`#${item.uid}`}
                      role="button"
                      aria-expanded="false"
                      aria-controls={item.uid}
                      className="btn btn-collapse-faq with-chevron"
                    >
                      <p className="title-collapse-faq mb-0">
                        {item.question}
                        <i className="fa fa-angle-down"></i>
                      </p>
                    </a>
                    <div
                      id={item.uid}
                      className="collapse content-collapse-faq show"
                    >
                      <p className="text-show-collapse-faq mb-0">
                        {item.response}
                      </p>
                    </div>
                  </div>
                ))}
              </>
            </Fade>
          </section>
        </div>
      </div>
    </section>
  );
};
export default Faq;
