import { useEffect, useState } from "react";
import { AiOutlinePlusSquare } from "react-icons/ai";
import { UserInfos } from "../../../../types";
import { useAppSelector } from "../../../redux/root";
import { useConcessionnairesAdmin } from "../../../utils/api/hooks";
import AjouterAdministrateurModal from "../../admin/Parametres/Modales/AjouterAdministrateurModal";
import ParametresAdminTable from "../../admin/Parametres/Tables/ParametresAdminTable";

function ParametresConcessionnaire() {
  const userId = useAppSelector(
    (s) => s.auth.userInfos?.concessionnaireId || s.auth.userInfos?.uid
  );
  const users = useConcessionnairesAdmin(userId);
  const [admins, setAdmins] = useState<UserInfos[]>([]);
  const [search, setSearch] = useState("");
  const modalId = "AjouterAdministrateurModal";
  useEffect(() => {
    const s = search.trim().toLowerCase();
    if (s) {
      setAdmins(
        users.filter((u) => `${u.prenom} ${u.nom}`.toLowerCase().includes(s))
      );
    } else {
      setAdmins(users);
    }
  }, [search, users]);
  return (
    <div className="dashbord-visiteur-component">
      <div className="terranga-auto-dash-admin-titre-btn-container mb-4">
        <div className="workcare-dash-admin-titre-container">
          <h1 className="terranga-auto-dash-admin-titre">Paramètres</h1>
        </div>
        <button
          className="terranga-auto-dash-admin-btn-ajout-deux"
          data-bs-toggle="modal"
          data-bs-target={`#${modalId}`}
        >
          <AiOutlinePlusSquare className="terranga-auto-dash-admin-btn-ajout-icone" />{" "}
          Ajouter un administrateur
        </button>
        <div
          className="modal fade"
          id={modalId}
          aria-labelledby="AjouterAdministrateurModalLabel"
          aria-hidden="true"
        >
          <AjouterAdministrateurModal modalId={modalId} />
        </div>
      </div>
      <div className="workcare-dash-admin-page-content-container">
        <div className="locataire-requete-filtre-container mb-4">
          <form className="row g-3 locataire-requete-filtre-form">
            <div className="col-auto">
              <label
                htmlFor="filtreLogement"
                className="locataire-requete-filtre-label"
              >
                Filtrer
              </label>
            </div>
            <div className="col-auto">
              <input
                type="text"
                className="form-control locataire-requete-filtre-input"
                id="filtreLogement"
                placeholder="Entrer un nom"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
            </div>
          </form>
        </div>
        <ParametresAdminTable admins={admins} />
      </div>
    </div>
  );
}

export default ParametresConcessionnaire;
