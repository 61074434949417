import Fade from "react-reveal/Fade";
import Dots from "../../assets/landingpage/dots.png";
import LocationIcon from "../../assets/landingpage/location.png";
import ShareIcon from "../../assets/landingpage/share.png";
import UserIcon from "../../assets/landingpage/user.png";
type AboutProps = {
  title: string;
  id: string;
  bg: string;
  subTitle: string;
  description?: string;
  items: { image?: string | number; title: string; theme?: string }[];
};

export function AboutUsComponent() {
  return (
    <AboutUs
      title="APPLICATION"
      subTitle="Nos fonctionnalités"
      description="Découvrez toutes nos fonctionnalités qui vous permettent de
vivre une expérience unique et sur-mesure."
      bg={"rgba(235, 241, 248, 0.45)"}
      id="a-propos"
      items={[
        { title: "Localisez votre chauffeur", image: LocationIcon },
        { title: "Vérifiez l'identité de votre chauffeur", image: UserIcon },
        { title: "Partagez votre trajet", image: ShareIcon },
      ]}
    />
  );
}

export function WhyUseComponent() {
  return (
    <AboutUs
      title="Pourquoi Jambaars?"
      subTitle="Pourquoi utiliser Jambaars Auto?"
      bg={"#FFF"}
      id="pourquoi"
      items={[
        {
          title:
            "La demande du client est toujours satisfaite (via l'application et le centre d'appel)",
        },
        {
          title:
            "Le client peut facilement choisir le trajet et le chauffeur à disposition",
        },
        {
          title:
            "Le propriétaire de la voiture de remorquage peut mieux suivre et contrôler son activité.",
        },
      ]}
    />
  );
}

export function CaracteristiquesComponent() {
  return (
    <AboutUs
      title="Caractéristiques de l’application"
      subTitle="Découvrez les caractéristiques de l’application"
      bg={"rgba(235, 241, 248, 0.45)"}
      id="pourquoi"
      items={[
        {
          theme: "Pratique",
          title:
            "L'application permet aux utilisateurs de demander une dépanneuse à tout moment, où qu'ils soient , en quelques clics sur leur smartphone.",
        },
        {
          theme: "Rapidité",
          title:
            " l'application met  rapidement  les utilisateurs en contact avec les dépanneurs à proximité, réduisant ainsi les temps d'attente.",
        },
        {
          theme: "Transparence",
          title:
            "L'application fournit aux utilisateurs des informations en temps réel sur l'état de leur dépanneuse, y compris une estimation de l'heure d'arrivée. Le propriétaire peut suivre son activité grâce au tableau de bord.",
        },
        {
          theme: "Réduction des coûts",
          title:
            "L'application permet  aux utilisateurs de comparer les prix de plusieurs fournisseurs de dépanneuses et de choisir l'option la moins chère.",
        },
        {
          theme: "Sécurité",
          title:
            "L'application permet aux utilisateurs de demander une dépanneuse en toute sécurité, puisqu'ils n'ont pas besoin de s'arrêter sur le bas-côté de la route pour passer un appel.",
        },
        {
          theme: "Paiement",
          title:
            "Avec l'application, payer en toute sécurité avec votre carte bancaire ou avec le mobile money de votre choix",
        },
      ]}
    />
  );
}
const AboutUs = ({
  bg,
  id,
  title,
  subTitle,
  description,
  items,
}: AboutProps) => {
  return (
    <section
      className="section-aprops-homepage position-relative"
      style={{ backgroundColor: bg }}
      id={id}
    >
      <div className="container-app-landing-page">
        <div className="content-img-dots-1">
          <img src={Dots} alt="Dots" className="img-dots-1" />
        </div>
        <div className="content-title-banner-about-us mb-5">
          <div className="content-title pb-3">
            <Fade bottom cascade>
              <div className="text-center pb-3">
                <span className="title-theme-app">{title}</span>
              </div>
            </Fade>
            <Fade bottom cascade>
              <h2 className="title-section-theme-app">{subTitle}</h2>
            </Fade>
          </div>
          {!!description && (
            <Fade bottom cascade>
              <div className="w-50 m-auto">
                <p className="text-about-service-item">{description}</p>
              </div>
            </Fade>
          )}
        </div>
        <div className="row row-about-us">
          {items.map((item, index) => (
            <div
              key={index}
              className="col-md-4 col-about-us-section d-flex mb-3"
            >
              <Fade bottom cascade>
                <div
                  style={{
                    backgroundColor:
                      bg === "#FFF" ? "rgba(235, 241, 248, 0.45)" : "#FFF",
                  }}
                  className="content-col-about-us-section w-100"
                >
                  <div className="info-about-us-section">
                    {!!item.theme && (
                      <div className="content-img-icon-about-us text-center pb-4">
                        <h4>{item.theme}</h4>
                      </div>
                    )}
                    {!!item.image && (
                      <div className="content-img-icon-about-us text-center pb-4">
                        <img
                          src={item.image as string}
                          alt="Location"
                          className="img-icon-about-us"
                        />
                      </div>
                    )}
                    <p className="title-about-us-section mb-0">{item.title}</p>
                  </div>
                </div>
              </Fade>
            </div>
          ))}
        </div>
        <div className="content-img-dots-2">
          <img src={Dots} alt="Dots" className="img-dots-2" />
        </div>
      </div>
    </section>
  );
};
