import { configureStore } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import { UserInfos } from "../../types";
import { ClientsSLice } from "./slices/clients.slice";
import { OwnerDashboardSLice } from "./slices/dashboard-owner";
import { JourSlice } from "./slices/jour.slice";

import { MessageContactSlice } from "./slices/message-contact.slice";
import { OwnersSLice } from "./slices/owners.slice";
import { RequestSLice } from "./slices/request.slice";
import { TarifSlice } from "./slices/tarif.slice";

import { ContentSlice } from "./slices/content.slice";
import { FaqSlice } from "./slices/faq.slice";
import { InvoiceSlice } from "./slices/invoice.slice";
import { LandingPageSlice } from "./slices/landingPage.slice";
import { NewsletterSlice } from "./slices/newsletter.slice";
import { NotificationSlice } from "./slices/notification.slice";
import { userSlice } from "./slices/user.slice";
import { UsersSLice } from "./slices/users.slice";
import { VehiculesSLice } from "./slices/vehicule.slice";

const store = configureStore({
  reducer: {
    [UsersSLice.name]: UsersSLice.reducer,
    [TarifSlice.name]: TarifSlice.reducer,
    [LandingPageSlice.name]: LandingPageSlice.reducer,
    [JourSlice.name]: JourSlice.reducer,
    [ContentSlice.name]: ContentSlice.reducer,
    [FaqSlice.name]: FaqSlice.reducer,
    [NewsletterSlice.name]: NewsletterSlice.reducer,
    [OwnersSLice.name]: OwnersSLice.reducer,
    [MessageContactSlice.name]: MessageContactSlice.reducer,
    [RequestSLice.name]: RequestSLice.reducer,
    [ClientsSLice.name]: ClientsSLice.reducer,
    [OwnerDashboardSLice.name]: OwnerDashboardSLice.reducer,
    [userSlice.name]: userSlice.reducer,
    [VehiculesSLice.name]: VehiculesSLice.reducer,
    [InvoiceSlice.name]: InvoiceSlice.reducer,
    [NotificationSlice.name]: NotificationSlice.reducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>(); // Export a hook that can be reused to resolve types
export function useAppSelector<T>(cb: (root: RootState) => T) {
  return useSelector(cb);
}

export function useUser() {
  return useAppSelector((s) => s.auth.userInfos as UserInfos);
}
export default store;
