import React, { useState } from "react";
import { BsCameraFill } from "react-icons/bs";
import { FiEdit } from "react-icons/fi";
import Swal from "sweetalert2";
import { UserFormData } from "../../../../types";
import { useUser } from "../../../redux/root";
import { uploadUserAvatarAsync } from "../../../utils/api/storage.api";
import { updateUserAsync } from "../../../utils/api/user.api";
import { getAvatar } from "../../../utils/data";
import { useFormData } from "../../../utils/form";
import ChangePasswordModal from "../../admin/ProfileAdmin/ChangePasswordModal";
import { BtnSubmit } from "../../common/Button";
import { Input } from "../../common/Input";

function ProfileConcessionnaire() {
  const user = useUser();

  const [loading, setLoading] = useState(false);

  const [avatar, setAvatar] = useState(getAvatar(user));
  const [file, setFile] = useState<File>();

  const { onSubmitForm, register } = useFormData<UserFormData>(
    {
      nom: user.nom,
      prenom: user.prenom,
      telephone: user.telephone,
      address: user.address,
      nomSociete: user.nomSociete,
    },
    (Yup) => ({
      prenom: Yup.string().required().label("Prénom"),
      nom: Yup.string().required().label("Nom"),
      telephone: Yup.string().required().label("Téléphone"),
      address: Yup.string().required().label("Adresse"),
      nomSociete: Yup.string().required().label("Raison sociale"),
    })
  );
  const onSubmit = React.useCallback(
    async (data: UserFormData) => {
      try {
        setLoading(true);
        if (file) {
          data.avatar = await uploadUserAvatarAsync(file, user.uid);
          setFile(undefined);
        }
        await updateUserAsync(user.uid, data);
        setTimeout(() => {
          setTimeout(() => {
            Swal.fire({
              title: "Succès",
              text: " modification avec succès",
              icon: "success",
            });
            setLoading(false);
          }, 600);
        }, 400);
      } catch (ex) {
        const err = ex as { code: string };
        if (err) {
          Swal.fire({
            title: "Erreur",
            text: "Erreur modification",
            icon: "error",
          });
        } else {
          Swal.fire({
            title: "Erreur",
            text: "Erreur modification",
            icon: "error",
          });
        }
        setLoading(false);
      }
    },
    [file]
  );

  return (
    <div className="dashbord-visiteur-component">
      <div className="workcare-dash-admin-page-content-container mb-3">
        <div className="content-pp-profil-visiteur text-center mb-3">
          <div className="d-flex justify-content-center align-items-center mb-4 leader-auto-user-avatar-container">
            <label htmlFor="input-test" className="input-testt">
              <img
                src={avatar}
                alt="profil utilisateur"
                className="pp-profil-user-visiteur"
              />
              <div className="leader-auto-user-avatar-edit-hover">
                <BsCameraFill className="edit-avatar-camera-icon" />
              </div>
            </label>
            <input
              onChange={(e) => {
                if (e.target.files?.length) {
                  const file = e.target.files[0];
                  if (file) {
                    setFile(file);
                    setAvatar(URL.createObjectURL(file));
                  }
                }
              }}
              type="file"
              className="input-add-avatar"
              id="input-test"
            />
          </div>
          <div className="content-text-name-user">
            <h2 className="custom-name-user-admin">
              {user.prenom} {user.nom}
            </h2>
            <div className="custom-name-user-role">Concessionnaire</div>
          </div>
        </div>

        <div className="kanimmo-dash-locataire-param-main-page-container py-4 mb-3">
          <section className="mb-3">
            <div className="terranga-dash-admin-param-title mb-4">
              Informations générales
            </div>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                onSubmitForm(onSubmit);
              }}
            >
              <div className="row">
                <div className="col-md-6">
                  <div className="mb-3">
                    <Input type="text" label="Prénom" {...register("prenom")} />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mb-3">
                    <Input type="text" label="Nom" {...register("nom")} />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mb-3">
                    <Input
                      type="text"
                      label="Raison sociale"
                      {...register("nomSociete")}
                    />
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="mb-3">
                    <Input
                      type="text"
                      label="Adresse"
                      {...register("address")}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mb-3">
                    <Input
                      type="email"
                      label="Email"
                      {...register("email")}
                      placeholder={user.email}
                      readOnly
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mb-3">
                    <Input
                      type="text"
                      label="Téléphone"
                      {...register("telephone")}
                    />
                  </div>
                </div>
              </div>
              <div className="container-btn-modal row my-4">
                <div className="col-md-6 offset-md-6">
                  <div className="d-flex gap-3 justify-content-end">
                    <BtnSubmit
                      label="Enregistrer"
                      data-testid="submit-proprio"
                      isLoading={loading}
                    />
                  </div>
                </div>
              </div>
            </form>
          </section>
        </div>
      </div>
      <div className="workcare-dash-admin-page-content-container mb-3">
        <div className="kanimmo-dash-locataire-param-main-page-container py-4 mb-4">
          <div className="terranga-dash-admin-param-title mb-4">
            Modifier votre mot de passe
          </div>
          <div className="kanimmo-dash-locataire-param-password-container">
            <div className="kanimmo-dash-locataire-param-password-title">
              Mot de passe
            </div>
            <button
              className="btn auth-submit-btn w-50"
              data-bs-toggle="modal"
              data-bs-target="#ChangePasswordModal"
            >
              <FiEdit /> Changer de mot de passe
            </button>
          </div>
          <ChangePasswordModal />
        </div>
      </div>
    </div>
  );
}

export default ProfileConcessionnaire;
