import { OwnersUsersRequest, RequestOwner } from "../../../../../types";
import { useAssingRequest } from "../../../../utils/api/hooks";
import { useModalActions } from "../../../../utils/data";
import { BtnCloseModal } from "../../../common/Button";
import { CommonTable } from "../../../common/Input";

type AssignRequestModalProps = {
  item: RequestOwner;
  modalId: string;
};

function AssignRequestModal({ item, modalId }: AssignRequestModalProps) {
  const ma = useModalActions(modalId);
  const actionFormatter = (cell: string, row: OwnersUsersRequest) => {
    return (
      <AssingButton
        onAssign={() => {
          ma.hide();
        }}
        requestId={item.uid}
        item={row}
      />
    );
  };
  const chargeFormatter = (cell: string, row: OwnersUsersRequest) => {
    return (
      <div>
        {row.user?.prenom} {row?.user?.nom}
      </div>
    );
  };

  const AddressFormatter = (cell: string, row: OwnersUsersRequest) => {
    return <div>{row.user?.location?.ville || row.user?.ville}</div>;
  };

  const columns = [
    {
      dataField: "uid",
      text: "Chauffeur",
      formatter: chargeFormatter,
      headerStyle: () => {
        return { width: "250px", whiteSpace: "normal" };
      },
    },
    {
      dataField: "amount",
      text: "Tarif",
      formatter: (cell: string) => `${cell} FCFA`,
      headerStyle: () => {
        return { width: "250px", whiteSpace: "normal" };
      },
    },
    {
      dataField: "location",
      text: "Localisation",
      formatter: AddressFormatter,
      headerStyle: () => {
        return { width: "250px", whiteSpace: "normal" };
      },
    },
    {
      dataField: "action",
      text: "Actions",
      style: { textAlign: "center" },
      formatter: actionFormatter,
    },
  ];

  return (
    <div className="modal-dialog modal-xl">
      <div className="modal-content custom-modal-content">
        <div className="modal-header custom-modal-header px-2 py-1">
          <h5 className="modal-title" id="AddTagModalLabel">
            {item.location.from.ville}
            {" => "}
            {item.location.to.ville}
          </h5>
          <div className="workcares-btn-close-modal-container">
            <BtnCloseModal label="" />
          </div>
        </div>
        <div className="modal-body">
          <CommonTable keyField="uid" data={item.users} columns={columns} />
        </div>
      </div>
    </div>
  );
}

export default AssignRequestModal;

function AssingButton({
  item,
  requestId,
  onAssign,
}: {
  item: OwnersUsersRequest;
  requestId: string;
  onAssign: () => void;
}) {
  const { loading, onAdd } = useAssingRequest(() => {
    setTimeout(() => {
      onAssign();
    }, 1000);
  });
  if (!item.user) return null;
  return (
    <div className="table-actions-btn-container-action gap-2 d-flex">
      <>
        {loading && (
          <button className="terranga-auto-dash-admin-btn-ajout-deux">
            Assignation...
          </button>
        )}
        {!loading && (
          <button
            onClick={() =>
              onAdd({
                requestId: requestId,
                driverId: item.user?.uid,
              })
            }
            className="terranga-auto-dash-admin-btn-ajout-deux"
          >
            Assigner
          </button>
        )}
      </>
    </div>
  );
}
