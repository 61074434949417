import { Newsleter, NewsleterFormData } from "../../../../../types";
import { useAddNewsletter } from "../../../../utils/api/hooks";
import { useModalActions } from "../../../../utils/data";
import { useFormData } from "../../../../utils/form";
import {
  BtnAnnulerModal,
  BtnCloseModal,
  BtnSubmit,
} from "../../../common/Button";
import { FormError, Input } from "../../../common/Input";

type AjouterNewsletterModalProps = {
  modalId?: string;
  item?: Newsleter;
};
function AjouterNewsletterModal({
  modalId,
  item,
}: AjouterNewsletterModalProps) {
  const ma = useModalActions(modalId ?? "");

  const { errors, onChange, onSubmitForm, register } =
    useFormData<NewsleterFormData>(
      {
        email: item?.email ?? "",
      },
      (Yup) => ({
        email: Yup.string().email().required().label("Email"),
      })
    );

  const { loading, onAdd } = useAddNewsletter(() => {
    ma.hide();
    setTimeout(() => {
      if (!item) {
        onChange({
          email: "",
        });
      }
    }, 1500);
  });
  return (
    <div className="modal-dialog modal-lg">
      <div className="modal-content custom-modal-content">
        <div className="modal-header custom-modal-header px-2 py-1">
          <h5 className="modal-title" id="AddTagModalLabel">
            {item ? "Modifier" : "Ajouter"} un email
          </h5>
          <div className="workcares-btn-close-modal-container">
            <BtnCloseModal label="" />
          </div>
        </div>
        <div className="modal-body">
          <div className="container-form">
            <form
              onSubmit={(e) => {
                e.preventDefault();
                onSubmitForm((data) => onAdd(data, item));
              }}
            >
              <div className="row">
                <div className="col-md-12">
                  <Input
                    type="text"
                    label="Email"
                    placeholder="Email"
                    {...register("email")}
                  />
                  <FormError error={errors.email} />
                </div>
                <div className="col-md-6 offset-md-6">
                  <div className="mb-3 mt-5 flex-r gap-3">
                    <BtnAnnulerModal label={"Fermer"} isLoading={false} />
                    <BtnSubmit
                      label={item ? "Modifier" : "Ajouter"}
                      isLoading={loading}
                      style={{ padding: "5px 10px", fontSize: 14 }}
                    />
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AjouterNewsletterModal;
