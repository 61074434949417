import React, { InputHTMLAttributes, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import { FieldError } from "react-hook-form";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import ErrorMessage from "./ErrorMessage";

import ReactSelect from "react-select";

type InputPropsType = InputHTMLAttributes<HTMLInputElement> & {
  label: string;
  error?: string | FieldError;
};

type SelectPropsType = InputHTMLAttributes<HTMLSelectElement> & {
  label: string;
  error?: string | FieldError;
  data: SelectItem[];
};

export function FormError({ error }: { error?: string | FieldError }) {
  const message =
    typeof error === "string" ? error : error ? error.message : error;
  if (!message) return null;

  return <ErrorMessage message={message} />;
}

export const Input = React.forwardRef<HTMLInputElement, InputPropsType>(
  (props, ref) => {
    const { name, error, id, label, required, readOnly, ...rest } = props;
    return (
      <>
        <div className="mb-3" style={{ textAlign: "left" }}>
          <label
            htmlFor={id}
            className="form-label form-label-modal-custom"
            aria-labelledby={id}
          >
            {label}
            {required && (
              <span className="text-danger" style={{ fontSize: 24 }}>
                *
              </span>
            )}
          </label>
          <input
            className="form-control form-control-modal-custom"
            name={name}
            id={id}
            ref={ref}
            readOnly={readOnly || false}
            {...rest}
            onWheel={(event) => event.currentTarget.blur()}
          />
          {<FormError error={error} />}
        </div>
      </>
    );
  }
);

export function RSelect({
  error,
  id,
  label,
  required,
  data,
  onChange,
  value,
  name,
  placeholder,
}: SelectPropsType) {
  const [search, setSearch] = useState(value);
  const finded =
    data.filter((d) =>
      d.label.toLowerCase().includes(search?.toString()?.toLowerCase() ?? "")
    ).length > 0;
  const options = [
    ...data,
    ...(!!search && !finded ? [{ label: search, value: search }] : []),
  ] as any;
  const selectedOption = data.find((i) => i.value === value) ?? null;
  return (
    <>
      <div className="mb-3" style={{ textAlign: "left" }}>
        <label
          htmlFor={id}
          className="form-label form-label-modal-custom"
          aria-labelledby={id}
        >
          {label}
          {required && (
            <span className="text-danger" style={{ fontSize: 24 }}>
              *
            </span>
          )}
        </label>
        <ReactSelect
          value={selectedOption}
          onInputChange={(e) => setSearch(e)}
          className="form-label form-label-modal-custom"
          // @ts-ignore
          onChange={({ value }) =>
            onChange?.({ target: { value: value } } as any)
          }
          options={options}
          id={id}
          name={name}
          placeholder={placeholder}
        />
        <FormError error={error} />
      </div>
    </>
  );
}

type SelectItem = { label: string; value: string };

export const Select = React.forwardRef<HTMLSelectElement, SelectPropsType>(
  (props, ref) => {
    const {
      name,
      error,
      id,
      label,
      placeholder,
      required,
      data,
      value,
      onChange,
    } = props;
    const selectedOption = data.find((i) => i.value === value) ?? null;

    return (
      <>
        <div className="mb-3" style={{ textAlign: "left" }}>
          <label
            htmlFor={id}
            className="form-label form-label-modal-custom"
            aria-labelledby={id}
          >
            {label}
            {required && (
              <span className="text-danger" style={{ fontSize: 24 }}>
                *
              </span>
            )}
          </label>
          <ReactSelect
            value={selectedOption}
            className="form-label form-label-modal-custom"
            // @ts-ignore
            onChange={({ value }) =>
              onChange?.({ target: { value: value } } as any)
            }
            options={data as any}
            id={id}
            name={name}
            placeholder={placeholder}
          />
          {<FormError error={error} />}
        </div>
      </>
    );
  }
);

export const InputPassword = React.forwardRef<HTMLInputElement, InputPropsType>(
  (props, ref) => {
    const { name, error, id, label, required, readOnly, ...rest } = props;
    const [showPassword, setShowPassword] = useState<boolean>(false);
    return (
      <>
        <label
          htmlFor={id}
          className="form-label form-label-modal-custom"
          aria-labelledby={id}
        >
          {label}
          {required && (
            <span className="text-danger" style={{ fontSize: 24 }}>
              *
            </span>
          )}
        </label>
        <div className="input-group auto-custom-input-groupe mb-1">
          <input
            className="form-control form-control-modal-custom"
            name={name}
            id={id}
            ref={ref}
            readOnly={readOnly || false}
            type={showPassword ? "text" : "password"}
            {...rest}
          />
          <span
            className="input-group-text auto-custom-showHideIcon"
            id="basic-addon2"
            onClick={() => setShowPassword((showPassword) => !showPassword)}
          >
            {showPassword ? (
              <FaEye className="showPasswordIcon" />
            ) : (
              <FaEyeSlash className="hidePasswordIcon" />
            )}
          </span>
        </div>
        {<FormError error={error} />}
      </>
    );
  }
);

export function RequiredText() {
  return (
    <span className="text-danger" style={{ fontSize: 24 }}>
      *
    </span>
  );
}

const options = {
  sizePerPage: 10,
};
type CommonTableProps = {
  data?: any[];
  columns: any[];
  keyField?: string;
  noDataIndication?: () => string;
};
export function CommonTable({
  data,
  columns,
  keyField = "uid",
  noDataIndication,
}: CommonTableProps) {
  // if (data?.length === 0) {
  //   return (
  //     <div className="py-5 px-5" style={{ backgroundColor: "#EEE" }}>
  //       {noDataIndication ? noDataIndication() : "Pas de données"}
  //     </div>
  //   );
  // }
  return (
    <BootstrapTable
      keyField={keyField}
      data={data}
      columns={columns}
      bordered={true}
      condensed={false}
      noDataIndication={() =>
        noDataIndication ? noDataIndication() : "Pas de données"
      }
      responsive={true}
      wrapperClasses="table-responsive admin-table"
      pagination={paginationFactory(options)}
    />
  );
}

export function CommonTable2({
  data,
  columns,
  keyField = "uid",
  noDataIndication,
}: CommonTableProps) {
  const width = `${100 / columns.length}%`;
  const getStyles = (c) => {
    if (c.headerStyle) {
      const { headerStyle } = c;
      const styles =
        typeof headerStyle === "function" ? headerStyle() : headerStyle;
      return {
        width,
        whiteSpace: "normal",
        ...styles,
      };
    }
  };
  return (
    <table className="table">
      <thead>
        <tr>
          {columns.map((c, index) => (
            <th style={getStyles(c)} key={index}>
              {c.text}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {!data?.length && (
          <tr>
            <td colSpan={columns.length}>
              {noDataIndication ? noDataIndication() : "Pas de données"}
            </td>
          </tr>
        )}
        {data?.map((item, index) => {
          const key = item[keyField] || index;

          return (
            <tr key={key}>
              {columns.map((col, index) => {
                const col_key = `${key}_${index}`;
                const val = item[col.dataField];

                return (
                  <td style={getStyles(col)} key={col_key}>
                    {col.formatter ? col.formatter(val, item) : val}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}
