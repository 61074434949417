import { useCallback, useEffect, useState } from "react";
import { AiOutlinePlusSquare } from "react-icons/ai";
import { Vehicule } from "../../../../types";
import { useCamions } from "../../../utils/api/hooks";
import AjouterVehiculeModal from "../Chauffeurs/Modales/AjouterVehiculeModal";
import ImporterVehiculeModal from "../Chauffeurs/Modales/ImporterVehiculeModal";
import VehiculeProprietaireTable from "../Chauffeurs/Tables/VehiculeProprietaireTable";
import "./VehiculesAdmin.css";

const tabs = ["Mes camions", "Tout les camions"];

function VehiculesAdmin() {
  const vehicules = useCamions();
  const [index, setIndex] = useState(0);

  const go = useCallback((index: number) => {
    setIndex(index);
  }, []);

  const [filtred, setFiltred] = useState<Vehicule[]>([]);
  const [search, setSearch] = useState("");
  const modalId = "AjouterVehiculeModal";
  const modalImportId = "ImporterVehiculeModal";

  useEffect(() => {
    const s = search.trim().toLowerCase();
    if (s) {
      setFiltred(
        vehicules.filter(
          (u) =>
            `${u.matricule} ${u.annee} ${u.model} ${u.mode}`
              .toLowerCase()
              .includes(s) && (index === 0 ? !u.concessionnaireId : true)
        )
      );
    } else {
      setFiltred(
        vehicules.filter((u) => (index === 0 ? !u.concessionnaireId : true))
      );
    }
  }, [search, vehicules, index]);

  return (
    <div className="dashbord-visiteur-component">
      <div className="terranga-auto-dash-admin-titre-btn-container mb-4">
        <ul className="tabs-container mt-2 mb-4">
          {tabs.map((tab, key) => (
            <li
              className={`tab-item  ${index === key && "tab-active-item"}`}
              key={key}
              style={{ fontSize: 13 }}
              onClick={() => go(key)}
            >
              {tab}
            </li>
          ))}
        </ul>
        <div
          style={{
            flexDirection: "row",
            alignItems: "center",
            display: "flex",
            gap: 10,
            flexWrap: "wrap",
          }}
        >
          <button
            className="terranga-auto-dash-admin-btn-ajout-deux"
            data-bs-toggle="modal"
            data-bs-target={`#${modalImportId}`}
          >
            <AiOutlinePlusSquare className="terranga-auto-dash-admin-btn-ajout-icone" />{" "}
            Importer un fichier excel
          </button>
          <button
            className="terranga-auto-dash-admin-btn-ajout-deux"
            data-bs-toggle="modal"
            data-bs-target={`#${modalId}`}
          >
            <AiOutlinePlusSquare className="terranga-auto-dash-admin-btn-ajout-icone" />{" "}
            Ajouter un véhicule
          </button>
        </div>
        <div
          className="modal fade"
          id={modalId}
          aria-labelledby="AjouterVehiculeModalLabel"
          aria-hidden="true"
        >
          <AjouterVehiculeModal modalId={modalId} />
        </div>
        <div
          className="modal fade"
          id={modalImportId}
          aria-labelledby="AjouterVehiculeModalLabel"
          aria-hidden="true"
        >
          <ImporterVehiculeModal modalId={modalImportId} />
        </div>
      </div>
      <div className="workcare-dash-admin-page-content-container">
        <div className="locataire-requete-filtre-container mb-4">
          <form className="row g-3 locataire-requete-filtre-form">
            <div className="col-auto">
              <label
                htmlFor="filtreLogement"
                className="locataire-requete-filtre-label"
              >
                Filtrer
              </label>
            </div>
            <div className="col-auto">
              <input
                type="text"
                className="form-control locataire-requete-filtre-input"
                id="filtreLogement"
                placeholder="Chercher"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
            </div>
          </form>
        </div>
        <VehiculeProprietaireTable admin vehicules={filtred} />
      </div>
    </div>
  );
}

export default VehiculesAdmin;
