import { BiArrowBack } from "react-icons/bi";
import { useNavigate } from "react-router-dom";

import moment from "moment";
import { useCallback, useState } from "react";
import { Vehicule } from "../../../../types";
import { useLocationOrRedirect } from "../../../utils/api/hooks";
import { getImage } from "../../../utils/data";
import {
  InfosRow,
  MessageUrgence,
} from "../../Concessionnaires/Vehicules/DetailVehicule";
import Map from "../../Concessionnaires/Vehicules/Map";

const tabs = [
  "Informations générales",
  "Chauffeur",
  "Message d'urgence",
  "Localisation",
];

function DetailVehiculeProprietaire() {
  const navigate = useNavigate();
  const [index, setIndex] = useState(0);
  const item = useLocationOrRedirect<Vehicule>();
  const go = useCallback((index: number) => {
    setIndex(index);
  }, []);

  if (!item) return null;

  return (
    <div className="dashbord-visiteur-component">
      <div className="terranga-auto-dash-admin-titre-btn-container mb-4">
        <div className="workcare-dash-admin-titre-container">
          <div
            onClick={() => navigate(-1)}
            className="terranga-auto-dash-admin-titre"
          >
            <BiArrowBack />
            <span className="ps-2">Retour</span>
          </div>
        </div>
      </div>
      <div className="workcare-dash-admin-page-content-container mb-3">
        <ul className="tabs-container mt-4">
          {tabs.map((tab, key) => (
            <li
              className={`tab-item  ${index === key && "tab-active-item"}`}
              key={key}
              style={{ fontSize: 13 }}
              onClick={() => go(key)}
            >
              {tab}
            </li>
          ))}
        </ul>
        {index === 0 && (
          <>
            {!!item.image && (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img
                  src={getImage(item)}
                  style={{
                    height: 250,
                    width: "auto",
                    marginBottom: 10,
                    borderRadius: 10,
                  }}
                />
              </div>
            )}
            <section className="mb-3">
              <div className="row">
                <InfosRow title={"Matricule"} value={item.matricule} />
                <InfosRow title={"Année"} value={item.annee} />
                <InfosRow title={"Modèle"} value={item.model} />
                <InfosRow title={"Type de vitesse"} value={item.mode} />
                <InfosRow
                  title={"Prochaine visite"}
                  value={moment(item.dateVisite).format("DD/MM/YYYY")}
                />
              </div>
            </section>
          </>
        )}
        {index === 1 && (
          <>
            {!item.driverId && (
              <div className="alert alert-danger">Pas de chauffeur</div>
            )}
            {!!item.driverId && (
              <>
                <div className="row">
                  <InfosRow title={"Prénom"} value={item.driver?.prenom} />
                  <InfosRow title={"Nom"} value={item.driver?.nom} />
                  <InfosRow
                    title={"Téléphone"}
                    value={item.driver?.telephone}
                  />
                  <InfosRow title={"Email"} value={item.driver?.email} />
                  <InfosRow title={"Adresse"} value={item.driver?.address} />
                </div>
              </>
            )}
          </>
        )}
        {index === 2 && (
          <>
            {!!item.driver && <MessageUrgence client={item.driver} />}
            {!item.driverId && (
              <div className="alert alert-danger">Pas de chauffeur</div>
            )}
          </>
        )}
        {index === 3 && (
          <>
            {!!item.driver && <Map driver={item.driver} />}
            {!item.driverId && (
              <div className="alert alert-danger">Pas de chauffeur</div>
            )}
          </>
        )}
      </div>
    </div>
  );
}

export default DetailVehiculeProprietaire;
