import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { MessageContactType } from "../../../types"

export type State = MessageContactType[]

const initialState: State = []

export const MessageContactSlice = createSlice({
  name: "messages_contact",
  initialState,
  reducers: {
    onMessageContact: (state, action: PayloadAction<State>) => {
      const newState = [...state]
      for (const i of action.payload) {
        const index = newState.findIndex((o) => o.id === i.id)
        if (index === -1) {
          newState.push(i)
        } else {
          newState[index] = i
        }
      }
      return newState.sort((a, b) => b.created_at - a.created_at)
    },
  },
})

export const { onMessageContact } = MessageContactSlice.actions

export default MessageContactSlice.reducer
