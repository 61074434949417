import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import {
  AcceptRequestParams,
  AssignRequestFormData,
  Content,
  CreateRequestFormData,
  Driver,
  DriverFormData,
  Faq,
  FaqFormData,
  Filter,
  Invoice,
  InvoiceFormData,
  JourFormData,
  LandingPage,
  Newsleter,
  NewsleterFormData,
  Request,
  RequestAdmin,
  RequestFormData,
  RequestOwner,
  SendMessageUrgenceFormData,
  Tarif,
  UserFormData,
  UserInfos,
  UsersRequest,
  Vehicule,
  VehiculeFormData,
} from "../../../types";
import { useAppSelector, useUser } from "../../redux/root";
import { SWAL_DEFAULT_PROPS } from "../data";
import {
  AssignRequestFunction,
  acceptRequestFunction,
  addFaq,
  addInvoice,
  addJour,
  addNewsletter,
  addVehicule,
  createAdminFunction,
  createClientFunction,
  createConcessionnaireFunction,
  createDriverFunction,
  createOwnerFunction,
  createRequestFunction,
  createSubConcessionnaireFunction,
  createSubOwnerFunction,
  newsLetterByEmail,
  responseMessageContact,
  sendMessageUrgence,
  sendRequestsFunction,
  updateContent,
  updateFaq,
  updateInvoice,
  updateLandingPage,
  updateNewsletter,
  updateTarif,
  updateUserAsync,
  updateVehicule,
} from "./user.api";

function useCreateUser<T>(
  createfunction: (data: T) => Promise<any>,
  cb: () => void,
  success: string,
  error: string
) {
  const [loading, setLoading] = useState(false);

  const onAdd = useCallback(
    async (data: T) => {
      const wait = () => new Promise((r) => setTimeout(r, 1000));
      setLoading(true);
      try {
        const res = (await createfunction(data)) as {
          data: { ok: boolean; message?: string };
        };
        if (res?.data?.ok) {
          cb();
          await wait();
          Swal.fire({
            title: "Succès",
            text: success,
            icon: "success",
            ...SWAL_DEFAULT_PROPS,
          });
        } else {
          await wait();
          if (res?.data?.ok === false) {
            Swal.fire({
              title: "Erreur",
              text: res?.data?.message || `Adresse E-Mail déjà utilisée`,
              icon: "error",
              ...SWAL_DEFAULT_PROPS,
            });
          } else {
            Swal.fire({
              title: "Erreur",
              text: `Une erreur est survenue lors de l'ajout`,
              icon: "error",
              ...SWAL_DEFAULT_PROPS,
            });
          }
        }
      } catch (ex) {
        console.log("error", ex);
        Swal.fire({
          icon: "error",
          title: error,
          showConfirmButton: false,
          timer: 5000,
          ...SWAL_DEFAULT_PROPS,
        });
      }
      setLoading(false);
    },
    [cb]
  );

  return {
    loading,
    onAdd,
  };
}

export function useCreateDriver(cb: () => void) {
  const { loading, onAdd } = useCreateUser<DriverFormData>(
    createDriverFunction,
    cb,
    "Chauffeur ajouté avec succés",
    "Chauffeur non ajouté"
  );

  return {
    loading,
    onAddDriver: onAdd,
  };
}

export function useSendRequests(cb: (data: Request | null) => void) {
  const [loading, setLoading] = useState(false);

  const onAdd = useCallback(
    async (data: RequestFormData) => {
      setLoading(true);
      try {
        const res = (await sendRequestsFunction(data)) as {
          data: Request | null;
        };
        console.log("res", res);
        if (res.data?.uid) {
          Swal.fire({
            title: "Succès",
            text: `Demande envoyée avec succès`,
            icon: "success",
            ...SWAL_DEFAULT_PROPS,
          });
          cb(res.data);
        } else {
          Swal.fire({
            title: "Erreur",
            // @ts-ignore
            text: res.data?.message || `Une erreur s'est produite`,
            icon: "error",
            ...SWAL_DEFAULT_PROPS,
          });
          cb(null);
        }
      } catch (ex) {
        Swal.fire({
          title: "Erreur",
          text: `Une erreur s'est produite`,
          icon: "error",
          ...SWAL_DEFAULT_PROPS,
        });
        cb(null);
      }
      setLoading(false);
    },
    [cb]
  );
  return {
    loading,
    onAdd,
  };
}

export function useCreateRequests(cb: (data: Request | null) => void) {
  const [loading, setLoading] = useState(false);

  const onAdd = useCallback(
    async (data: CreateRequestFormData) => {
      setLoading(true);
      try {
        const res = (await createRequestFunction(data)) as {
          data: Request | null;
        };
        console.log("res", res);
        if (res.data?.uid) {
          Swal.fire({
            title: "Succès",
            text: `Prise en charge ajoutée avec succès`,
            icon: "success",
            ...SWAL_DEFAULT_PROPS,
          });
          cb(res.data);
        } else {
          Swal.fire({
            title: "Erreur",
            // @ts-ignore
            text: res.data?.message || `Une erreur s'est produite`,
            icon: "error",
            ...SWAL_DEFAULT_PROPS,
          });
          cb(null);
        }
      } catch (ex) {
        Swal.fire({
          title: "Erreur",
          text: `Une erreur s'est produite`,
          icon: "error",
          ...SWAL_DEFAULT_PROPS,
        });
        cb(null);
      }
      setLoading(false);
    },
    [cb]
  );
  return {
    loading,
    onAdd,
  };
}

export function useCreateClient(cb: () => void) {
  const { loading, onAdd } = useCreateUser<DriverFormData>(
    createClientFunction,
    cb,
    "Client ajouté avec succés",
    "Client non ajouté"
  );

  return {
    loading,
    onAdd: onAdd,
  };
}

export function useSendMessageUrgence(cb: () => void) {
  const { loading, onAdd } = useCreateUser<SendMessageUrgenceFormData>(
    sendMessageUrgence,
    cb,
    "Message envoyé succés",
    "Message non  envoyé"
  );

  return {
    loading,
    onAdd: onAdd,
  };
}

export function useCreateConcessionnaire(cb: () => void) {
  const { onAdd, loading } = useCreateUser<UserFormData>(
    createConcessionnaireFunction,
    cb,
    "Concessionnaire ajouté avec succés",
    "Concessionnaire non ajouté"
  );
  return {
    loading,
    onAddOwner: onAdd,
  };
}

export function useCreateOwner(cb: () => void, item?: UserInfos) {
  const [updating, setUpdating] = useState(false);
  const { onAdd, loading } = useCreateUser<UserFormData>(
    createOwnerFunction,
    cb,
    "Propriétaires ajouté avec succés",
    "Propriétaires non ajouté"
  );

  const onUpdate = async (data: UserFormData) => {
    if (!item) return;
    const wait = () => new Promise((r) => setTimeout(r, 1000));
    setUpdating(true);
    try {
      await updateUserAsync(item.uid, data);
      cb();
      await wait();
      Swal.fire({
        title: "Succès",
        text: "Propriétaires modifié avec succés",
        icon: "success",
        ...SWAL_DEFAULT_PROPS,
      });
    } catch (ex) {
      console.log("error", ex);
      Swal.fire({
        icon: "error",
        title: "Propriétaires non modifié",
        showConfirmButton: false,
        timer: 5000,
        ...SWAL_DEFAULT_PROPS,
      });
    }
    setUpdating(false);
  };
  return {
    loading: updating || loading,
    onAdd: (data: UserFormData) => {
      return item ? onUpdate(data) : onAdd(data);
    },
  };
}

export function useCreateAdmin(cb: () => void, item?: UserInfos) {
  const user = useUser();
  const owner = isOwner(user);
  const concessionnaires = isConcessionnaires(user);
  let func = createAdminFunction;
  if (owner) func = createSubOwnerFunction;
  if (concessionnaires) func = createSubConcessionnaireFunction;
  const ownerId = user.ownerId || user.uid;
  const concessionnaireId = user.concessionnaireId || user.uid;
  const [updating, setUpdating] = useState(false);
  const { onAdd, loading } = useCreateUser<UserFormData>(
    func,
    cb,
    "Administrateur ajouté avec succés",
    "Administrateur non ajouté"
  );

  const onUpdate = async (data: UserFormData) => {
    if (!item) return;
    const wait = () => new Promise((r) => setTimeout(r, 1000));
    setUpdating(true);
    try {
      await updateUserAsync(item.uid, data);
      cb();
      await wait();
      Swal.fire({
        title: "Succès",
        text: "Administrateur modifié avec succés",
        icon: "success",
        ...SWAL_DEFAULT_PROPS,
      });
    } catch (ex) {
      console.log("error", ex);
      Swal.fire({
        icon: "error",
        title: "Administrateur non modifié",
        showConfirmButton: false,
        timer: 5000,
        ...SWAL_DEFAULT_PROPS,
      });
    }
    setUpdating(false);
  };
  return {
    loading: updating || loading,
    onAddAdmin: (data: UserFormData) => {
      if (owner) data.ownerId = ownerId;
      if (concessionnaires) data.concessionnaireId = concessionnaireId;
      return item ? onUpdate(data) : onAdd(data);
    },
  };
}

export function useUpdateTarif(cb: () => void) {
  const [updating, setUpdating] = useState(false);

  const onUpdate = async (data: Tarif) => {
    const wait = () => new Promise((r) => setTimeout(r, 1000));
    setUpdating(true);
    try {
      await updateTarif(data);
      cb();
      await wait();
      Swal.fire({
        title: "Succès",
        text: "Tarif modifié avec succés",
        icon: "success",
        ...SWAL_DEFAULT_PROPS,
      });
    } catch (ex) {
      console.log("error", ex);
      Swal.fire({
        icon: "error",
        title: "Tarif non modifié",
        showConfirmButton: false,
        timer: 5000,
        ...SWAL_DEFAULT_PROPS,
      });
    }
    setUpdating(false);
  };
  return {
    loading: updating,
    onUpdate,
  };
}

export function useUpdateLandingPage(cb: () => void) {
  const [updating, setUpdating] = useState(false);

  const onUpdate = async (data: LandingPage) => {
    const wait = () => new Promise((r) => setTimeout(r, 1000));
    setUpdating(true);
    try {
      await updateLandingPage(data);
      cb();
      await wait();
      Swal.fire({
        title: "Succès",
        text: "Données modifié avec succés",
        icon: "success",
        ...SWAL_DEFAULT_PROPS,
      });
    } catch (ex) {
      console.log("error", ex);
      Swal.fire({
        icon: "error",
        title: "Données non modifié",
        showConfirmButton: false,
        timer: 5000,
        ...SWAL_DEFAULT_PROPS,
      });
    }
    setUpdating(false);
  };
  return {
    loading: updating,
    onUpdate,
  };
}

export function useAddJour(cb: () => void) {
  const [updating, setUpdating] = useState(false);

  const onAdd = async (data: JourFormData) => {
    const wait = () => new Promise((r) => setTimeout(r, 1000));
    setUpdating(true);
    try {
      await addJour(data);
      cb();
      await wait();
      Swal.fire({
        title: "Succès",
        text: "Données ajoutées avec succés",
        icon: "success",
        ...SWAL_DEFAULT_PROPS,
      });
    } catch (ex) {
      console.log("error", ex);
      Swal.fire({
        icon: "error",
        title: "Données non ajoutées",
        showConfirmButton: false,
        timer: 5000,
        ...SWAL_DEFAULT_PROPS,
      });
    }
    setUpdating(false);
  };
  return {
    loading: updating,
    onAdd,
  };
}

export function useAddFaq(cb: () => void) {
  const [updating, setUpdating] = useState(false);

  const onAdd = async (data: FaqFormData, item?: Faq) => {
    const wait = () => new Promise((r) => setTimeout(r, 1000));
    setUpdating(true);
    try {
      if (item) {
        await updateFaq(item.uid, data);
        cb();
        await wait();
        Swal.fire({
          title: "Succès",
          text: "Données modifiées avec succés",
          icon: "success",
          ...SWAL_DEFAULT_PROPS,
        });
      } else {
        await addFaq(data);
        cb();
        await wait();
        Swal.fire({
          title: "Succès",
          text: "Données ajoutées avec succés",
          icon: "success",
          ...SWAL_DEFAULT_PROPS,
        });
      }
    } catch (ex) {
      console.log("error", ex);
      Swal.fire({
        icon: "error",
        title: "Données non ajoutées",
        showConfirmButton: false,
        timer: 5000,
        ...SWAL_DEFAULT_PROPS,
      });
    }
    setUpdating(false);
  };
  return {
    loading: updating,
    onAdd,
  };
}

export function useAddContent(cb: () => void) {
  const [updating, setUpdating] = useState(false);

  const onAdd = async (data: Partial<Content>) => {
    const wait = () => new Promise((r) => setTimeout(r, 1000));
    setUpdating(true);
    try {
      await updateContent(data);
      cb();
      await wait();
      Swal.fire({
        title: "Succès",
        text: "Données modifiées avec succés",
        icon: "success",
        ...SWAL_DEFAULT_PROPS,
      });
    } catch (ex) {
      console.log("error", ex);
      Swal.fire({
        icon: "error",
        title: "Données non modifiées",
        showConfirmButton: false,
        timer: 5000,
        ...SWAL_DEFAULT_PROPS,
      });
    }
    setUpdating(false);
  };
  return {
    loading: updating,
    onAdd,
  };
}

export function useAddNewsletter(cb: () => void) {
  const [updating, setUpdating] = useState(false);

  const onAdd = async (data: NewsleterFormData, item?: Newsleter) => {
    const wait = () => new Promise((r) => setTimeout(r, 1000));
    setUpdating(true);
    try {
      const fined = await newsLetterByEmail(data.email);
      if (item) {
        if (
          !(
            fined &&
            fined?.id !== item.uid &&
            fined.data().email === data.email
          )
        ) {
          await updateNewsletter(item.uid, data);
          cb();
          await wait();
        }
        Swal.fire({
          title: "Succès",
          text: "Email modifié avec succés",
          icon: "success",
          ...SWAL_DEFAULT_PROPS,
        });
      } else {
        if (!(fined && fined.data().email === data.email)) {
          await addNewsletter(data);
          cb();
          await wait();
          Swal.fire({
            title: "Succès",
            text: "Email ajouté avec succés",
            icon: "success",
            ...SWAL_DEFAULT_PROPS,
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Email existe déjà",
            showConfirmButton: false,
            timer: 5000,
            ...SWAL_DEFAULT_PROPS,
          });
        }
      }
    } catch (ex) {
      console.log("error", ex);
      Swal.fire({
        icon: "error",
        title: "Email non ajouté",
        showConfirmButton: false,
        timer: 5000,
        ...SWAL_DEFAULT_PROPS,
      });
    }
    setUpdating(false);
  };
  return {
    loading: updating,
    onAdd,
  };
}

export function useAddVehicule(cb: () => void) {
  const [updating, setUpdating] = useState(false);

  const onAdd = async (data: VehiculeFormData) => {
    const wait = () => new Promise((r) => setTimeout(r, 1000));
    setUpdating(true);
    try {
      await addVehicule(data);
      await wait();
      cb();
      Swal.fire({
        title: "Succès",
        text: "Véhicule ajouté avec succés",
        icon: "success",
        ...SWAL_DEFAULT_PROPS,
      });
    } catch (ex: any) {
      Swal.fire({
        icon: "error",
        title: ex?.message || "Véhicule non ajouté",
        showConfirmButton: false,
        timer: 5000,
        ...SWAL_DEFAULT_PROPS,
      });
    }
    setUpdating(false);
  };

  const onUpdate = async (uid: string, data: VehiculeFormData) => {
    const wait = () => new Promise((r) => setTimeout(r, 1000));
    setUpdating(true);
    try {
      await updateVehicule(uid, data);
      await wait();
      cb();
      Swal.fire({
        title: "Succès",
        text: "Véhicule modifié avec succés",
        icon: "success",
        ...SWAL_DEFAULT_PROPS,
      });
    } catch (ex: any) {
      Swal.fire({
        icon: "error",
        title: ex?.message || "Véhicule non modifié",
        showConfirmButton: false,
        timer: 5000,
        ...SWAL_DEFAULT_PROPS,
      });
    }
    setUpdating(false);
  };

  return {
    loading: updating,
    onAdd: (uid: string | undefined, data: VehiculeFormData) => {
      if (uid) return onUpdate(uid, data);
      return onAdd(data);
    },
  };
}

export function useAddInvoice(cb: () => void) {
  const [updating, setUpdating] = useState(false);

  const onAdd = async (data: InvoiceFormData) => {
    const wait = () => new Promise((r) => setTimeout(r, 1000));
    setUpdating(true);
    try {
      await addInvoice(data);
      await wait();
      cb();
      Swal.fire({
        title: "Succès",
        text: "Facture ajoutée avec succés",
        icon: "success",
        ...SWAL_DEFAULT_PROPS,
      });
    } catch (ex: any) {
      Swal.fire({
        icon: "error",
        title: ex?.message || "Facture non ajoutée",
        showConfirmButton: false,
        timer: 5000,
        ...SWAL_DEFAULT_PROPS,
      });
    }
    setUpdating(false);
  };

  const onUpdate = async (uid: string, data: InvoiceFormData) => {
    const wait = () => new Promise((r) => setTimeout(r, 1000));
    setUpdating(true);
    try {
      await updateInvoice(uid, data);
      await wait();
      cb();
      Swal.fire({
        title: "Succès",
        text: "Facture modifiée avec succés",
        icon: "success",
        ...SWAL_DEFAULT_PROPS,
      });
    } catch (ex: any) {
      Swal.fire({
        icon: "error",
        title: ex?.message || "Facture non modifiée",
        showConfirmButton: false,
        timer: 5000,
        ...SWAL_DEFAULT_PROPS,
      });
    }
    setUpdating(false);
  };

  return {
    loading: updating,
    onAdd: (uid: string | undefined, data: VehiculeFormData) => {
      if (uid) return onUpdate(uid, data);
      return onAdd(data);
    },
  };
}

export function useResponseContact(cb: () => void) {
  const [loading, setLoading] = useState(false);

  const onResponse = useCallback(
    async (id: string, response: string) => {
      setLoading(true);
      try {
        await responseMessageContact(id, response);
        setTimeout(() => cb(), 400);
        setTimeout(() => {
          Swal.fire({
            icon: "success",
            title: "Réponse envoyé avec succés",
            showConfirmButton: false,
            timer: 5000,
            ...SWAL_DEFAULT_PROPS,
          });
          setLoading(false);
        }, 800);
      } catch (ex) {
        console.log("error", ex);
        setTimeout(() => cb(), 400);
        setTimeout(() => {
          Swal.fire({
            icon: "error",
            title: "Réponse non envoyé",
            showConfirmButton: false,
            timer: 5000,
            ...SWAL_DEFAULT_PROPS,
          });
          setLoading(false);
        }, 800);
      }
    },
    [cb]
  );

  return {
    loading,
    onResponse,
  };
}

export function useLocationOrRedirect<T>(redirectPath?: string) {
  const navigate = useNavigate();
  const { state } = useLocation() as { state: T };
  useEffect(() => {
    if (!state) {
      if (redirectPath) navigate(redirectPath, { replace: true });
      else navigate(-1);
    }
  }, [state]);

  return state;
}

export const useDeleteItem = (
  deleteFunction: (id: string) => Promise<any>,
  alert: string,
  success: string,
  error: string
) => {
  const onDelete = async (id: string) => {
    Swal.fire({
      title: alert,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "OUI",
      cancelButtonText: "NON",
      showLoaderOnConfirm: true,
      preConfirm: async () => {
        try {
          await deleteFunction(id);
          Swal.fire({
            icon: "success",
            title: success,
            showConfirmButton: false,
            timer: 5000,
            ...SWAL_DEFAULT_PROPS,
          });
        } catch (ex) {
          Swal.fire({
            icon: "error",
            title: error,
            showConfirmButton: false,
            timer: 5000,
            ...SWAL_DEFAULT_PROPS,
          });
        }
      },
      ...SWAL_DEFAULT_PROPS,
    });
  };

  return onDelete;
};

export const useUpdateItem = (
  updateFunction: (id: string, data: any) => Promise<any>,
  alert: string,
  success: string,
  error: string,
  cb?: (id: string, data: any) => void
) => {
  const onUpdate = async (id: string, data: any) => {
    Swal.fire({
      title: alert,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "OUI",
      cancelButtonText: "NON",
      showLoaderOnConfirm: true,
      preConfirm: async () => {
        try {
          await updateFunction(id, data);
          Swal.fire({
            icon: "success",
            title: success,
            showConfirmButton: false,
            timer: 5000,
            ...SWAL_DEFAULT_PROPS,
          });
          if (cb) {
            cb(id, data);
          }
        } catch (ex) {
          Swal.fire({
            icon: "error",
            title: error,
            showConfirmButton: false,
            timer: 5000,
            ...SWAL_DEFAULT_PROPS,
          });
        }
      },
      ...SWAL_DEFAULT_PROPS,
    });
  };

  return onUpdate;
};

export function useDashboardOwnersRequests(userId: string, filter?: Filter) {
  const [count, setCount] = useState({
    pending: 0,
    requests: 0,
  });
  const [data, setData] = useState<Request[]>([]);
  const requests = useFilterRequests(filter);
  useEffect(() => {
    const data = requests.filter(
      (f) => !!f.user && isOwnerIdIn(f.users, userId)
    );
    const pendings = requests.filter(
      (f) => !f.user && isOwnerIdIn(f.users, userId)
    );
    setData(data);
    setCount({
      pending: pendings.length,
      requests: data.length,
    });
  }, [requests.length]);
  return { ...count, data };
}

export function useDashbaordAdminRequests(filter?: Filter) {
  const [count, setCount] = useState<number>(0);
  const [data, setData] = useState<Request[]>([]);
  const requests = useFilterRequests(filter);
  useEffect(() => {
    const filtred = requests.filter((f) => !!f.user);
    setData(filtred);
    setCount(filtred.length);
  }, [requests.length]);
  return { count, data };
}

export function useDashboardConcessionnaireRequests(
  userId: string,
  filter?: Filter
) {
  const [count, setCount] = useState({
    pending: 0,
    requests: 0,
  });
  const [data, setData] = useState<Request[]>([]);
  const vehicules = useVehiculesConcessionnaire(userId);
  const [filtredRequests, setFiltredRequests] = useState<Request[]>([]);
  const requests = useFilterRequests(filter);
  useEffect(() => {
    const ids = vehicules.map((v) => v.uid);
    setFiltredRequests(
      requests.filter((r) => r.vehiculeId && ids.includes(r.vehiculeId))
    );
  }, [requests, vehicules.length]);

  useEffect(() => {
    const requests = filtredRequests.filter((u) => !!u.user);
    setCount({
      pending: filtredRequests.filter((u) => !u.user).length,
      requests: requests.length,
    });
    setData(requests);
  }, [filtredRequests]);
  return { ...count, data };
}

export function useAdminRequests() {
  const [data, setData] = useState<RequestAdmin[]>([]);
  const users = useDrivers();
  const requests = useAppSelector((s) => s.requests);
  useEffect(() => {
    const filtred = requests.filter((f) => !!f.user);
    const ids: { [key: string]: number } = {};
    let index = 0;
    for (let u of users) {
      ids[u.uid] = index;
      index++;
    }
    setData(
      filtred.map((item) => {
        const newItem = { ...item };
        if (newItem.user) {
          const uid = newItem.user;
          // @ts-ignore
          newItem.user = users[ids[uid]] as Driver;
          // @ts-ignore
          newItem.userData = newItem.users.find((u) => u.user === uid);
        }
        // @ts-ignore
        return newItem as RequestAdmin;
      })
    );
  }, [users, requests]);
  return data;
}

export function useConcessionnaireRequests(userId: string) {
  const [data, setData] = useState<RequestAdmin[]>([]);
  const users = useDrivers();
  const vehicules = useVehiculesConcessionnaire(userId);
  const [filtredRequests, setFiltredRequests] = useState<Request[]>([]);
  const requests = useAppSelector((s) => s.requests);
  useEffect(() => {
    const ids = vehicules.map((v) => v.uid);
    setFiltredRequests(
      requests.filter((r) => r.vehiculeId && ids.includes(r.vehiculeId))
    );
  }, [requests, vehicules.length, userId]);
  useEffect(() => {
    const ids: { [key: string]: number } = {};
    let index = 0;
    for (let u of users) {
      ids[u.uid] = index;
      index++;
    }
    setData(
      filtredRequests.map((item) => {
        const newItem = { ...item };
        if (newItem.user) {
          const uid = newItem.user;
          // @ts-ignore
          newItem.user = users[ids[uid]] as Driver;
          // @ts-ignore
          newItem.userData = newItem.users.find((u) => u.user === uid);
        }
        // @ts-ignore
        return newItem as RequestAdmin;
      })
    );
  }, [users, filtredRequests]);
  return data;
}

export function useFilterRequests(filter?: Filter) {
  const [data, setData] = useState<Request[]>([]);
  const requests = useAppSelector((s) => s.requests);

  useEffect(() => {
    if (filter && filter !== "all") {
      let format: any = filter;
      let date = moment();
      if (filter === "last month") {
        date = moment().subtract(1, "month").startOf("months");
        format = "month";
      }
      setData(requests.filter((r) => moment(r.date).isSame(date, format)));
    } else {
      setData(requests);
    }
  }, [requests, filter]);

  return data;
}

export function useAdminDahsboardPendingRequests(
  userId?: string,
  filter?: Filter
) {
  const [count, setCount] = useState<number>(0);
  const requests = useFilterRequests(filter);

  useEffect(() => {
    let count = 0;
    for (let r of requests) {
      if (
        !r.user &&
        r.vehiculeId &&
        (userId ? userId === r.concessionnaireId : true)
      ) {
        count++;
      }
    }
    setCount(count);
  }, [requests.length]);
  return {
    count,
  };
}
export function useAdminDashboardPendingOtherRequests(filter?: Filter) {
  const [count, setCount] = useState(0);
  const requests = useFilterRequests(filter);
  useEffect(() => {
    const filtred = requests.filter((f) => !f.user && !f.vehiculeId);
    setCount(filtred.length);
  }, [requests]);
  return { count };
}

export function useAdminPendingRequests(userId?: string, filter?: Filter) {
  const [data, setData] = useState<RequestOwner[]>([]);
  const drivers = useDrivers();
  const requests = useFilterRequests(filter);

  useEffect(() => {
    const ids: { [key: string]: number } = {};
    let index = 0;
    for (let u of drivers) {
      ids[u.uid] = index;
      index++;
    }

    const newRequests: RequestOwner[] = [];
    for (let r of requests) {
      if (
        !r.user &&
        r.vehiculeId &&
        (userId ? userId === r.concessionnaireId : true)
      ) {
        const users = r.users.filter((u) => !!drivers[ids[u.user]]);
        const newUsers: typeof users = [];
        for (let u of users) {
          const newU = { ...u };
          // @ts-ignore
          newU.user = drivers[ids[newU.user]];
          newUsers.push(newU);
        }
        newRequests.push({
          // @ts-ignore
          ...r,
          // @ts-ignore
          users: newUsers,
        });
      }
    }
    setData(newRequests);
  }, [requests, drivers]);
  return data;
}

export function useAdminPendingOtherRequests(filter?: Filter) {
  const [data, setData] = useState<Request[]>([]);
  const requests = useFilterRequests(filter);
  useEffect(() => {
    setData(requests.filter((f) => !f.user && !f.vehiculeId));
  }, [requests]);
  return data;
}

export function useOwnerPendingRequests(userId: string, filter?: Filter) {
  const [data, setData] = useState<RequestOwner[]>([]);

  const drivers = useDrivers();
  const requests = useFilterRequests(filter);

  useEffect(() => {
    const ids: { [key: string]: number } = {};
    let index = 0;
    for (let u of drivers) {
      ids[u.uid] = index;
      index++;
    }

    const newRequests: RequestOwner[] = [];
    for (let r of requests) {
      if (!r.vehiculeId && !r.user && isOwnerIdIn(r.users, userId)) {
        const users = r.users.filter((u) => u.ownerId === userId);
        const newUsers: typeof users = [];
        for (let u of users) {
          const newU = { ...u };
          // @ts-ignore
          newU.user = drivers[ids[newU.user]];
          newUsers.push(newU);
        }
        newRequests.push({
          // @ts-ignore
          ...r,
          // @ts-ignore
          users: newUsers,
        });
      }
    }
    setData(newRequests);
  }, [requests, drivers]);
  return data;
}

const isOwnerIdIn = (users: UsersRequest[], userId: string) => {
  return !!users.find((u) => {
    return u.ownerId === userId;
  });
};

export function useOwnersRequests(userId: string) {
  const [data, setData] = useState<RequestAdmin[]>([]);
  const users = useDrivers();
  const requests = useAppSelector((s) => s.requests);

  useEffect(() => {
    const filtred = requests.filter(
      (f) => !!f.user && isOwnerIdIn(f.users, userId)
    );
    const ids: { [key: string]: number } = {};
    let index = 0;
    for (let u of users) {
      ids[u.uid] = index;
      index++;
    }
    setData(
      filtred.map((item) => {
        const newItem = { ...item };
        if (newItem.user) {
          const uid = newItem.user;
          // @ts-ignore
          newItem.user = users[ids[uid]] as Driver;
          // @ts-ignore
          newItem.userData = newItem.users.find((u) => u.user === uid);
        }
        // @ts-ignore
        return newItem as RequestAdmin;
      })
    );
  }, [users, requests]);
  return data;
}

export const isDriver = (u: Driver) => {
  return !isAdmin(u) && !isOwner(u) && !isConcessionnaires(u) && !isClient(u);
};

export const isOwner = (u?: Driver | null) => {
  return u?.is_proprietaire || u?.is_sub_proprietaire;
};

export const isClient = (u?: Driver | null) => {
  return u?.isClient;
};

export const isConcessionnaires = (u?: Driver | null) => {
  return u?.is_concessionnaire || u?.is_sub_concessionnaire;
};

export const isAdmin = (u?: Driver | null) => {
  return u?.is_admin || u?.is_super_admin;
};

export function useDrivers() {
  const users = useAppSelector((s) => s.users);
  const [drivers, setDrivers] = useState<Driver[]>([]);
  useEffect(() => {
    setDrivers(users.filter((u) => isDriver(u)));
  }, [users]);
  return drivers;
}

export function useDriversAndVehicules() {
  const users = useAppSelector((s) => s.users);
  const vehicules = useAppSelector((s) => s.vehicules);
  const [drivers, setDrivers] = useState<Driver[]>([]);
  useEffect(() => {
    const filtred = users.filter((u) => isDriver(u));
    const newData: Driver[] = [];
    for (let i of filtred) {
      newData.push({
        ...i,
        vehicule: vehicules.find((u) => u.driverId === i.uid),
      });
    }
    setDrivers(newData);
  }, [users, vehicules]);
  return drivers;
}

export function useUtilisateurs() {
  const users = useAppSelector((s) => s.users);
  const [drivers, setDrivers] = useState<Driver[]>([]);
  useEffect(() => {
    setDrivers(users.filter((u) => isClient(u)));
  }, [users]);
  return drivers;
}

export function useVehiculesConcessionnaire(uid?: string) {
  const clients = useUtilisateurs();
  const items = useAppSelector((s) => s.vehicules);
  const [vehicules, setVehicules] = useState<Vehicule[]>([]);
  useEffect(() => {
    if (uid) {
      const filtred = items.filter((u) => u.concessionnaireId === uid);
      const newItems: Vehicule[] = [];
      for (let item of filtred) {
        const newItem = { ...item };
        if (newItem.clientId) {
          newItem.client = clients.find((u) => u.uid === newItem.clientId);
        }
        newItems.push(newItem);
      }
      setVehicules(newItems);
    }
  }, [items, uid, clients.length]);
  return vehicules;
}

export function useVehiculesProprietaires(uid?: string) {
  const drivers = useDrivers();
  const items = useAppSelector((s) => s.vehicules);
  const [vehicules, setVehicules] = useState<Vehicule[]>([]);
  useEffect(() => {
    if (uid) {
      const filtred = items.filter((u) => u.concessionnaireId === uid);
      const newItems: Vehicule[] = [];
      for (let item of filtred) {
        const newItem = { ...item };
        if (newItem.driverId) {
          newItem.driver = drivers.find((u) => u.uid === newItem.driverId);
        }
        newItems.push(newItem);
      }
      setVehicules(newItems);
    }
  }, [items, uid, drivers.length]);
  return vehicules;
}

export function useVehiculesWithClients() {
  const clients = useUtilisateurs();
  const items = useAppSelector((s) => s.vehicules);
  const [vehicules, setVehicules] = useState<Vehicule[]>([]);
  useEffect(() => {
    const filtred = items.filter((u) => !!u.concessionnaireId && !u.driverId);
    const newItems: Vehicule[] = [];
    for (let item of filtred) {
      const newItem = { ...item };
      if (newItem.clientId) {
        newItem.client = clients.find((u) => u.uid === newItem.clientId);
      }
      newItems.push(newItem);
    }
    setVehicules(newItems);
  }, [items, clients.length]);
  return vehicules;
}

export function useCamions() {
  const items = useAppSelector((s) => s.vehicules);
  const users = useAppSelector((s) => s.users);
  const [vehicules, setVehicules] = useState<Vehicule[]>([]);
  useEffect(() => {
    const owners = users.filter((u) => isOwner(u)).map((c) => c.uid);
    const filtred = items.filter(
      (u) => !u.concessionnaireId || owners.includes(u.concessionnaireId)
    );
    const drivers = users.filter((u) => isDriver(u));
    const newItems: Vehicule[] = [];
    for (let item of filtred) {
      const newItem = { ...item };
      if (newItem.driverId) {
        newItem.driver = drivers.find((u) => u.uid === newItem.driverId);
      }
      newItems.push(newItem);
    }
    setVehicules(newItems);
  }, [items, users.length]);
  return vehicules;
}

export function useVehiculesClients() {
  const clients = useUtilisateurs();
  const users = useAppSelector((s) => s.users);
  const items = useAppSelector((s) => s.vehicules);
  const [vehicules, setVehicules] = useState<Vehicule[]>([]);
  useEffect(() => {
    const owners = users.filter((u) => isConcessionnaires(u)).map((c) => c.uid);
    const filtred = items.filter((u) => owners.includes(u.concessionnaireId));
    const newItems: Vehicule[] = [];
    for (let item of filtred) {
      const newItem = { ...item };
      if (newItem.clientId) {
        newItem.client = clients.find((u) => u.uid === newItem.clientId);
      }
      newItems.push(newItem);
    }
    setVehicules(newItems);
  }, [items, clients.length, users.length]);
  return vehicules;
}

export function useVehicules() {
  const drivers = useDrivers();
  const items = useAppSelector((s) => s.vehicules);
  const [vehicules, setVehicules] = useState<Vehicule[]>([]);
  useEffect(() => {
    const filtred = items.filter((u) => !u.concessionnaireId);
    const newItems: Vehicule[] = [];
    for (let item of filtred) {
      const newItem = { ...item };
      if (newItem.driverId) {
        newItem.driver = drivers.find((u) => u.uid === newItem.driverId);
      }
      newItems.push(newItem);
    }
    setVehicules(newItems);
  }, [items, drivers.length]);
  return vehicules;
}

export function useUsersForInvoices() {
  const [data, setData] = useState<Driver[]>([]);
  const users = useAppSelector((s) => s.users);
  useEffect(() => {
    setData(users.filter((u) => isConcessionnaires(u) && !u.concessionnaireId));
  }, [users.length]);

  return data;
}

export function useInvoicesAdmin() {
  const users = useUsersForInvoices();
  const items = useAppSelector((s) => s.invoices);
  const [vehicules, setVehicules] = useState<Invoice[]>([]);
  useEffect(() => {
    const newItems: Invoice[] = [];
    for (let item of items) {
      const newItem = { ...item };
      if (newItem.userId) {
        newItem.user = users.find((u) => u.uid === newItem.userId);
      }
      newItems.push(newItem);
    }
    setVehicules(newItems);
  }, [items, users.length]);
  return vehicules;
}

export function useInvoicesByUser(uid: string) {
  const items = useAppSelector((s) => s.invoices);
  const [vehicules, setVehicules] = useState<Invoice[]>([]);
  useEffect(() => {
    setVehicules(items.filter((u) => u.userId === uid));
  }, [items, uid]);
  return vehicules;
}

export function useAdmins() {
  const users = useAppSelector((s) => s.users);
  const [drivers, setDrivers] = useState<Driver[]>([]);
  useEffect(() => {
    setDrivers(users.filter((u) => isAdmin(u)));
  }, [users]);
  return drivers;
}

export function useOwners() {
  const users = useAppSelector((s) => s.users);
  const [drivers, setDrivers] = useState<Driver[]>([]);
  useEffect(() => {
    setDrivers(users.filter((u) => isOwner(u)));
  }, [users]);
  return drivers;
}

export function useOwnersAdmin(uid: string | undefined) {
  const users = useAppSelector((s) => s.users);
  const [drivers, setDrivers] = useState<Driver[]>([]);
  useEffect(() => {
    setDrivers(users.filter((u) => isOwner(u) && u.ownerId === uid));
  }, [users]);
  return drivers;
}

export function useConcessionnaires() {
  const users = useAppSelector((s) => s.users);
  const [items, setItems] = useState<Driver[]>([]);
  useEffect(() => {
    setItems(
      users.filter((u) => isConcessionnaires(u) && !u.concessionnaireId)
    );
  }, [users]);
  return items;
}

export function useConcessionnairesAdmin(uid?: string) {
  const users = useAppSelector((s) => s.users);
  const [items, setItems] = useState<Driver[]>([]);
  useEffect(() => {
    setItems(
      users.filter((u) => isConcessionnaires(u) && u.concessionnaireId === uid)
    );
  }, [users]);
  return items;
}

export function useCLients() {
  const users = useAppSelector((s) => s.users);
  const [items, setItems] = useState<Driver[]>([]);
  useEffect(() => {
    setItems(users.filter((u) => isClient(u)));
  }, [users]);
  return items;
}

export function useAcceptRequest() {
  const [loading, setLoading] = useState(false);

  const onAdd = useCallback(async (data: AcceptRequestParams) => {
    setLoading(true);
    try {
      await acceptRequestFunction(data);
      Swal.fire({
        title: "Succès",
        text: "Demande acceptée avec succès",
        icon: "success",
        ...SWAL_DEFAULT_PROPS,
      });
    } catch (ex) {
      Swal.fire({
        title: "Erreur",
        text: "Demande non acceptée",
        icon: "error",
        ...SWAL_DEFAULT_PROPS,
      });
    }
    setLoading(false);
  }, []);
  return {
    loading,
    onAdd,
  };
}

export function useAssingRequest(cb: () => void) {
  const [loading, setLoading] = useState(false);

  const onAdd = useCallback(async (data: AssignRequestFormData) => {
    setLoading(true);
    try {
      await AssignRequestFunction(data);
      Swal.fire({
        title: "Succès",
        text: "Prise en charge assignée avec succès",
        icon: "success",
        ...SWAL_DEFAULT_PROPS,
      });
      cb();
    } catch (ex) {
      Swal.fire({
        title: "Erreur",
        text: "Prise en charge non assignée",
        icon: "error",
        ...SWAL_DEFAULT_PROPS,
      });
    }
    setLoading(false);
  }, []);
  return {
    loading,
    onAdd,
  };
}

export function getRedirectRoute(user?: UserInfos) {
  if (!user) return "/connexion";
  if (isAdmin(user)) return "/admin/dashboard";
  if (isOwner(user)) return `/proprietaire/dashboard`;
  if (isConcessionnaires(user)) return `/concessionnaire/dashboard`;

  return `/page-non-trouve`;
}
