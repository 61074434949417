import GoogleMapReact from "google-map-react";
import { Driver } from "../../../../types";
import { useLocationTraking } from "../../../utils/api/database";

export const MyGreatPlace = (props: any) => (
  <div
    style={{
      position: "absolute",
      width: 40,
      height: 40,
      left: -40 / 2,
      top: -40 / 2,
      border: "5px solid #f44336",
      borderRadius: 40,
      backgroundColor: "white",
      textAlign: "center",
      color: "#3f51b5",
      fontSize: 16,
      fontWeight: "bold",
      padding: 4,
    }}
  ></div>
);

export default function Map({ driver }: { driver: Driver }) {
  const { data, loading } = useLocationTraking(driver.uid);
  const center = {
    lat: parseFloat(data?.latitude ?? "0"),
    lng: parseFloat(data?.longitude ?? "0"),
  };
  console.log("center", center);
  return (
    <div style={{ height: "100vh", width: "100%" }}>
      {loading && <p>Chargement...</p>}
      {!loading && (
        <>
          {!data && <p>Pas de localisation trouvée</p>}
          {!!data && (
            <GoogleMapReact
              bootstrapURLKeys={{
                key: "AIzaSyAPP9zeGYiB3hM6nrWmcAMIJl5o5oQ4-zY",
              }}
              defaultCenter={{ lat: center.lat, lng: center.lng }}
              defaultZoom={11}
            >
              <MyGreatPlace lat={center.lat} lng={center.lng} />
            </GoogleMapReact>
          )}
        </>
      )}
    </div>
  );
}
