import { UserFormData } from "../../../../../types";
import { useCreateConcessionnaire } from "../../../../utils/api/hooks";
import { useModalActions } from "../../../../utils/data";
import { useFormData } from "../../../../utils/form";
import { FormError } from "../../../auth/Login";
import {
  BtnAnnulerModal,
  BtnCloseModal,
  BtnSubmit,
} from "../../../common/Button";
import { Input } from "../../../common/Input";
import GooglePlaceInput from "../../../shared/GooglePlace";
type AjouterConcessionnaireModalProps = {
  modalId?: string;
};
function AjouterConcessionnaireModal({
  modalId,
}: AjouterConcessionnaireModalProps) {
  const ma = useModalActions(modalId ?? "");

  const { errors, onChange, data, onSubmitForm, register } =
    useFormData<UserFormData>(
      {
        email: "",
        prenom: "",
        nom: "",
        telephone: "",
        address: "",
        nomSociete: "",
        nbVehicules: 0,
      },
      (Yup) => ({
        email: Yup.string().email().required().label("Email"),
        prenom: Yup.string().required().label("Prénom"),
        nom: Yup.string().required().label("Nom"),
        telephone: Yup.string().required().label("Téléphone"),
        nomSociete: Yup.string().required().label("Raison sociale"),
        address: Yup.string().required().label("Adresse"),
      })
    );

  const { loading, onAddOwner } = useCreateConcessionnaire(() => {
    ma.hide();
    setTimeout(() => {
      onChange({
        email: "",
        prenom: "",
        nom: "",
        telephone: "",
        address: "",
        nomSociete: "",
      });
    }, 1500);
  });
  return (
    <div className="modal-dialog modal-lg">
      <div className="modal-content custom-modal-content">
        <div className="modal-header custom-modal-header px-2 py-1">
          <h5 className="modal-title" id="AddTagModalLabel">
            Ajouter un concessionnaire
          </h5>
          <div className="workcares-btn-close-modal-container">
            <BtnCloseModal label="" />
          </div>
        </div>
        <div className="modal-body">
          <div className="container-form">
            <form
              onSubmit={(e) => {
                e.preventDefault();
                onSubmitForm(onAddOwner);
              }}
            >
              <div className="row">
                <div className="col-md-6">
                  <Input
                    type="text"
                    label="Prénom"
                    placeholder="Prénom"
                    {...register("prenom")}
                  />
                  <FormError error={errors.prenom} />
                </div>
                <div className="col-md-6">
                  <Input
                    type="text"
                    label="Nom"
                    placeholder="Nom"
                    {...register("nom")}
                  />
                  <FormError error={errors.nom} />
                </div>
                <div className="col-md-6">
                  <Input
                    type="text"
                    label="Raison sociale"
                    placeholder="Raison sociale"
                    {...register("nomSociete")}
                  />
                  <FormError error={errors.nomSociete} />
                </div>
                <div className="col-md-6">
                  <Input
                    type="phone"
                    label="Téléphone"
                    placeholder="Téléphone"
                    {...register("telephone")}
                  />
                  <FormError error={errors.telephone} />
                </div>
                <div className="col-md-6">
                  <Input
                    type="email"
                    label="Email"
                    placeholder="Email"
                    {...register("email")}
                  />
                  <FormError error={errors.email} />
                </div>
                <div className="col-md-6">
                  <GooglePlaceInput
                    onChange={(data) => {
                      onChange(data);
                    }}
                    value={data.address ?? ""}
                    className=""
                    placeholder="Adresse"
                  />
                  <FormError error={errors.address} />
                </div>
                <div className="col-md-6 offset-md-6">
                  <div className="mb-3 mt-5 flex-r gap-3">
                    <BtnAnnulerModal label={"Fermer"} isLoading={false} />
                    <BtnSubmit
                      label="Ajouter"
                      isLoading={loading}
                      style={{ padding: "5px 10px", fontSize: 14 }}
                    />
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AjouterConcessionnaireModal;
