import { AiOutlineFacebook, AiOutlineInstagram } from "react-icons/ai";
import { FaTwitter } from "react-icons/fa";
import { GrLinkedinOption } from "react-icons/gr";
import Fade from "react-reveal/Fade";
import { NavLink } from "react-router-dom";
import Logo from "../../../assets/landingpage/logo-footer.png";
import { useAppSelector } from "../../../redux/root";
import "./FrontFooter.css";

const FrontFooter = () => {
  const lp = useAppSelector((s) => s.landing_page);
  return (
    <footer className="footer-component">
      <div className="container-app-landing-page">
        <div className="row">
          <div className="col-md-6">
            <div className="container-copyright">
              <Fade bottom cascade>
                <div className="footer-grid">
                  <img src={Logo} alt="logo" className="logo-app-footer" />
                </div>
              </Fade>
              <Fade bottom cascade>
                <p className="copyright mb-0 py-5">
                  Nous somme ouvert du lundi au samedi de 08:00 à 18:00
                </p>
              </Fade>
              <Fade bottom cascade>
                {" "}
                <div className="Social-media-to-share-article">
                  <a href={lp.facebook} className="google">
                    <AiOutlineFacebook className="share-icon-social-media-item" />
                  </a>
                  <a href={lp.twitter} className="twitter">
                    <FaTwitter className="share-icon-social-media-item" />
                  </a>
                  <a href={lp.linkedin} className="linkedin">
                    <GrLinkedinOption className="share-icon-social-media-item" />
                  </a>
                  <a href={lp.instagram} className="instagram">
                    <AiOutlineInstagram className="share-icon-social-media-item" />
                  </a>
                </div>
              </Fade>
            </div>
          </div>
          <div className="col-md-6">
            <div className="container-copyright">
              <Fade bottom cascade>
                <h4 className="text-white">Garage Téranga Automobiles</h4>
                <p className="copyright mb-0 py-2">
                  <a
                    target="_blank"
                    className="no-link"
                    href="https://maps.google.com/maps?q=Garage+T%C3%A9ranga+Automobiles%2C+Maristes+00000%2C+S%C3%A9n%C3%A9gal&z=8"
                  >
                    V42E HANN MARISTES 2 DERRIERE ECOLE LES MARISTES Maristes
                    00000 Sénégal
                  </a>
                </p>
                <p className="copyright mb-0 py-2">
                  <a
                    target="_blank"
                    className="no-link"
                    href="tel:+221763706161"
                  >
                    76 370 61 61
                  </a>
                </p>
                <p className="copyright mb-0 py-2">
                  <a target="_blank" className="no-link" href="tel:338324658">
                    33 832 46 58
                  </a>
                </p>

                <p className="copyright mb-0 py-2">
                  <a
                    target="_blank"
                    className="no-link"
                    href="tel:+221779615031"
                  >
                    77 961 50 31
                  </a>
                </p>

                <p className="copyright mb-0 py-2">
                  <a
                    target="_blank"
                    className="no-link"
                    href="mailto:terangaautomobiles@gmail.com"
                  >
                    terangaautomobiles@gmail.com
                  </a>
                </p>
              </Fade>

              <p className="copyright mb-0 py-2">
                <NavLink className="no-link" to="/privacy">
                  Politique de confidentialité
                </NavLink>
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default FrontFooter;
