import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Request } from "../../../types";

const initialState: Request[] = [];

export const RequestSLice = createSlice({
  name: "requests",
  initialState,
  reducers: {
    onGetRequests: (state, action: PayloadAction<Request[]>) => {
      const newState = [...state];
      for (const i of action.payload) {
        const index = newState.findIndex((o) => o.uid === i.uid);
        if (index === -1) {
          newState.push(i);
        } else {
          newState[index] = i;
        }
      }
      return newState.sort((a, b) => b.date - a.date);
    },
    onDeleteRequests: (state, action: PayloadAction<string[]>) => {
      state = state.filter((f) => !action.payload.includes(f.uid));
      return state;
    },
  },
});

export const { onGetRequests, onDeleteRequests } = RequestSLice.actions;

export default RequestSLice.reducer;
