import React, { useState } from "react";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { BsFillPersonFill } from "react-icons/bs";
import { FaLock } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import Logo from "../../assets/appImages/logo-login.png";
import { useAppDispatch } from "../../redux/root";
import { onSetAuth } from "../../redux/slices/user.slice";
import { loginAsync } from "../../utils/api/auth.api";
import {
  getRedirectRoute,
  isAdmin,
  isConcessionnaires,
  isOwner,
} from "../../utils/api/hooks";
import { useFormData } from "../../utils/form";
import "./Login.css";

type LoginFormData = {
  email: string;
  password: string;
};

export function FormError({ error }: { error?: string }) {
  if (!error) return null;
  return <div className="alert alert-danger">{error}</div>;
}

export function SubmitButton({
  title,
  loading,
}: {
  title: string;
  loading?: boolean;
}) {
  if (loading) {
    return (
      <button
        data-testid="submit"
        type="button"
        disabled
        className="btn auth-submit-btn trans-0-2"
      >
        Chargement...
      </button>
    );
  }
  return (
    <button
      data-testid="submit"
      type="submit"
      className="btn auth-submit-btn trans-0-2"
    >
      {title}
    </button>
  );
}

const Login = () => {
  const [show, setShow] = useState(false);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { errors, setErrors, onSubmitForm, register } =
    useFormData<LoginFormData>(
      {
        email: "",
        password: "",
      },
      (Yup) => ({
        password: Yup.string().required().label("Mot de passe"),
        email: Yup.string().email().required().label("Adresse mail"),
      })
    );
  const [loading, setLoading] = React.useState(false);
  const onSubmit = React.useCallback(async (data: LoginFormData) => {
    setLoading(true);
    try {
      const auth = await loginAsync(data.email, data.password);
      if (auth) {
        if (
          isOwner(auth?.userInfos) ||
          isConcessionnaires(auth?.userInfos) ||
          isAdmin(auth?.userInfos)
        ) {
          dispatch(onSetAuth(auth));
          setTimeout(() => {
            navigate(getRedirectRoute(auth?.userInfos));
          }, 500);
        } else {
          setErrors({ email: "Identifiants incorrects" });
        }
      } else {
        setErrors({ email: "Identifiants incorrects" });
      }
    } catch (ex) {
      setErrors({ email: "Identifiants incorrects" });
      console.log("error login", ex);
    }
    setLoading(false);
  }, []);
  return (
    <div>
      <div className="empty-header">
        <img src={Logo} alt="Logo" />
      </div>
      <div className="container-page-login-app">
        <div className="auth-row">
          <div className="auth-col auth-right-side-col">
            <div className="auth-right-side-container">
              <div className="auth-form-container pb-4">
                <h2 className="title-auth-form-text pt-4 text-center">
                  Connectez-vous
                </h2>
                <div className="mt-5">
                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                      onSubmitForm(onSubmit);
                    }}
                    id="auth-form"
                  >
                    <div className="row auth-form-row">
                      <div className="col-md-12 auth-input-col mb-3">
                        <div className="auth-form-group position-relative">
                          <span className="icon-connect-form-login">
                            <BsFillPersonFill />
                          </span>
                          <input
                            type={`email`}
                            className="form-control auth-form-control"
                            placeholder="Identifiant"
                            aria-label="Username"
                            {...register("email")}
                          />
                        </div>
                        <FormError error={errors.email} />
                      </div>
                      <div className="col-md-12 auth-input-col mb-2">
                        <div className="auth-form-group position-relative">
                          <span className="icon-connect-form-login">
                            <FaLock />
                          </span>
                          <input
                            type={show ? "text" : "password"}
                            className="form-control auth-form-control"
                            placeholder="Mot de passe"
                            aria-label="Password"
                            {...register("password")}
                          />
                          <span
                            className="show-hide-password"
                            onClick={() => setShow((old) => !old)}
                          >
                            {show && <AiOutlineEyeInvisible />}
                            {!show && <AiOutlineEye />}
                          </span>
                        </div>
                        <FormError error={errors.password} />
                      </div>
                      <div className="content-forget-password-link mb-3 flex-r">
                        <Link
                          to={"/mot-de-passe-oublie"}
                          className="forget-password-link"
                        >
                          Mot de passe oublié ?
                        </Link>
                      </div>
                      <div className="col-md-8 offset-md-2 auth-submit-btn-container mt-5">
                        <SubmitButton title="Connexion" loading={loading} />
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
