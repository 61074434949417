import moment from "moment";
import { useState } from "react";
import {
  CreateRequestFormData,
  Driver,
  GooglePlaceData,
  PriseEnChargeFormData,
  Vehicule,
} from "../../../../../types";
import {
  useCamions,
  useCreateRequests,
  useDrivers,
  useVehiculesClients,
} from "../../../../utils/api/hooks";
import { getImage, useModalActions } from "../../../../utils/data";
import { useFormData } from "../../../../utils/form";
import { FormError } from "../../../auth/Login";
import {
  BtnAnnulerModal,
  BtnCloseModal,
  BtnSubmit,
} from "../../../common/Button";
import { Input, RSelect, Select } from "../../../common/Input";
import GooglePlaceInput from "../../../shared/GooglePlace";
type AjouterPriseEnChargeModalAdminProps = {
  modalId?: string;
};
function AjouterPriseEnChargeModalAdmin({
  modalId,
}: AjouterPriseEnChargeModalAdminProps) {
  const camions = useCamions();
  const drivers = useDrivers();

  const vehicules = useVehiculesClients();

  const ma = useModalActions(modalId ?? "");

  const [fetching, setFetching] = useState(false);

  const { errors, onChange, data, onSubmitForm, setErrors, register } =
    useFormData<PriseEnChargeFormData>(
      { camion: "", vehicule: "", fullname: "", telephone: "", driver: "" },
      (Yup) => ({
        from: Yup.mixed().required().label("De"),
        to: Yup.mixed().required().label("A"),
        camion: Yup.string().required().label("Camion"),
        driver: Yup.string().required().label("Chauffeur"),
        vehicule: Yup.string().required().label("Véhicule"),
      })
    );

  const { loading, onAdd } = useCreateRequests((data) => {
    setFetching(false);
    if (data) {
      ma.hide();
      setTimeout(() => {
        onChange({
          camion: "",
          vehicule: "",
          fullname: "",
          telephone: "",
          from: undefined,
          to: undefined,
        });
        const form = document.getElementById("form-add") as HTMLFormElement;
        if (form) {
          form.reset();
        }
      }, 1500);
    }
  });

  const findedVehicule = vehicules.find((v) => v.uid === data.vehicule);

  const onSubmit = async (data: PriseEnChargeFormData, vehicule?: Vehicule) => {
    setFetching(true);
    const sendData: CreateRequestFormData = {
      date: moment().toDate().getTime(),
      image: data.image,
      user: data.driver,
      camionId: data.camion,
      location: {
        from: data.from as GooglePlaceData,
        to: data.to as GooglePlaceData,
      },
      mode: data.mode,
      collected: false,
      ended: false,
      fullname: data.fullname,
      telephone: data.telephone,
      identifier: data.vehicule,
    };
    if (vehicule) {
      let client: Driver | null | undefined = vehicule.client;

      if (client) {
        sendData.image = getImage(vehicule);
        sendData.mode = vehicule.mode;
        sendData.fullname = `${client?.prenom} ${client?.nom}`;
        sendData.identifier = vehicule.matricule;
        sendData.telephone = client?.telephone || "";
        sendData.token = client?.token || null;
      }
      sendData.vehiculeId = vehicule.uid;
      sendData.concessionnaireId = vehicule.concessionnaireId;
      if (vehicule.clientId) {
        sendData.anonymousId = vehicule.clientId;
      }
    }
    onAdd(sendData);
  };

  return (
    <div className="modal-dialog modal-lg">
      <div className="modal-content custom-modal-content">
        <div className="modal-header custom-modal-header px-2 py-1">
          <h5 className="modal-title" id="AddTagModalLabel">
            Ajouter une prise en chrage
          </h5>
          <div className="workcares-btn-close-modal-container">
            <BtnCloseModal label="" />
          </div>
        </div>
        <div className="modal-body">
          <div className="container-form">
            <form
              id="form-add"
              onSubmit={(e) => {
                e.preventDefault();
                onSubmitForm((data) => {
                  if (findedVehicule) {
                    onSubmit(data, findedVehicule);
                  } else {
                    const errors: any = {};
                    if (!data.mode) errors.mode = "Ce champ est obligatoir";
                    if (!data.fullname)
                      errors.fullname = "Ce champ est obligatoir";
                    if (!data.telephone)
                      errors.telephone = "Ce champ est obligatoir";
                    if (!data.image) errors.image = "Ce champ est obligatoir";
                    if (Object.keys(errors).length > 0) {
                      setErrors(errors);
                    } else {
                      onSubmit(data);
                    }
                  }
                });
              }}
            >
              <div className="row">
                <div className="col-md-12">
                  <GooglePlaceInput
                    onChange={(data) => {
                      // @ts-ignore
                      onChange("from", data);
                    }}
                    value={data.from?.address ?? ""}
                    className=""
                    label="De"
                    placeholder="Ajouter votre position"
                  />
                  <FormError error={errors.from as any} />
                </div>
                <div className="col-md-12">
                  <GooglePlaceInput
                    onChange={(data) => {
                      // @ts-ignore
                      onChange("to", data);
                    }}
                    value={data.to?.address ?? ""}
                    className=""
                    label="A"
                    placeholder="Destionation du véhicule"
                  />
                  <FormError error={errors.to as any} />
                </div>

                <div className="col-md-12">
                  <Select
                    label="Camion"
                    data={camions.map((v) => ({
                      value: v.uid ?? "",
                      label: `${v.matricule}`,
                    }))}
                    placeholder="Camion"
                    {...register("camion")}
                  />
                  <FormError error={errors.camion} />
                </div>

                <div className="col-md-12">
                  <Select
                    label="Chauffeur"
                    data={drivers.map((v) => ({
                      value: v.uid ?? "",
                      label: `${v.prenom} ${v.nom} - ${v.telephone}`,
                    }))}
                    placeholder="Chauffeur"
                    {...register("driver")}
                  />
                  <FormError error={errors.driver} />
                </div>

                <div className="col-md-12">
                  <RSelect
                    label="Véhicule"
                    data={vehicules.map((v) => ({
                      value: v.uid,
                      label: `${v.matricule}`,
                    }))}
                    placeholder="Véhicule"
                    {...register("vehicule")}
                  />
                  <FormError error={errors.vehicule} />
                </div>

                {!findedVehicule?.client && !!data.vehicule && (
                  <>
                    <div className="col-md-12">
                      <Input
                        type="text"
                        label="Prénom & Nom"
                        placeholder="Prénom & Nom"
                        required
                        {...register("fullname")}
                      />
                      <FormError error={errors.fullname} />
                    </div>
                    <div className="col-md-12">
                      <Input
                        type="phone"
                        label="Téléphone"
                        placeholder="Téléphone"
                        required
                        {...register("telephone")}
                      />
                      <FormError error={errors.fullname} />
                    </div>
                    <div className="col-md-12">
                      <Select
                        label="Type de vitesse"
                        data={[
                          { label: "Manuel", value: "Manuel" },
                          { label: "Automatique", value: "Automatique" },
                        ]}
                        required
                        placeholder="Type de vitesse"
                        {...register("mode")}
                      />
                      <FormError error={errors.mode} />
                    </div>
                    <div className="col-md-6">
                      <div className="mb-3">
                        <Input
                          type="file"
                          label="Image"
                          id={"Image_0"}
                          name={"Image_0"}
                          accept="image/png, image/gif, image/jpeg"
                          onChange={(e) => {
                            if (e.target.files) {
                              // @ts-ignore
                              onChange("image", e.target.files[0]);
                            }
                          }}
                          data-testid="image"
                          placeholder="image"
                        />
                        <FormError error={errors.image as any} />
                        <div className="img-preview-container">
                          {data.image instanceof File && (
                            <div className="img-position-container">
                              <img
                                src={URL.createObjectURL(data.image)}
                                className="type-img-preview"
                                alt="img preview"
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </>
                )}
                <div className="col-md-6 offset-md-6">
                  <div className="mb-3 mt-5 flex-r gap-3">
                    <BtnAnnulerModal label={"Fermer"} isLoading={false} />
                    <BtnSubmit
                      label={"Ajouter"}
                      isLoading={loading || fetching}
                      style={{ padding: "5px 10px", fontSize: 14 }}
                    />
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AjouterPriseEnChargeModalAdmin;
