import { Faq, FaqFormData } from "../../../../../types";
import { useAddFaq } from "../../../../utils/api/hooks";
import { useModalActions } from "../../../../utils/data";
import { useFormData } from "../../../../utils/form";
import {
  BtnAnnulerModal,
  BtnCloseModal,
  BtnSubmit,
} from "../../../common/Button";
import { FormError, Input } from "../../../common/Input";

type AjouterFaqModalProps = {
  modalId?: string;
  item?: Faq;
};
function AjouterFaqModal({ modalId, item }: AjouterFaqModalProps) {
  const ma = useModalActions(modalId ?? "");

  const { errors, onChange, onSubmitForm, register } = useFormData<FaqFormData>(
    {
      question: item?.question ?? "",
      response: item?.response ?? "",
    },
    (Yup) => ({
      question: Yup.string().required().label("Question"),
      response: Yup.string().required().label("Réponse"),
    })
  );

  const { loading, onAdd } = useAddFaq(() => {
    ma.hide();
    setTimeout(() => {
      if (!item) {
        onChange({
          question: "",
          response: "",
        });
      }
    }, 1500);
  });
  return (
    <div className="modal-dialog modal-lg">
      <div className="modal-content custom-modal-content">
        <div className="modal-header custom-modal-header px-2 py-1">
          <h5 className="modal-title" id="AddTagModalLabel">
            {item ? "Modifier" : "Ajouter"} un faq
          </h5>
          <div className="workcares-btn-close-modal-container">
            <BtnCloseModal label="" />
          </div>
        </div>
        <div className="modal-body">
          <div className="container-form">
            <form
              onSubmit={(e) => {
                e.preventDefault();
                onSubmitForm((data) => onAdd(data, item));
              }}
            >
              <div className="row">
                <div className="col-md-12">
                  <Input
                    type="text"
                    label="Question"
                    placeholder="Question"
                    {...register("question")}
                  />
                  <FormError error={errors.question} />
                </div>
                <div className="col-md-12">
                  <Input
                    type="text"
                    label="Réponse"
                    placeholder="Réponse"
                    {...register("response")}
                  />
                  <FormError error={errors.response} />
                </div>

                <div className="col-md-6 offset-md-6">
                  <div className="mb-3 mt-5 flex-r gap-3">
                    <BtnAnnulerModal label={"Fermer"} isLoading={false} />
                    <BtnSubmit
                      label={item ? "Modifier" : "Ajouter"}
                      isLoading={loading}
                      style={{ padding: "5px 10px", fontSize: 14 }}
                    />
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AjouterFaqModal;
