import { useEffect, useState } from "react";
import { AiOutlinePlusSquare } from "react-icons/ai";
import { Vehicule } from "../../../../types";
import { useUser } from "../../../redux/root";
import { useVehiculesProprietaires } from "../../../utils/api/hooks";
import AjouterVehiculeModal from "../../admin/Chauffeurs/Modales/AjouterVehiculeModal";
import ImporterVehiculeModal from "../../admin/Chauffeurs/Modales/ImporterVehiculeModal";
import VehiculeProprietaireTable from "../../admin/Chauffeurs/Tables/VehiculeProprietaireTable";
import "./VehiculesProprietaires.css";

function VehiculesProprietaires() {
  const user = useUser();
  const userId = user.ownerId || user?.uid;
  const vehicules = useVehiculesProprietaires(userId);
  const [filtred, setFiltred] = useState<Vehicule[]>([]);
  const [search, setSearch] = useState("");
  const modalId = "AjouterVehiculeModal";
  const modalImportId = "ImporterVehiculeModal";

  useEffect(() => {
    const s = search.trim().toLowerCase();
    if (s) {
      setFiltred(
        vehicules.filter((u) =>
          `${u.matricule} ${u.annee} ${u.model} ${u.mode}`
            .toLowerCase()
            .includes(s)
        )
      );
    } else {
      setFiltred(vehicules);
    }
  }, [search, vehicules]);

  return (
    <div className="dashbord-visiteur-component">
      <div className="terranga-auto-dash-admin-titre-btn-container mb-4">
        <div className="workcare-dash-admin-titre-container">
          <h1 className="terranga-auto-dash-admin-titre">Véhicules</h1>
        </div>
        <div
          style={{
            flexDirection: "row",
            alignItems: "center",
            display: "flex",
            gap: 10,
            flexWrap: "wrap",
          }}
        >
          <button
            className="terranga-auto-dash-admin-btn-ajout-deux"
            data-bs-toggle="modal"
            data-bs-target={`#${modalImportId}`}
          >
            <AiOutlinePlusSquare className="terranga-auto-dash-admin-btn-ajout-icone" />{" "}
            Importer un fichier excel
          </button>
          <button
            className="terranga-auto-dash-admin-btn-ajout-deux"
            data-bs-toggle="modal"
            data-bs-target={`#${modalId}`}
          >
            <AiOutlinePlusSquare className="terranga-auto-dash-admin-btn-ajout-icone" />{" "}
            Ajouter un véhicule
          </button>
        </div>
        <div
          className="modal fade"
          id={modalId}
          aria-labelledby="AjouterVehiculeModalLabel"
          aria-hidden="true"
        >
          <AjouterVehiculeModal modalId={modalId} userId={userId} />
        </div>
        <div
          className="modal fade"
          id={modalImportId}
          aria-labelledby="AjouterVehiculeModalLabel"
          aria-hidden="true"
        >
          <ImporterVehiculeModal modalId={modalImportId} userId={userId} />
        </div>
      </div>
      <div className="workcare-dash-admin-page-content-container">
        <div className="locataire-requete-filtre-container mb-4">
          <form className="row g-3 locataire-requete-filtre-form">
            <div className="col-auto">
              <label
                htmlFor="filtreLogement"
                className="locataire-requete-filtre-label"
              >
                Filtrer
              </label>
            </div>
            <div className="col-auto">
              <input
                type="text"
                className="form-control locataire-requete-filtre-input"
                id="filtreLogement"
                placeholder="Chercher"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
            </div>
          </form>
        </div>
        <VehiculeProprietaireTable admin={false} vehicules={filtred} />
      </div>
    </div>
  );
}

export default VehiculesProprietaires;
