/* eslint-disable @typescript-eslint/no-use-before-define */
import { User } from "firebase/auth";
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  DocumentData,
  getDoc,
  getDocs,
  getFirestore,
  onSnapshot,
  query,
  serverTimestamp,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";
import moment from "moment";
import {
  AcceptRequestParams,
  AssignRequestFormData,
  Content,
  CreateRequestFormData,
  Driver,
  DriverFormData,
  Faq,
  FaqFormData,
  Invoice,
  InvoiceFormData,
  Jour,
  JourFormData,
  LandingPage,
  MessageContactType,
  Newsleter,
  NewsleterFormData,
  Notification,
  Request,
  RequestFormData,
  SendMessageUrgenceFormData,
  Tarif,
  UserFormData,
  UserInfos,
  Vehicule,
  VehiculeFormData,
} from "../../../types";
import { PATHS } from "./api";
import { uploadImagesAsync, uploadInvoiceAsync } from "./storage.api";

const firestore = getFirestore();
const functions = getFunctions();

export async function updateUserAsync(uid: string, data: UserFormData) {
  if (data.part_propritaire) {
    data.part_propritaire =
      typeof data.part_propritaire === "string"
        ? parseInt(data.part_propritaire)
        : data.part_propritaire;
  }
  return updateDoc(doc(firestore, PATHS.users, uid), data);
}

export async function updateTarif(data: Tarif) {
  return setDoc(doc(firestore, PATHS.tarif, PATHS.tarif), data);
}

export async function updateLandingPage(data: LandingPage) {
  return setDoc(doc(firestore, PATHS.landing_page, PATHS.landing_page), data);
}

export async function addJour(data: JourFormData) {
  return addDoc(collection(firestore, PATHS.jours), data);
}

export async function deleteJour(uid: string) {
  return deleteDoc(doc(firestore, PATHS.jours, uid));
}

export async function deleteRequest(uid: string) {
  return deleteDoc(doc(firestore, PATHS.requests, uid));
}

export async function addImportedVehicule(data: VehiculeFormData) {
  const sendData = { ...data };
  sendData.created_at = serverTimestamp();
  if (sendData.image instanceof File) {
    // @ts-ignore
    sendData.image = await uploadImagesAsync(sendData.image);
  }
  const mat = (sendData.matricule ?? "").trim();
  sendData.matricule = mat;
  sendData.matricule_lower = mat.toLowerCase();
  return addDoc(collection(firestore, PATHS.vehicules), sendData);
}

export async function addVehicule(data: VehiculeFormData) {
  const sendData = { ...data };
  sendData.created_at = serverTimestamp();
  if (sendData.image instanceof File) {
    // @ts-ignore
    sendData.image = await uploadImagesAsync(sendData.image);
  }
  const mat = (sendData.matricule ?? "").trim();
  const doc = await vehiculeByMatricule(mat.toLowerCase());
  if (doc) throw { message: "Matricule existe déjà" };
  sendData.matricule = mat;
  sendData.matricule_lower = mat.toLowerCase();
  return addDoc(collection(firestore, PATHS.vehicules), sendData);
}

export async function updateVehicule(uid: string, data: VehiculeFormData) {
  const sendData = { ...data };
  if (sendData.image instanceof File) {
    // @ts-ignore
    sendData.image = await uploadImagesAsync(sendData.image);
  }
  const mat = (sendData.matricule ?? "").trim();
  if (mat) {
    const doc = await vehiculeByMatricule(mat.toLowerCase());
    if (doc && doc.id !== uid) throw { message: "Matricule existe déjà" };
    sendData.matricule = mat;
    sendData.matricule_lower = mat.toLowerCase();
  }

  return updateDoc(doc(firestore, PATHS.vehicules, uid), sendData);
}

export async function deleteVehicule(uid: string) {
  return deleteDoc(doc(firestore, PATHS.vehicules, uid));
}

export async function addInvoice(data: InvoiceFormData) {
  const sendData = { ...data };
  sendData.created_at = serverTimestamp();
  if (sendData.fichier instanceof File) {
    // @ts-ignore
    sendData.fichier = await uploadInvoiceAsync(sendData.fichier);
  }

  return addDoc(collection(firestore, PATHS.invoices), sendData);
}

export async function updateInvoice(uid: string, data: InvoiceFormData) {
  const sendData = { ...data };
  if (sendData.fichier instanceof File) {
    // @ts-ignore
    sendData.fichier = await uploadInvoiceAsync(sendData.fichier);
  }
  return updateDoc(doc(firestore, PATHS.invoices, uid), sendData);
}

export async function readNotif(uid: string) {
  return updateDoc(doc(firestore, PATHS.notifications, uid), {
    read: true,
    readDate: new Date().getTime(),
  });
}

export async function deleteNotif(uid: string) {
  return deleteDoc(doc(firestore, PATHS.notifications, uid));
}

export async function deleteInvoice(uid: string) {
  return deleteDoc(doc(firestore, PATHS.invoices, uid));
}

export async function updateFaq(uid: string, data: FaqFormData) {
  return updateDoc(doc(firestore, PATHS.faq, uid), data);
}

export async function addFaq(data: FaqFormData) {
  return addDoc(collection(firestore, PATHS.faq), data);
}

export async function deleteFaq(uid: string) {
  return deleteDoc(doc(firestore, PATHS.faq, uid));
}

export async function vehiculeByDriverId(uid: string) {
  const docs = await getDocs(
    query(collection(firestore, PATHS.vehicules), where("driverId", "==", uid))
  );
  if (docs.empty) return null;
  const doc = docs.docs[0];
  return getDocData(doc) as Vehicule;
}

export async function vehiculeById(uid: string) {
  const docRef = await getDoc(doc(firestore, PATHS.vehicules, uid));
  return getDocData(docRef) as Vehicule;
}

export async function newsLetterByEmail(email: string) {
  const docs = await getDocs(
    query(collection(firestore, PATHS.newsletter), where("email", "==", email))
  );
  if (docs.empty) return null;
  const doc = docs.docs[0];

  return doc;
}

export async function vehiculeByMatricule(mat: string) {
  const docs = await getDocs(
    query(
      collection(firestore, PATHS.vehicules),
      where("matricule_lower", "==", mat.toLowerCase())
    )
  );
  if (docs.empty) return null;
  const doc = docs.docs[0];

  return doc;
}

export async function updateNewsletter(uid: string, data: NewsleterFormData) {
  return updateDoc(doc(firestore, PATHS.newsletter, uid), data);
}

export async function updateContent(data: Partial<Content>) {
  return updateDoc(doc(firestore, PATHS.content, PATHS.content), data);
}

export async function addNewsletter(data: NewsleterFormData) {
  return addDoc(collection(firestore, PATHS.newsletter), data);
}

export async function deleteNewsletter(uid: string) {
  return deleteDoc(doc(firestore, PATHS.newsletter, uid));
}

export function userListener(user: User, cb: (dzta: UserInfos | null) => void) {
  return onSnapshot(doc(firestore, PATHS.users, user.uid), async (snapshot) => {
    if (snapshot.exists()) {
      cb(getDocData<UserInfos>(snapshot));
    } else {
      cb(null);
    }
  });
}

export function tarifListener(cb: (dzta: Tarif) => void) {
  return onSnapshot(
    doc(firestore, PATHS.tarif, PATHS.tarif),
    async (snapshot) => {
      if (snapshot.exists()) {
        cb(snapshot.data() as Tarif);
      }
    }
  );
}

export function contentListener(cb: (dzta: Content) => void) {
  return onSnapshot(
    doc(firestore, PATHS.content, PATHS.content),
    async (snapshot) => {
      if (snapshot.exists()) {
        cb(snapshot.data() as Content);
      } else {
        const initData: Content = { ptc: "", cgu: "" };
        setDoc(doc(firestore, PATHS.content, PATHS.content), initData);
      }
    }
  );
}

export function landingPageListener(cb: (dzta: LandingPage) => void) {
  return onSnapshot(
    doc(firestore, PATHS.landing_page, PATHS.landing_page),
    async (snapshot) => {
      if (snapshot.exists()) {
        cb(snapshot.data() as LandingPage);
      }
    }
  );
}

export async function getUserInfos(user: User) {
  return getUserByUid(user.uid);
}

export async function getUserByUid(uid: string) {
  const docRef = await getDoc(doc(firestore, PATHS.users, uid));
  if (docRef.exists()) {
    return getDocData<UserInfos>(docRef);
  }
  return null;
}

export function adminListener(
  cb: (data: UserInfos[], deleteds: string[]) => void
) {
  const q = query(
    collection(firestore, PATHS.users),
    where("is_admin", "==", true)
  );
  return onSnapshot(q, async (querySnapshot) => {
    const users: UserInfos[] = [];
    const deleteds: string[] = [];
    querySnapshot.docChanges().forEach((change) => {
      if (change.type === "removed") {
        deleteds.push(change.doc.id);
      } else {
        users.push(getDocData(change.doc) as UserInfos);
      }
    });
    cb(users, deleteds);
  });
}
export function adminOwnerListener(
  ownerId: string,
  cb: (data: UserInfos[], deleteds: string[]) => void
) {
  const q = query(
    collection(firestore, PATHS.users),
    where("ownerId", "==", ownerId)
  );
  return onSnapshot(q, async (querySnapshot) => {
    const users: UserInfos[] = [];
    const deleteds: string[] = [];
    querySnapshot.docChanges().forEach((change) => {
      if (change.type === "removed") {
        deleteds.push(change.doc.id);
      } else {
        users.push(getDocData(change.doc) as UserInfos);
      }
    });
    cb(users, deleteds);
  });
}

export function faqListener(cb: (data: Faq[], deleteds: string[]) => void) {
  return onSnapshot(collection(firestore, PATHS.faq), async (querySnapshot) => {
    const users: Faq[] = [];
    const deleteds: string[] = [];
    querySnapshot.docChanges().forEach((change) => {
      if (change.type === "removed") {
        deleteds.push(change.doc.id);
      } else {
        users.push(getDocData(change.doc) as Faq);
      }
    });
    cb(users, deleteds);
  });
}

export function newsletterListener(
  cb: (data: Newsleter[], deleteds: string[]) => void
) {
  return onSnapshot(
    collection(firestore, PATHS.newsletter),
    async (querySnapshot) => {
      const users: Newsleter[] = [];
      const deleteds: string[] = [];
      querySnapshot.docChanges().forEach((change) => {
        if (change.type === "removed") {
          deleteds.push(change.doc.id);
        } else {
          users.push(getDocData(change.doc) as Newsleter);
        }
      });
      cb(users, deleteds);
    }
  );
}

export function joursListener(cb: (data: Jour[], deleteds: string[]) => void) {
  return onSnapshot(
    collection(firestore, PATHS.jours),
    async (querySnapshot) => {
      const users: Jour[] = [];
      const deleteds: string[] = [];
      querySnapshot.docChanges().forEach((change) => {
        if (change.type === "removed") {
          deleteds.push(change.doc.id);
        } else {
          users.push(getDocData(change.doc) as Jour);
        }
      });
      cb(users, deleteds);
    }
  );
}
export function driverListener(
  cb: (data: Driver[], deleteds: string[]) => void
) {
  return onSnapshot(
    collection(firestore, PATHS.users),
    async (querySnapshot) => {
      const users: Driver[] = [];
      const deleteds: string[] = [];
      querySnapshot.docChanges().forEach((change) => {
        if (change.type === "removed") {
          deleteds.push(change.doc.id);
        } else {
          const data = getDocData<Driver>(change.doc);
          users.push(data);
        }
      });
      if (users.length > 0 || deleteds.length > 0) {
        cb(users, deleteds);
      }
    }
  );
}

export function VehiculeListener(
  cb: (data: Vehicule[], deleteds: string[]) => void
) {
  return onSnapshot(
    collection(firestore, PATHS.vehicules),
    async (querySnapshot) => {
      const vehicules: Vehicule[] = [];
      const deleteds: string[] = [];
      querySnapshot.docChanges().forEach((change) => {
        if (change.type === "removed") {
          deleteds.push(change.doc.id);
        } else {
          const data = getDocData<Vehicule>(change.doc);
          vehicules.push(data);
        }
      });
      if (vehicules.length > 0 || deleteds.length > 0) {
        cb(vehicules, deleteds);
      }
    }
  );
}

export function requestsListener(
  cb: (data: Request[], deleteds: string[]) => void
) {
  return onSnapshot(
    collection(firestore, PATHS.requests),
    async (querySnapshot) => {
      const users: Request[] = [];
      const deleteds: string[] = [];
      querySnapshot.docChanges().forEach((change) => {
        if (change.type === "removed") {
          deleteds.push(change.doc.id);
        } else {
          const data = getDocData<Request>(change.doc);
          users.push(data);
        }
      });
      if (users.length > 0 || deleteds.length > 0) {
        cb(users, deleteds);
      }
    }
  );
}

export function invoicesListener(
  cb: (data: Invoice[], deleteds: string[]) => void
) {
  return onSnapshot(
    collection(firestore, PATHS.invoices),
    async (querySnapshot) => {
      const users: Invoice[] = [];
      const deleteds: string[] = [];
      querySnapshot.docChanges().forEach((change) => {
        if (change.type === "removed") {
          deleteds.push(change.doc.id);
        } else {
          const data = getDocData<Invoice>(change.doc);
          users.push(data);
        }
      });
      if (users.length > 0 || deleteds.length > 0) {
        cb(users, deleteds);
      }
    }
  );
}

export function notificationListener(
  uid: string,
  cb: (data: Notification[]) => void
) {
  return onSnapshot(
    query(collection(firestore, PATHS.notifications), where("to", "==", uid)),
    async (querySnapshot) => {
      const users: Notification[] = [];
      querySnapshot.docChanges().forEach((change) => {
        if (change.type !== "removed") {
          const data = getDocData<Notification>(change.doc);
          users.push(data);
        }
      });
      cb(users);
    }
  );
}

export async function createDriverFunction(data: DriverFormData) {
  const callable = httpsCallable(functions, "createUser");
  return callable(data);
}

export async function toggleUserStatusFunction(userId: string) {
  const callable = httpsCallable(functions, "toggleUserStatus");
  return callable(userId);
}

export async function sendRequestsFunction(data: RequestFormData) {
  const callable = httpsCallable(functions, "sendRequests");
  if (data.image instanceof File) {
    data.image = await uploadImagesAsync(data.image);
  }
  return callable(data);
}

export async function createRequestFunction(data: CreateRequestFormData) {
  const callable = httpsCallable(functions, "createRequest");
  if (data.image instanceof File) {
    data.image = await uploadImagesAsync(data.image);
  }
  return callable(data);
}

export async function createClientFunction(data: DriverFormData) {
  const callable = httpsCallable(functions, "createClient");
  return callable(data);
}

export async function sendMessageUrgence(data: SendMessageUrgenceFormData) {
  const callable = httpsCallable(functions, "sendMessageUrgence");
  return callable(data);
}

export async function createOwnerFunction(data: UserFormData) {
  const callable = httpsCallable(functions, "createProprietaire");
  data.part_propritaire =
    typeof data.part_propritaire === "string"
      ? parseInt(data.part_propritaire)
      : data.part_propritaire;
  return callable(data);
}

export async function createSubOwnerFunction(data: UserFormData) {
  const callable = httpsCallable(functions, "createSousProprietaire");
  return callable(data);
}

export async function createConcessionnaireFunction(data: UserFormData) {
  const callable = httpsCallable(functions, "createConcessionnaire");
  return callable(data);
}

export async function createSubConcessionnaireFunction(data: UserFormData) {
  const callable = httpsCallable(functions, "createSousConcessionnaire");
  return callable(data);
}

export async function createAdminFunction(data: UserFormData) {
  const callable = httpsCallable(functions, "createAdmin");
  return callable(data);
}

export function AssignRequestFunction(data: AssignRequestFormData) {
  const callable = httpsCallable(functions, "AssignRequest");
  return callable(data);
}

export function createRequests(data: CreateRequestFormData[]) {
  const callable = httpsCallable(functions, "createRequests");
  return callable(data);
}

export function deleteUserFunction(id: string) {
  const callable = httpsCallable(functions, "deleteUser");
  return callable(id);
}
type SendResponseMail = {
  email: string;
  subject: string;
  response: string;
};

export function sendResponseContactFunction(data: SendResponseMail) {
  const callable = httpsCallable(functions, "sendResponseContact");
  return callable(data);
}

export function deleteNormalUserFunction(id: string) {
  const callable = httpsCallable(functions, "deleteNormalUser");
  return callable(id);
}

export function acceptRequestFunction(data: AcceptRequestParams) {
  const callable = httpsCallable(functions, "acceptRequest");
  return callable(data);
}

export async function getCgu() {
  const docRef = await getDoc(doc(firestore, PATHS.contents, "cgu"));
  if (docRef.exists()) {
    return docRef.data().content as string;
  }
  return "";
}

export async function updateCgu(content: string) {
  return setDoc(doc(firestore, PATHS.contents, "cgu"), { content });
}

export async function responseMessageContact(id: string, response: string) {
  const item = await getDoc(doc(firestore, PATHS.contacts, id));
  if (item.exists()) {
    const data = item.data() as MessageContactType;
    const responses = data.responses
      ? [...data.responses, response]
      : [response];
    await updateMessageContact(id, { responses });
    sendResponseContactFunction({
      email: data.email,
      response: response,
      subject: data.subject ?? data.message,
    });
  }
  return null;
}
export async function updateMessageContact(
  id: string,
  data: Partial<MessageContactType>
) {
  return updateDoc(doc(firestore, PATHS.contacts, id), data);
}

export async function updateRequest(id: string, data: RequestFormData) {
  return updateDoc(doc(firestore, PATHS.requests, id), data);
}

export function messageContactListener(
  cb: (data: MessageContactType[]) => void
) {
  return onSnapshot(
    collection(firestore, PATHS.contacts),
    async (querySnapshot) => {
      const data: MessageContactType[] = [];
      querySnapshot.docChanges().forEach((change) => {
        if (change.type !== "removed") {
          data.push(getDocData<MessageContactType>(change.doc));
        }
      });
      cb(data);
    }
  );
}

export function getDocData<T>(doc: DocumentData) {
  const data = doc.data();
  return {
    ...data,
    created_at:
      moment(data.created_at?.toDate()?.toString()).toDate().getTime() ??
      new Date().getTime(),
    uid: doc.id,
    id: doc.id,
  } as T;
}
