import moment from "moment";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import { AiFillEye } from "react-icons/ai";
// import { FaTrash } from "react-icons/fa";
import { FaTrash } from "react-icons/fa";
import { NavLink } from "react-router-dom";
import Swal from "sweetalert2";
import {
  Request,
  RequestAdmin,
  RequestFormData,
  RequestOwner,
} from "../../../../../types";
import { useUser } from "../../../../redux/root";
import {
  isAdmin,
  isConcessionnaires,
  isOwner,
  useDeleteItem,
} from "../../../../utils/api/hooks";
import { deleteRequest, updateRequest } from "../../../../utils/api/user.api";
import {
  SWAL_DEFAULT_PROPS,
  getAmountFormRequest,
  getAvatar,
} from "../../../../utils/data";
import { CommonTable } from "../../../common/Input";

type CommonPriseEnChargeAdminTableProps = {
  data: RequestAdmin[] | Request[];
  enableDelete?: boolean;
  isPro?: boolean;
};

function CommonPriseEnChargeAdminTable({
  data,
  isPro = false,
  enableDelete,
}: CommonPriseEnChargeAdminTableProps) {
  const user = useUser();

  const onDelete = useDeleteItem(
    deleteRequest,
    `Êtes-vous sûr de vouloir supprimer cette demande de prise en charge ?`,
    `Demande de prise en charge supprimée avec succés`,
    `Demande de prise en chargenon supprimée`
  );

  const actionFormatter = (cell: string, row: RequestAdmin) => {
    let link = `/admin/prise-en-charge/${row.uid}`;
    if (isOwner(user)) {
      link = `/proprietaire/prise-en-charge/${row.uid}`;
    } else if (isConcessionnaires(user)) {
      link = `/concessionnaire/prise-en-charge/${row.uid}`;
    }

    return (
      <div className="table-actions-btn-container-action gap-2 d-flex">
        <NavLink
          to={link}
          state={row}
          className="btn btn-action-admin-voir with-tooltip no-link"
          data-tooltip-content="Voir"
        >
          <AiFillEye />
        </NavLink>
        {enableDelete && (
          <button
            className="btn btn-action-admin-supprimer with-tooltip"
            data-tooltip-content="Supprimer"
            onClick={() => {
              onDelete(row.uid);
            }}
          >
            <FaTrash />
          </button>
        )}
      </div>
    );
  };

  const chargeFormatter = (cell: string, row: RequestAdmin) => {
    return (
      <div>
        <img
          src={row.image || getAvatar()}
          alt="car img"
          className="terranga-auto-table-img-car"
          style={{ width: 50, height: "auto" }}
        />{" "}
        {row.identifier}
      </div>
    );
  };
  const nameFormatter = (cell: string, row: RequestAdmin) => {
    return <div>{row.fullname ?? row.telephone ?? "-"}</div>;
  };

  const DriverFormatter = (cell: string, row: RequestAdmin) => {
    if (!row.user) return "-";
    return <div>{`${row.user.prenom} ${row.user.nom}`}</div>;
  };

  const statutFormatter = (cell: string, row: RequestAdmin) => {
    return <PriseEnChargeStatus item={row} />;
  };

  const columns = [
    {
      dataField: "uid",
      text: "Prise en charge",
      formatter: chargeFormatter,
      headerStyle: () => {
        return { width: "250px", whiteSpace: "normal" };
      },
    },
    {
      dataField: "fullname",
      text: "Nom",
      formatter: nameFormatter,
    },
    {
      dataField: "user",
      text: "Chauffeur",
      formatter: DriverFormatter,
    },
    {
      dataField: "amount",
      text: "Montant",
      formatter: (cell: string, row: RequestAdmin) =>
        getAmountFormRequest(row, isPro),
    },
    {
      dataField: "statut",
      text: "Statut",
      formatter: statutFormatter,
    },
    {
      dataField: "date",
      text: "Date",
      formatter: (cell: string) => moment(cell).format("DD/MM/YYYY"),
    },
    {
      dataField: "action",
      text: "Actions",
      style: { textAlign: "center" },
      formatter: actionFormatter,
    },
  ];

  return <CommonTable keyField="uid" data={data} columns={columns} />;
}

export default CommonPriseEnChargeAdminTable;

export function PriseEnChargeStatus({
  item,
}: {
  item: Request | RequestAdmin | RequestOwner;
}) {
  const admin = isAdmin(useUser());
  if (!item.user)
    return (
      <button className="btn terranga-auto-btn-statut-nouveau">Nouveau</button>
    );
  if (!item.collected)
    return (
      <button
        onClick={() => admin && onUpdateStatus(item)}
        className="btn terranga-auto-btn-statut-nouveau"
      >
        En attente
      </button>
    );
  if (item.collected && !item.ended)
    return (
      <button
        onClick={() => admin && onUpdateStatus(item)}
        className="btn terranga-auto-btn-statut-nouveau"
      >
        En cours
      </button>
    );

  return (
    <button
      onClick={() => admin && onUpdateStatus(item)}
      className="btn terranga-auto-btn-statut-success"
    >
      Terminé
    </button>
  );
}

const onUpdateStatus = async (item: Request | RequestAdmin | RequestOwner) => {
  let status = !item.collected ? "En attente" : "En cours";
  if (item.ended) status = "Terminé";
  await Swal.fire({
    title: "Status de la prise en charge",
    input: "select",
    inputValue: status,
    inputOptions: {
      "En attente": "En attente",
      "En cours": "En cours",
      Terminé: "Terminé",
    },
    inputPlaceholder: "Selectionner un statut",
    showCancelButton: true,
    confirmButtonText: "Modifier",
    cancelButtonText: "Annuler",
    reverseButtons: true,
    confirmButtonColor: SWAL_DEFAULT_PROPS.confirmButtonColor,
    showLoaderOnConfirm: true,
    preConfirm: (status) => {
      if (!status) {
        return Swal.showValidationMessage("Veuillez sélectionner un statut");
      }
      let update: RequestFormData = {};
      if (status === "En attente") {
        (update.collected = false), (update.ended = false);
      } else if (status === "En cours") {
        (update.collected = true), (update.ended = false);
      } else if (status === "Terminé") {
        (update.collected = true), (update.ended = true);
      }
      return updateRequest(item.uid, update);
    },
    allowOutsideClick: () => !Swal.isLoading(),
  }).then((result: any) => {
    if (result?.isConfirmed) {
      Swal.fire({
        icon: "success",
        title: "Le statut de la prise en charge a été modifiée avec succès!",
        iconColor: SWAL_DEFAULT_PROPS.confirmButtonColor,
        showConfirmButton: false,
        timer: 1500,
      });
    }
  });
};
