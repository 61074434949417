import Fade from "react-reveal/Fade";
import Slide from "react-reveal/Slide";
import { NewsleterFormData } from "../../../types";
import AppStore from "../../assets/landingpage/app-store.png";
import PaperImg from "../../assets/landingpage/paper.png";
import PhoneService from "../../assets/landingpage/phone-service.png";
import PlayStore from "../../assets/landingpage/play-store.png";
import { useAppSelector } from "../../redux/root";
import { useAddNewsletter } from "../../utils/api/hooks";
import { useFormData } from "../../utils/form";
import { FormError } from "../auth/Login";
import {
  AboutUsComponent,
  CaracteristiquesComponent,
  WhyUseComponent,
} from "./AboutUs";
import BannerPage from "./BannerPage";
import Faq from "./Faq";
import FrontFooter from "./FrontFooter/FrontFooter";
import FrontHeader from "./FrontHeader/FrontHeader";
import "./LandingPage.css";
const LandingPage = () => {
  const lp = useAppSelector((s) => s.landing_page);
  const { errors, onChange, onSubmitForm, register } =
    useFormData<NewsleterFormData>(
      {
        email: "",
      },
      (Yup) => ({
        email: Yup.string().email().required().label("Email"),
      })
    );
  const { loading, onAdd } = useAddNewsletter(() => {
    setTimeout(() => {
      onChange({
        email: "",
      });
    }, 1500);
  });
  return (
    <div className="landing-page-component">
      <FrontHeader />
      <div className="page-content">
        <section className="section-banner-homepage py-5" id="banniere">
          <BannerPage />
        </section>
        <AboutUsComponent />
        <WhyUseComponent />
        <CaracteristiquesComponent />
        <div className="section-service-app">
          <div className="container-app-landing-page">
            <div className="row row-service-page">
              <div className="col-md-7 col-left-service-page mb-3">
                <Slide right cascade>
                  <div className="content-col-left-service-page">
                    <img
                      src={PhoneService}
                      alt="Service"
                      className="img-phone-service-app"
                    />
                  </div>
                </Slide>
              </div>
              <div className="col-md-5 col-right-service-page mb-3">
                <Fade bottom cascade>
                  <div className="content-col-right-service-page">
                    <h2 className="title-section-theme-app text-start">
                      DÉCOUVREZ NOS SERVICES{" "}
                    </h2>
                    <div className="content-text-service">
                      <p className="text-about-service-item text-start">
                        Nous vous offrons des services de mécanique auto
                        moderne, climatisation, diagnostique auto, suspension et
                        freinages
                      </p>
                    </div>
                    <div className="content-btn-download-app pt-4">
                      <div className="download-app-item">
                        <a
                          href={lp.appstore}
                          className="btn btn-download-app"
                          target="_blank"
                        >
                          <img
                            src={AppStore}
                            alt="App Store"
                            className="img-download-app"
                          />
                        </a>
                      </div>
                      <div className="download-app-item">
                        <a
                          href={lp.playstore}
                          className="btn btn-download-app"
                          target="_blank"
                        >
                          <img
                            src={PlayStore}
                            alt="Play Store"
                            className="img-download-app"
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                </Fade>
              </div>
            </div>
          </div>
        </div>
        <Faq />
        <div
          className="section-newsletter-app position-relative"
          id="actualite"
        >
          <div className="container-app-landing-page">
            <div className="content-title-banner-about-us mb-5">
              <div className="content-title">
                <Fade bottom cascade>
                  <div className="text-center pb-4">
                    <span className="title-theme-app">Newsletter</span>
                  </div>
                </Fade>
                <Fade bottom cascade>
                  <h2 className="title-section-theme-app mt-3">
                    Abonnez-vous à la newsletter
                  </h2>
                </Fade>
              </div>
              <Fade bottom cascade>
                <div className="w-50 m-auto">
                  <p className="text-about-service-item">
                    Connectez-vous avec moi ! Veuillez vous inscrire à la
                    Newslatter
                  </p>
                </div>
              </Fade>
            </div>
            <Slide right cascade>
              <div className="content-img-paper pt-4">
                <img
                  src={PaperImg}
                  alt="Newsletter"
                  className="img-newsletter"
                />
              </div>
            </Slide>
            <Fade bottom cascade>
              <div className="content-input-newsletter mt-5">
                <div>
                  <input
                    type="email"
                    className="form-control input-newsletter"
                    placeholder="Entrer votre mail"
                    {...register("email")}
                  />
                  <FormError error={errors.email} />
                </div>

                <button
                  onClick={() => {
                    if (loading) return;
                    onSubmitForm((data) => onAdd(data));
                  }}
                  className="btn btn-subscribe-newsletter  animate__animated animate__tada animate__infinite"
                >
                  {loading ? "Chargement..." : "Souscrire"}
                </button>
              </div>
            </Fade>
          </div>
        </div>
      </div>
      <FrontFooter />
    </div>
  );
};

export default LandingPage;
