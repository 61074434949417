import Icon1 from "../../../assets/icons/iconuser.png";
import Icon2 from "../../../assets/icons/iconuser2.png";
import { useUser } from "../../../redux/root";
import { useDashboardConcessionnaires } from "../../../utils/api/dashboard";
import { formatAmout } from "../../../utils/formatter";

import { DashboardCard, useFilter } from "./DashboardAdmin";
import "./DashboardAdmin.css";
import PriseEnChargeGraph from "./Graphs/PriseEnChargeGraph";

const DashboardConcessionnaires = () => {
  const user = useUser();
  const userId = user.concessionnaireId || user.uid;
  return (
    <div className="dashbord-visiteur-component">
      <DashboardConcessionnairesComponent userId={userId} />
    </div>
  );
};

export default DashboardConcessionnaires;

export function DashboardConcessionnairesComponent({
  userId,
  bg = "#FFF",
}: {
  userId: string;
  bg?: string;
}) {
  const { filter, renderFilter } = useFilter();
  const { pendingRequests, requests, requestsData, vehicules, sumAmounts } =
    useDashboardConcessionnaires(filter, userId);
  return (
    <>
      <div className="row row-card-stat-visiteur mb-4 align-items-stretch">
        <div className="col-md-12">{renderFilter()}</div>
        <DashboardCard
          bg={bg}
          title="Prise en charge"
          value={requests}
          icon={Icon2}
        />
        <DashboardCard
          bg={bg}
          title="Demande de prise en charge"
          value={pendingRequests}
          icon={Icon2}
        />
        <DashboardCard
          bg={bg}
          title="Montant"
          value={formatAmout(sumAmounts)}
          icon={Icon2}
        />
        <DashboardCard
          bg={bg}
          title="Véhicules"
          value={vehicules}
          icon={Icon1}
        />
      </div>
      <div className="row row-dashboard-visiteur mb-4">
        <div className="col-md-12 col-left-dashboard-visiteur mb-3">
          <div className="content-col-left-dashboard-visiteur">
            <div className="content-col-graph-admin">
              <PriseEnChargeGraph filter={filter} data={requestsData} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
