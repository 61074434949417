import {
  OwnersUsersRequest,
  RequestAdmin,
  RequestOwner,
} from "../../../../../types";
import { useAcceptRequest } from "../../../../utils/api/hooks";
import { BtnCloseModal } from "../../../common/Button";
import { CommonTable } from "../../../common/Input";

type AcceptRequestModalProps = {
  modalId?: string;
  item: RequestOwner | RequestAdmin;
};
function AcceptRequestModal({ modalId, item }: AcceptRequestModalProps) {
  const actionFormatter = (cell: string, row: OwnersUsersRequest) => {
    return <AcceptButton requestId={item.uid} item={row} />;
  };
  const chargeFormatter = (cell: string, row: OwnersUsersRequest) => {
    return (
      <div>
        {row.user?.prenom} {row?.user?.nom}
      </div>
    );
  };

  const AddressFormatter = (cell: string, row: OwnersUsersRequest) => {
    return <div>{row.user?.location?.ville || row.user?.ville}</div>;
  };

  const columns = [
    {
      dataField: "uid",
      text: "Chauffeur",
      formatter: chargeFormatter,
      headerStyle: () => {
        return { width: "250px", whiteSpace: "normal" };
      },
    },
    {
      dataField: "amount",
      text: "Tarif",
      formatter: (cell: string, row: OwnersUsersRequest) =>
        `${row.pro_price} FCFA`,
      headerStyle: () => {
        return { width: "250px", whiteSpace: "normal" };
      },
    },
    {
      dataField: "location",
      text: "Localisation",
      formatter: AddressFormatter,
      headerStyle: () => {
        return { width: "250px", whiteSpace: "normal" };
      },
    },
    {
      dataField: "action",
      text: "Actions",
      style: { textAlign: "center" },
      formatter: actionFormatter,
    },
  ];

  return (
    <div className="modal-dialog modal-xl">
      <div className="modal-content custom-modal-content">
        <div className="modal-header custom-modal-header px-2 py-1">
          <h5 className="modal-title" id="AddTagModalLabel">
            {item.location.from.ville}
            {" => "}
            {item.location.to.ville}
          </h5>
          <div className="workcares-btn-close-modal-container">
            <BtnCloseModal label="" />
          </div>
        </div>
        <div className="modal-body">
          <CommonTable keyField="uid" data={item.users} columns={columns} />
        </div>
      </div>
    </div>
  );
}

export default AcceptRequestModal;

function AcceptButton({
  item,
  requestId,
}: {
  item: OwnersUsersRequest;
  requestId: string;
}) {
  const { loading, onAdd } = useAcceptRequest();
  if (!item.user) return null;
  return (
    <div className="table-actions-btn-container-action gap-2 d-flex">
      {item.accepted && (
        <button className="terranga-auto-dash-admin-btn-ajout-deux">
          Acceptée
        </button>
      )}
      {!item.accepted && (
        <>
          {loading && (
            <button className="terranga-auto-dash-admin-btn-ajout-deux">
              Acceptation...
            </button>
          )}
          {!loading && (
            <button
              onClick={() =>
                onAdd({
                  requestId: requestId,
                  userId: item.user?.uid,
                })
              }
              className="terranga-auto-dash-admin-btn-ajout-deux"
            >
              Accepter
            </button>
          )}
        </>
      )}
    </div>
  );
}
