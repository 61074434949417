import { BiArrowBack } from "react-icons/bi";
import { useNavigate } from "react-router-dom";

import { useCallback, useEffect, useState } from "react";
import { RequestAdmin, RequestOwner, Vehicule } from "../../../../types";
import { useLocationOrRedirect } from "../../../utils/api/hooks";
import { vehiculeByDriverId, vehiculeById } from "../../../utils/api/user.api";
import { getAmountFormRequest } from "../../../utils/data";
import { InfosRow } from "../../Concessionnaires/Vehicules/DetailVehicule";
import { PriseEnChargeStatus } from "./Tables/CommonPriseEnChargeTable";

const tabs = ["Informations générales", "Camion", "Chauffeur"];

function DetailPriseEnCharge() {
  const navigate = useNavigate();
  const [index, setIndex] = useState(0);
  const [vehicule, setVehicule] = useState<Vehicule>();
  const item = useLocationOrRedirect<RequestAdmin | RequestOwner>();
  const go = useCallback((index: number) => {
    setIndex(index);
  }, []);

  if (!item) return null;

  return (
    <div className="dashbord-visiteur-component">
      <div className="terranga-auto-dash-admin-titre-btn-container mb-4">
        <div className="workcare-dash-admin-titre-container">
          <div
            onClick={() => navigate(-1)}
            className="terranga-auto-dash-admin-titre"
          >
            <BiArrowBack />
            <span className="ps-2">Retour</span>
          </div>
        </div>
      </div>
      <div className="workcare-dash-admin-page-content-container mb-3">
        <ul className="tabs-container mt-4">
          {tabs.map((tab, key) => (
            <li
              className={`tab-item  ${index === key && "tab-active-item"}`}
              key={key}
              style={{ fontSize: 13 }}
              onClick={() => go(key)}
            >
              {tab}
            </li>
          ))}
        </ul>
        {index === 0 && (
          <>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img
                src={item.image}
                style={{
                  height: 250,
                  width: "auto",
                  marginBottom: 10,
                  borderRadius: 10,
                }}
              />
            </div>
            <section className="mb-3">
              <div className="row">
                <InfosRow title={"Prénom & Nom"} value={item.fullname} />
                <InfosRow title={"Téléphone"} value={item.telephone} />
                <InfosRow title={"Matricule"} value={item.identifier} />
                <InfosRow title={"Type de vitesse"} value={item.mode} />
                <InfosRow title={"De"} value={item.location.from.address} />
                <InfosRow title={"A"} value={item.location.to.address} />
                <InfosRow title={"Distance"} value={item.distance} />
                <InfosRow
                  title={"Montant"}
                  value={getAmountFormRequest(item)}
                />
                <InfosRow
                  title={"Statut"}
                  value={<PriseEnChargeStatus item={item} />}
                />
              </div>
            </section>
          </>
        )}
        {index === 1 && <VehiculeInfos {...{ vehicule, setVehicule, item }} />}
        {index === 2 && (
          <>
            {!!item.user && (
              <div className="row">
                <InfosRow title={"Prénom"} value={item.user.prenom} />
                <InfosRow title={"Nom"} value={item.user.nom} />
                <InfosRow title={"Téléphone"} value={item.user.telephone} />
                <InfosRow title={"Email"} value={item.user.email} />
                <InfosRow title={"Adresse"} value={item.user.address} />
              </div>
            )}
            {!item.user && (
              <div className="alert alert-danger">Pas de chauffeur</div>
            )}
          </>
        )}
      </div>
    </div>
  );
}

export default DetailPriseEnCharge;

type VehiculeInfosProps = {
  item: RequestAdmin | RequestOwner;
  vehicule?: Vehicule;
  setVehicule: (v: Vehicule) => void;
};

function VehiculeInfos({ item, vehicule, setVehicule }: VehiculeInfosProps) {
  const [empty, setEmpty] = useState(false);
  useEffect(() => {
    if (!vehicule) {
      if (item.camionId) {
        vehiculeById(item.camionId).then((v) => {
          if (v) setVehicule(v);
          else setEmpty(true);
        });
      } else if (item.user) {
        vehiculeByDriverId(item.user.uid).then((v) => {
          if (v) setVehicule(v);
          else setEmpty(true);
        });
      }
    }
  }, [vehicule]);
  if (!item.user && !item.camionId) {
    return <div className="alert alert-danger">Pas de véhicule</div>;
  }

  if (empty) {
    return <div className="alert alert-danger">Pas de véhicule trouvée</div>;
  }

  if (!vehicule) {
    return <p>Chargement...</p>;
  }

  return (
    <>
      {!!vehicule.image && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img
            src={vehicule.image}
            style={{
              height: 250,
              width: "auto",
              marginBottom: 10,
              borderRadius: 10,
            }}
          />
        </div>
      )}
      <div className="row">
        <InfosRow title={"Matricule"} value={vehicule.matricule} />
        <InfosRow title={"Année"} value={vehicule.annee} />
        <InfosRow title={"Modèle"} value={vehicule.model} />
        <InfosRow title={"Type de vitesse"} value={vehicule.mode} />
      </div>
    </>
  );
}
