import {
  createUserWithEmailAndPassword,
  getAuth,
  onAuthStateChanged,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signOut,
  updatePassword,
  User,
} from "firebase/auth";
import Swal from "sweetalert2";
import { SWAL_DEFAULT_PROPS } from "../data";
import { getUserByUid, getUserInfos } from "./user.api";

const auth = getAuth();

export function authListener(cb: (user: User | null) => void) {
  return onAuthStateChanged(auth, cb);
}
export function logoutAsync() {
  return signOut(auth);
}

export async function loginAsync(email: string, password: string) {
  const { user } = await signInWithEmailAndPassword(auth, email, password);
  if (user) {
    const userInfos = await getUserInfos(user);
    if (userInfos) {
      const userId = userInfos.concessionnaireId || userInfos.ownerId;
      if (userId) {
        getUserByUid(userId).then((u) => {
          if (!u || u.disabled) {
            Swal.fire({
              icon: "error",
              title: "Le compte principal à été désactivé",
              showConfirmButton: false,
              timer: 5000,
              ...SWAL_DEFAULT_PROPS,
            });
            logoutAsync();
          }
        });
      }
      return {
        user,
        userInfos,
      };
    } else {
      await logoutAsync();
    }
  }
  return null;
}
export async function registerAsync(email: string, password: string) {
  return createUserWithEmailAndPassword(auth, email, password);
}

export function sendPasswordResetLinkAsync(email: string) {
  return sendPasswordResetEmail(auth, email);
}

export async function updatePasswordAsync(password: string) {
  if (auth.currentUser) {
    return updatePassword(auth.currentUser, password);
  }
  return null;
}

export default auth;
