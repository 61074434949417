import { useCallback, useEffect, useState } from "react";
import { AiOutlinePlusSquare } from "react-icons/ai";
import { BiUpload } from "react-icons/bi";
import { useSearchParams } from "react-router-dom";
import AjouterPriseEnChargeModalAdmin from "../Chauffeurs/Modales/AjouterPriseEnChargeModalAdmin";
import ImporterPriseEnChargeModal from "../Chauffeurs/Modales/ImporterPriseEnChargeModal";
import PendingAssignationAdminTable from "./Tables/PendingAssignationAdminTable";
import PendingPriseEnChargeAdminTable from "./Tables/PendingPriseEnChargeAdminTable";
import PriseEnChargeAdminTable from "./Tables/PriseEnChargeAdminTable";

const tabs = [
  "Prise en charges",
  "Assignation en attente",
  "Demande de prise en charges",
];

function PriseEnChargeConcessionnaires() {
  const [params] = useSearchParams();
  const initIndex = params.get("index");
  const [index, setIndex] = useState(0);
  const go = useCallback((index: number) => {
    setIndex(index);
  }, []);

  useEffect(() => {
    if (initIndex) {
      const parsed = parseInt(initIndex);
      if (parsed && parsed >= 0 && parsed < 3) {
        go(parsed);
      }
    }
  }, [initIndex]);

  const [search, setSearch] = useState("");

  const modalId = "AjouterPriseEnChageModal";
  const modalImportId = "ImportPriseEnChageModal";

  return (
    <div className="dashbord-visiteur-component">
      <div className="workcare-dash-admin-page-content-container">
        <div
          className="modal fade"
          id={modalId}
          aria-labelledby="AjouterVehiculeModalLabel"
          aria-hidden="true"
        >
          <AjouterPriseEnChargeModalAdmin modalId={modalId} />
        </div>
        <div
          className="modal fade"
          id={modalImportId}
          aria-labelledby="AjouterVehiculeModalLabel"
          aria-hidden="true"
        >
          <ImporterPriseEnChargeModal modalId={modalImportId} />
        </div>
        <ul className="tabs-container mt-4">
          {tabs.map((tab, key) => (
            <li
              className={`tab-item  ${index === key && "tab-active-item"}`}
              key={key}
              style={{ fontSize: 13 }}
              onClick={() => go(key)}
            >
              {tab}
            </li>
          ))}
        </ul>

        <div className="locataire-requete-filtre-container mb-4">
          <form className="row g-3 locataire-requete-filtre-form">
            <div className="col-auto">
              <label
                htmlFor="filtreLogement"
                className="locataire-requete-filtre-label"
              >
                Filtrer
              </label>
            </div>
            <div className="col-auto">
              <input
                type="text"
                className="form-control locataire-requete-filtre-input"
                id="filtreLogement"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                placeholder="Rechercher..."
              />
            </div>
            <div className="col-auto">
              <button
                className="terranga-auto-dash-admin-btn-ajout-deux"
                data-bs-toggle="modal"
                type="button"
                data-bs-target={`#${modalId}`}
              >
                <AiOutlinePlusSquare className="terranga-auto-dash-admin-btn-ajout-icone" />{" "}
                Ajouter une prise en charge
              </button>
            </div>
            <div className="col-auto">
              <button
                className="terranga-auto-dash-admin-btn-ajout-deux"
                data-bs-toggle="modal"
                type="button"
                data-bs-target={`#${modalImportId}`}
              >
                <BiUpload className="terranga-auto-dash-admin-btn-ajout-icone" />{" "}
                Importer un fichier
              </button>
            </div>
          </form>
        </div>

        {index === 0 && <PriseEnChargeAdminTable search={search} />}
        {index === 1 && <PendingAssignationAdminTable search={search} />}
        {index === 2 && <PendingPriseEnChargeAdminTable search={search} />}
      </div>
    </div>
  );
}

export default PriseEnChargeConcessionnaires;
