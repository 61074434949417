import { useEffect } from "react";
import { AiOutlinePlusSquare } from "react-icons/ai";
import { Tarif } from "../../../../types";
import { useUpdateTarif } from "../../../utils/api/hooks";
import { tarifListener } from "../../../utils/api/user.api";
import { useFormData } from "../../../utils/form";
import { FormError } from "../../auth/Login";
import { BtnSubmit } from "../../common/Button";
import { Input } from "../../common/Input";
import AjouterJourModal from "./Modales/AjouterJourModal";
import "./ParametresAdmin.css";
import JoursTable from "./Tables/JoursTable";

function Tarification() {
  const { errors, onChange, onSubmitForm, register } = useFormData<Tarif>(
    {
      dk_tarif: 0,
      interurbain_tarif: 0,
      majoration_ferier: 0,
      majoration_nuit: 0,
    },
    (Yup) => ({
      dk_tarif: Yup.number().required().label("Tarif district de Dakar").min(0),
      interurbain_tarif: Yup.number()
        .required()
        .label("Tarif interurbain(montant par km)")
        .min(0),
      majoration_nuit: Yup.number()
        .required()
        .label("Taux de majoration nuit(%)")
        .min(0)
        .max(100),
      majoration_ferier: Yup.number()
        .required()
        .label("Taux de majoration jour férié(%)")
        .min(0)
        .max(100),
    })
  );

  const { loading, onUpdate } = useUpdateTarif(() => {});
  useEffect(() => {
    const li = tarifListener((s) => {
      onChange(s);
    });
    return () => li();
  }, []);
  return (
    <div className="dashbord-visiteur-component">
      <div className="terranga-auto-dash-admin-titre-btn-container mb-4">
        <div className="workcare-dash-admin-titre-container">
          <h1 className="terranga-auto-dash-admin-titre">Tarification</h1>
        </div>
      </div>
      <div className="workcare-dash-admin-page-content-container">
        <div className="locataire-requete-filtre-container mb-4">
          <form
            onSubmit={(e) => {
              e.preventDefault();
              onSubmitForm(onUpdate);
            }}
            className="row g-3 locataire-requete-filtre-form"
          >
            <div className="row">
              <div className="col-md-6">
                <Input
                  type="number"
                  label="Tarif district de Dakar"
                  placeholder="Tarif district de Dakar"
                  {...register("dk_tarif")}
                />
                <FormError
                  error={errors.dk_tarif ? String(errors.dk_tarif) : ""}
                />
              </div>

              <div className="col-md-6">
                <Input
                  type="number"
                  label="Tarif interurbain(montant par km)"
                  placeholder="Tarif interurbain(montant par km)"
                  {...register("interurbain_tarif")}
                />
                <FormError
                  error={
                    errors.interurbain_tarif
                      ? String(errors.interurbain_tarif)
                      : ""
                  }
                />
              </div>
              <div className="col-md-6">
                <Input
                  type="number"
                  label="Taux de majoration nuit(%)"
                  placeholder="Taux de majoration nuit(%)"
                  {...register("majoration_nuit")}
                />
                <FormError
                  error={
                    errors.majoration_nuit ? String(errors.majoration_nuit) : ""
                  }
                />
              </div>
              <div className="col-md-6">
                <Input
                  type="number"
                  label="Taux de majoration jour férié(%)"
                  placeholder="Taux de majoration jour férié(%)"
                  {...register("majoration_ferier")}
                />
                <FormError
                  error={
                    errors.majoration_ferier
                      ? String(errors.majoration_ferier)
                      : ""
                  }
                />
              </div>
              <div className="col-md-3">
                <div className="mb-3 mt-2 flex-r gap-3">
                  <BtnSubmit
                    label={"Modifier"}
                    isLoading={loading}
                    style={{ padding: "5px 10px", fontSize: 14 }}
                  />
                </div>
              </div>
            </div>
          </form>
        </div>
        <div className="terranga-auto-dash-admin-titre-btn-container mb-4">
          <div className="workcare-dash-admin-titre-container">
            <h1 className="terranga-auto-dash-admin-titre">Jours férié</h1>
          </div>
          <button
            className="terranga-auto-dash-admin-btn-ajout-deux"
            data-bs-toggle="modal"
            data-bs-target={`#jourf`}
          >
            <AiOutlinePlusSquare className="terranga-auto-dash-admin-btn-ajout-icone" />{" "}
            Ajouter un jour férié
          </button>
          <div
            className="modal fade"
            id={"jourf"}
            aria-labelledby="AjouterAdministrateurModalLabel"
            aria-hidden="true"
          >
            <AjouterJourModal modalId={"jourf"} />
          </div>
        </div>
        <JoursTable />
      </div>
    </div>
  );
}

export default Tarification;
